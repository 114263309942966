import React from 'react'
import doktor24LogoSvg from '../../../assets/images/doktor24_logo.svg'

export type VirtualUrlServiceLogoProps = {
  logoImg?: React.ReactNode
  containerProps?: Record<string, string>
}

/**
 * @param props - props
 * @param props.svgLogo - the svg logo to override the default with
 * @returns the VirtualUrlServiceLogo component
 */
export const VirtualUrlServiceLogo: React.FC<VirtualUrlServiceLogoProps> = ({ logoImg, containerProps }) => (
  <div
    {...containerProps}
    style={{
      borderRadius: '5px',
      backgroundColor: 'rgba(0,47,108, 1)',
      width: '1.5em',
      height: '1.5em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {logoImg ?? <img src={doktor24LogoSvg} style={{ width: '60%' }} />}
  </div>
)
