import React, { FC, useMemo } from 'react'
import { useElementSize } from 'usehooks-ts'
import { Typography } from 'antd'
import { WrapperWithTitleAndAnimation } from '../../../components/WrapperWithTitleAndAnimation'
import { mobileMaxWidth } from '../../../hooks'

interface Props {
  questionText: string
  isLoading: boolean
}

export const Info: FC<Props> = (props) => {
  const { questionText, isLoading } = props
  const [ref, { width }] = useElementSize()

  const marginSize = useMemo(() => (width > mobileMaxWidth ? 150 : 25), [width])
  const [title, text] = questionText.split(';')

  return (
    <WrapperWithTitleAndAnimation
      elementReference={ref}
      title={title}
      isAnimated
      animationStartsWhen={!isLoading}
      style={{
        textAlign: 'center',
        alignItems: 'center',
        marginLeft: marginSize,
        marginRight: marginSize,
        letterSpacing: 0.17,
        marginTop: 100,
      }}
    >
      <Typography.Text>{text}</Typography.Text>
    </WrapperWithTitleAndAnimation>
  )
}
