import React, { CSSProperties, FC, useMemo } from 'react'
import { Popover, Typography } from 'antd'
import styled from '@emotion/styled'
import { Specialization } from '../../../../../models'
import { useServicesContext } from '../../../../../context'
import { ActiveFilter } from '../../ServicesMenu'
import { FilterKey } from '../../../../../models/servicesFilters'
import {
  DEFAULT_SELECTED_SPECIALIZATION_ALLOWED_LENGTH,
  DEFAULT_SELECTED_SPECIALIZATION_ALLOWED_LENGTH_RESPONSIVE,
  truncateString,
} from '../../../../../utils/truncateString'
import { useScreenSize } from '../../../../../hooks'
import { Container, FilterButton, RemoveFilterIcon } from '../Filters.common.styled'
import { CustomIcon } from '../../../../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

const AddIcon = styled.div<{ needsExtraPadding: boolean }>(
  `
  cursor: pointer;
  `,
  (props) =>
    props.needsExtraPadding &&
    `
    padding-top: 8px
    `,
)

/**
 * @param props The props object
 * @param props.activeFilters The active filters
 * @param props.currentFilter The current filter
 * @param props.specializations The array of specializations
 * @param props.selectedButtonStyles The stylings for buttons in the selected state
 * @param props.setIsSpecializationsDetailedViewVisible The setter for whether the specializations detailed view is visible
 * @returns The SpecializationsFilter component
 */
export const SpecializationsFilter: FC<{
  activeFilters: ActiveFilter
  currentFilter: FilterKey
  specializations?: Specialization[]
  selectedButtonStyles: (val?: boolean) => CSSProperties
  setIsSpecializationsDetailedViewVisible: (val: boolean) => void
}> = ({
  activeFilters,
  currentFilter,
  specializations,
  selectedButtonStyles,
  setIsSpecializationsDetailedViewVisible,
}) => {
  const { filterObject, setFilterObject } = useServicesContext()
  const { isMobileLandscapeView, isTabletView, isTabletLandscapeView } = useScreenSize()

  const isResponsiveView = useMemo(
    () => isMobileLandscapeView || isTabletView || isTabletLandscapeView,
    [isMobileLandscapeView, isTabletLandscapeView, isTabletView],
  )

  if (!(activeFilters[currentFilter] && currentFilter === 'specializations')) {
    return null
  }

  return (
    <Container className="descriptionFadeIn" align="middle">
      {filterObject?.specializations?.map((spec) => {
        const selectedSpec = specializations?.find((item) => item.id === spec)

        return (
          selectedSpec && (
            <FilterButton
              key={selectedSpec.id}
              type="primary"
              style={selectedButtonStyles(filterObject?.[currentFilter]?.includes(selectedSpec.id))}
              onClick={() => {
                if (filterObject?.specializations?.includes(selectedSpec.id)) {
                  setFilterObject({
                    ...filterObject,
                    specializations: filterObject?.specializations?.filter((item) => item !== selectedSpec.id),
                  })
                } else {
                  setFilterObject({
                    ...filterObject,
                    specializations: [...(filterObject.specializations || []), selectedSpec.id],
                  })
                }
              }}
            >
              <Popover
                overlayInnerStyle={{ maxWidth: '84%', margin: '0 5%' }}
                content={<Typography style={{ color: CSS_VARS.questionFontColor }}>{selectedSpec?.name}</Typography>}
              >
                {truncateString(
                  selectedSpec?.name,
                  isResponsiveView
                    ? DEFAULT_SELECTED_SPECIALIZATION_ALLOWED_LENGTH_RESPONSIVE
                    : DEFAULT_SELECTED_SPECIALIZATION_ALLOWED_LENGTH,
                )}
              </Popover>

              <RemoveFilterIcon />
            </FilterButton>
          )
        )
      })}

      <AddIcon
        onClick={() => setIsSpecializationsDetailedViewVisible(true)}
        needsExtraPadding={Boolean(filterObject?.specializations?.length)}
      >
        <CustomIcon icon="addCircle" color={CSS_VARS.sideMenuBackgroundColor} />
      </AddIcon>
    </Container>
  )
}
