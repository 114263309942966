import React, { FC } from 'react'
import { Card } from 'antd'
import { CSSTransition } from 'react-transition-group'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.itemId The ID of the symptom item
 * @param props.title The title of the symptom item
 * @param props.isLoading The boolean for whether the app is loading
 * @param props.icon The icon to use
 * @param props.onClick The method handling clicks
 * @param props.elementReference The reference object
 * @param props.isAdded The boolean that shows if the symptom has been added by the patient
 * @returns The overview symptom item component
 */
export const OverviewSymptomItem: FC<{
  title: string
  isLoading: boolean
  icon?: 'addCircle' | 'edit' | null
  itemId?: string
  onClick?: (val: React.MouseEvent) => void
  elementReference?: (node: HTMLDivElement | null) => void
  isAdded?: boolean
}> = ({ title, isLoading, icon, itemId, onClick, elementReference, isAdded = false }) => {
  return (
    <CSSTransition key={itemId} in={!isLoading} timeout={200} classNames="symptomSuggestionAnimation">
      <Card
        ref={elementReference}
        bordered={false}
        onClick={onClick}
        style={{ pointerEvents: isLoading ? 'none' : 'initial', opacity: isLoading ? 0.3 : 1 }}
      >
        <div className="overViewSymptomItem">
          <div style={{ display: 'flex' }}>
            <p
              style={{
                margin: '2px 0',
                fontSize: CSS_VARS.chatFontSize,
                fontWeight: isAdded ? 600 : 'normal',
                lineHeight: 1.2,
                color: CSS_VARS.chatFontColor,
              }}
            >
              {title}
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            {icon && (
              <CustomIcon
                icon={icon}
                style={{ marginRight: 1, width: 20, height: 20, color: CSS_VARS.PRIMARY_COLOR }}
              />
            )}
          </div>
        </div>
      </Card>
    </CSSTransition>
  )
}
