import React, { FC, useMemo } from 'react'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { SymptomSurveyDetailedData } from '../../models/api/response/detailedTypeData/SymptomSurveyDetailedData'
import { useQuestionContext } from '../../context/QuestionContext'
import { MaleAbdominalZoomSVG } from './MaleAbdominalZoomSVG/MaleAbdominalZoomSVG'
import { FemaleAbdominalZoomSVG } from './FemaleAbdominalZoomSVG/FemaleAbdominalZoomSVG'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.sendAnswer The method that sends the answer
 * @returns The AbdominalZoomQuestion component
 */
export const AbdominalZoomQuestion: FC<{
  question: Question
  animationStartsWhen: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, animationStartsWhen, sendAnswer }) => {
  const everywhereOption = useMemo(
    () => question.options?.values?.find((option) => option.code === 'uberall'),
    [question],
  )
  const { isLoading } = useQuestionContext()

  return (
    <WrapperWithTitleAndAnimation title={question.text} isAnimated animationStartsWhen={animationStartsWhen}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {(question.detailedTypeData as SymptomSurveyDetailedData)?.gender === 'MALE' ? (
            <MaleAbdominalZoomSVG questionId={question.id} sendAnswer={sendAnswer} />
          ) : (
            <FemaleAbdominalZoomSVG questionId={question.id} sendAnswer={sendAnswer} />
          )}

          <StyledButton
            type="outlined"
            title={everywhereOption?.text || ''}
            disabled={isLoading}
            onClick={() => sendAnswer({ questionId: question.id, answer: { id: everywhereOption?.id } })}
          />
        </div>
      </div>
    </WrapperWithTitleAndAnimation>
  )
}
