import { DAYS_OF_THE_WEEK } from '../../constants'
import { OpeningHours } from '../../models/Service'

/**
 * @param openingHours The current opening hours
 * @returns An object with the days of the week mapped to the opening hours
 */
export const convertCurrentServiceOpeningHoursToObject = (openingHours?: OpeningHours) => {
  const obj: { [key: string]: Array<{ start: string; finish: string }> } = {}

  DAYS_OF_THE_WEEK.forEach((item, i) => {
    obj[item] = openingHours?.[i] || []
  })

  return obj
}
