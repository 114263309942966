import React, { FC } from 'react'
import { useServicesContext } from '../../../../context'
import { SearchItemLabeledListMobile } from '../SearchItemLabeledListMobile/SearchItemLabeledListMobile'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { Container, InnerContainer } from './SearchResultListMobile.styled'

/**
 * @param props The props object
 * @param props.showMatchingFiltersServices Whether to show services matching the filters
 * @param props.isMenuOpen Whether the menu is open
 * @returns The SearchResultListMobile component
 */
export const SearchResultListMobile: FC<{
  showMatchingFiltersServices: boolean
  isMenuOpen: boolean
}> = ({ showMatchingFiltersServices, isMenuOpen }) => {
  const { matchingFiltersServicesData, isMatchingFiltersServicesLoading } = useServicesContext()

  return (
    <Container style={{ background: CSS_VARS.chatBackgroundColor }} isMenuOpen={isMenuOpen}>
      <InnerContainer>
        <SearchItemLabeledListMobile
          servicesData={matchingFiltersServicesData}
          isListVisible={showMatchingFiltersServices}
          isLoading={isMatchingFiltersServicesLoading}
        />
      </InnerContainer>
    </Container>
  )
}
