// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OSOkZeBSRNmFPPPfEEg0 {\n  padding: 5px;\n  margin: 10px auto;\n  border-radius: 5px;\n  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.05);\n  background-color: #ffffff;\n  width: 300px;\n}\n.OSOkZeBSRNmFPPPfEEg0 .nmvYH8fp8TMzaMexDv_C {\n  color: #808080;\n  padding: 12px;\n}\n.X8KW_4rLnaaee_BXHeMp {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Report/VirtualUrlServices/VirtualUrlService.module.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,4CAAA;EACA,yBAAA;EACA,YAAA;AADF;AALA;EASI,cAAA;EACA,aAAA;AADJ;AAKA;EACC,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAHD","sourcesContent":[" @import '../../../variables.less';\n\n.virtualUrlService {\n  padding: 5px;\n  margin: 10px auto;\n  border-radius: 5px;\n  box-shadow: 0px 7px 15px rgba(0,0,0, 0.05);\n  background-color: rgba(255,255,255, 1);\n  width: 300px;\n\n  .description {\n    color: rgba(128,128,128,1);\n    padding: 12px;\n  }\n}\n\n.virtualUrlServiceCardTitle {\n display: flex; \n flex-direction: row;\n gap: 10px;\n align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"virtualUrlService": "OSOkZeBSRNmFPPPfEEg0",
	"description": "nmvYH8fp8TMzaMexDv_C",
	"virtualUrlServiceCardTitle": "X8KW_4rLnaaee_BXHeMp"
};
export default ___CSS_LOADER_EXPORT___;
