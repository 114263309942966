import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Select } from 'antd'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { useQuestionContext } from '../../context/QuestionContext'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.isLoading The boolean that decides whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @param props.options the array of the possible options
 * @param props.defaultValue the default value in the select (must be in the options list)
 * @param props.placeholder placeholder text
 * @param props.prefixIcon optional prefix icon
 * @returns The SelectInput component
 */
export const SelectInput: FC<{
  question: Question
  animationStartsWhen: boolean
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
  options: Array<{ value: string; label: string }>
  defaultValue?: string
  placeholder?: string
  prefixIcon?: ReactNode
}> = ({ question, animationStartsWhen, isLoading, sendAnswer, options, defaultValue, placeholder, prefixIcon }) => {
  const { setNextButtonLogic, setIsNextButtonDisabled } = useQuestionContext()

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (!value) {
      setIsNextButtonDisabled(true)
    } else {
      setIsNextButtonDisabled(false)
    }
    setNextButtonLogic(
      () => () =>
        sendAnswer({
          questionId: question.id,
          answer: {
            value: value?.toString(),
          },
        }),
    )
  }, [setNextButtonLogic, sendAnswer, question.id, value, setIsNextButtonDisabled])

  return (
    <WrapperWithTitleAndAnimation title={question.text} isAnimated animationStartsWhen={animationStartsWhen}>
      <div className="buttonContainer">
        <div style={{ margin: '40px auto 30px', position: 'relative', width: '100%' }}>
          {prefixIcon && (
            <div
              style={{
                position: 'absolute',
                zIndex: 1,
                width: '40px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {prefixIcon}
            </div>
          )}
          <Select
            showSearch
            onChange={(val) => setValue(val)}
            value={value}
            placeholder={placeholder}
            style={{ width: '100%' }}
            size="large"
          >
            {options.map((option, key) => {
              return (
                <Select.Option key={key} value={option.value}>
                  {option.label}
                </Select.Option>
              )
            })}
          </Select>
        </div>
      </div>
    </WrapperWithTitleAndAnimation>
  )
}
