import { BodyZone } from '../models/BodyZone'

export const bodyZones: BodyZone[] = [
  'Head',
  'Occiput',
  'Abdomen',
  'Back',
  'Upper_arm_left',
  'Upper_arm_right',
  'Lower_arm_left',
  'Lower_arm_right',
  'Hips',
  'Lowerback',
  'Legs',
]

/**
 * Hardcoded values until body zones are available from API Gateway
 *
 * @param code The option code
 * @returns The body zone
 */
export const assignAnswerOptionsToBodyZones = (code: string) => {
  const bodyParts = {
    ear: ['Head'],
    eyes: ['Head'],
    face: ['Head'],
    head: ['Head'],
    mental: ['Head'],
    mouth: ['Head'],
    nose: ['Head'],
    throat: ['Head'],

    nape: ['Occiput'],
    occiput: ['Occiput'],

    breast: ['Abdomen'],
    chest: ['Abdomen'],
    abdomen: ['Abdomen'],
    stomach: ['Abdomen'],

    back: ['Back'],
    spine: ['Back'],

    shoulders: ['Upper_arm_left', 'Upper_arm_right'],
    upperarm: ['Upper_arm_left', 'Upper_arm_right'],
    armpit: ['Upper_arm_left', 'Upper_arm_right'],

    elbow: ['Lower_arm_left', 'Lower_arm_right'],
    fingers: ['Lower_arm_left', 'Lower_arm_right'],
    forearm: ['Lower_arm_left', 'Lower_arm_right'],
    hands: ['Lower_arm_left', 'Lower_arm_right'],
    wrist: ['Lower_arm_left', 'Lower_arm_right'],

    genitals: ['Hips'],

    buttocks: ['Lowerback'],

    lowerback: ['Lowerback', 'Back'],

    hip: ['Hips', 'Lowerback'],

    ankle: ['Legs'],
    feet: ['Legs'],
    heel: ['Legs'],
    knee: ['Legs'],
    lowerleg: ['Legs'],
    thigh: ['Legs'],
    toes: ['Legs'],
  }

  if (code in bodyParts) {
    return bodyParts[code as keyof typeof bodyParts]
  } else {
    return undefined
  }
}
