import React, { FC, useCallback } from 'react'
import { useServicesContext } from '../../../../../context'
import { ActiveFilter } from '../../ServicesMenu'
import { DayFilterKeys, FilterKey, OpeningHourFilter } from '../../../../../models/servicesFilters'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'
import { Container, DateButton, DateContainer, DateRow, StyledTypography } from './OpeningHoursFilter.styled'

/**
 * @param props The props object
 * @param props.activeFilters The active filters
 * @param props.currentFilter The current filter
 * @param props.filter The opening hours filter
 * @returns The OpeningHoursFilter component
 */
export const OpeningHoursFilter: FC<{
  activeFilters: ActiveFilter
  currentFilter: FilterKey
  filter: OpeningHourFilter
}> = ({ activeFilters, currentFilter, filter }) => {
  const { filterObject, setFilterObject } = useServicesContext()

  /**
   * @param dayKey The key of the day to modify
   * @param partOfTheDayToAddFilterTo The part of the day to filter a filter to
   */
  const handleFilterDateValueSelect = useCallback(
    (dayKey: DayFilterKeys, value: string) => {
      const currentDayValue = filterObject?.openingHours?.[dayKey]
      const isValueSelected = currentDayValue?.includes(value)

      let updatedValues: string[] | undefined = []

      if (isValueSelected) {
        updatedValues = currentDayValue?.filter((item) => item !== value)
      } else {
        updatedValues = [...(currentDayValue || []), value]
      }

      setFilterObject({
        ...filterObject,
        openingHours: {
          ...filterObject.openingHours,
          [dayKey]: updatedValues,
        },
      })
    },
    [filterObject, setFilterObject],
  )

  /**
   * @param condition The condition to evaluate
   * @returns Dynamic CSS stylings
   */
  const dynamicStylings = useCallback(
    (condition = false) => ({
      background: condition ? CSS_VARS.sideMenuBackgroundColor : CSS_VARS.lightPrimaryColor,
      color: 'white',
    }),
    [],
  )

  if (!(activeFilters[currentFilter] && currentFilter === 'openingHours')) {
    return null
  }

  return (
    <Container className={'descriptionFadeIn'}>
      {filter.filters.map((day) => {
        return (
          <DateRow key={day.key}>
            <StyledTypography>{day.shortText}</StyledTypography>

            <DateContainer>
              {day.values.map((item) => {
                const currentDayValue = filterObject?.openingHours?.[day.key]

                const isSelected = currentDayValue?.includes(item.value)

                return (
                  <DateButton
                    key={`${day.key}_${item.text}`}
                    type="primary"
                    size="small"
                    style={{
                      marginRight: 5,
                      marginLeft: 'auto',
                      ...dynamicStylings(isSelected),
                    }}
                    onClick={() => handleFilterDateValueSelect(day.key, item.value)}
                  >
                    {item.text}
                  </DateButton>
                )
              })}
            </DateContainer>
          </DateRow>
        )
      })}
    </Container>
  )
}
