import React, { FC, useCallback, useState } from 'react'
import { Gender } from 'medical-engine-api'
import { IconButton } from '../../General/IconButton/IconButton'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { BodySVG } from './BodySVG'

/**
 * @param props The props object
 * @param props.gender The current SVG view
 * @param props.isLoading The boolean for whether the app is loading
 * @param props.selectBodyZone The method selecting a body zone
 * @returns The BodyPartSelectorSVG component
 */
export const BodyPartSelectorSVG: FC<{
  gender: Gender
  isLoading: boolean
  selectBodyZone: (value: EventTarget) => void
}> = ({ gender, isLoading, selectBodyZone }) => {
  const [isRotated, setIsRotated] = useState(false)

  /**
   * @returns An SVG Body component
   */
  const determineBodySVGToShow = useCallback(() => {
    if (gender === 'MALE') {
      if (isRotated) {
        return <BodySVG requiredSVG="MALE_BACK" onClick={selectBodyZone} />
      } else {
        return <BodySVG requiredSVG="MALE_FRONT" onClick={selectBodyZone} />
      }
    } else {
      if (isRotated) {
        return <BodySVG requiredSVG="FEMALE_BACK" onClick={selectBodyZone} />
      } else {
        return <BodySVG requiredSVG="FEMALE_FRONT" onClick={selectBodyZone} />
      }
    }
  }, [isRotated, gender, selectBodyZone])

  return (
    <div style={{ textAlign: 'center', margin: 'auto', display: 'flex', flexDirection: 'column' }}>
      {determineBodySVGToShow()}
      <div style={{ margin: '5px auto auto' }}>
        <IconButton
          testId="rotateButton"
          icon={<CustomIcon icon="rotate" />}
          disabled={isLoading}
          onClick={() => setIsRotated(!isRotated)}
        />
      </div>
    </div>
  )
}
