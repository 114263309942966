import React, { CSSProperties, FC, useMemo } from 'react'
import { frontIds } from '../bodyPartIds'
import { femaleStyles } from '../styles'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

const { headId, upperArmLeftId, upperArmRightId, lowerArmLeftId, lowerArmRightId, abdomenId, hipsId, legsId } = frontIds

/**
 * @param props The props object
 * @param props.handleClick The click handler method
 * @param props.applyStyling The method which applies appropriate stylings for components
 * @param props.setIsHovered The setter for whether a component is hovered
 * @returns The FemaleBodyFrontSVG component
 */
export const FemaleBodyFrontSVG: FC<{
  handleClick: (eventTarget: EventTarget, bodyPartId: string) => void
  applyStyling: (id: string, styling: CSSProperties) => CSSProperties
  setIsHovered: (val: string) => void
}> = ({ handleClick, applyStyling, setIsHovered }) => {
  const femaleStylings = useMemo(
    () => femaleStyles(CSS_VARS.bodyPartSelectorSVGBodyOutlineAndHoverColor, CSS_VARS.bodyPartSelectorSVGColor),
    [],
  )

  const { styling2, styling3, styling4, styling5, styling6, styling7 } = femaleStylings

  return (
    <svg
      data-testid="femaleFrontSVG"
      className="bodySvg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 253.84 541.61"
    >
      <g
        id={legsId}
        onClick={(e) => handleClick(e.target, legsId)}
        onMouseEnter={() => setIsHovered(legsId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path style={applyStyling(legsId, styling2)} d="M123.35,293.1" />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.95,274.87c0.02-0.33,0.05-0.66,0.07-0.99C73,274.21,72.97,274.54,72.95,274.87z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M127.61,298.19c-0.04,5.2,0.84,12.91,1.06,15.29s1.28,19.65,1.37,22.21s-0.22,18.15-0.35,20.53
          c-0.13,2.38-1.01,15.38-1.28,19.87s0.22,11.46,0.97,15.6s2.56,10.13,3.66,13.09c1.1,2.95,0.18,12.29-0.4,15.42
          c-0.57,3.13-1.37,10.31-1.67,15.91c-0.31,5.6,1.23,19.43,1.45,21.02c0.22,1.59,2.11,15.91,2.29,17.71
          c0.18,1.81,0.35,10.22,0.26,12.38s-0.57,5.38-1.1,7.93c-0.53,2.56-0.44,5.35-0.22,6.72c0.22,1.37,0.95,4.48,0.97,6.33
          c0.03,2.38-1.57,4.29-1.83,7.59c-0.26,3.3,1.29,4.99,3.59,5.96c1.86,0.79,5.11,1.37,6.04,5.38c0.93,4.01,1.98,3.61,3.26,5.29
          c1.28,1.67,3.26,3.22,6.7,3.35c3.44,0.13,5.64-1.5,5.64-1.5s2.29,0.13,5.6,0.18c3.3,0.04,4.58-1.28,4.58-1.28s1.81-0.57,2.56-2.38
          c1.15-2.77-1.46-4.76-4.17-6.3c-2.71-1.54-6.96-4.88-11.2-9.76c-4.25-4.88-5.42-9.04-6.14-15.81c-0.72-6.78,3.79-20.69,4.97-24.94
          c1.17-4.25,5.78-22.05,8.49-36.95c2.71-14.91-0.09-32.08-0.45-34.06c-0.36-1.99-1.99-7.32-1.99-13.73
          c0-11.75,7.41-40.3,8.85-46.71c1.45-6.42,6.87-23.31,9.13-34.6c0.84-4.18,1.08-9.22,1-14.74c-0.04,0.01-12.56,4-25.65,4
          c-13.11,0-25.67-4-25.67-4s-0.21,1.16-0.25,2.36C127.63,296.74,127.61,298.19,127.61,298.19z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M122.57,313.16c0.22-2.38,1.1-10.09,1.06-15.29c-0.01-1.49-0.12-3.1-0.27-4.69c-0.02,0.01-13.24,4-26.54,4
          c-13.26,0-24.71-3.97-24.8-4c-0.06,5.39,0.18,10.31,1,14.41c2.26,11.29,7.68,28.19,9.13,34.6c1.45,6.42,8.85,34.97,8.85,46.71
          c0,6.41-1.63,11.75-1.99,13.73c-0.36,1.99-3.16,19.15-0.45,34.06c2.71,14.91,7.32,32.71,8.49,36.95
          c1.17,4.25,5.69,18.16,4.97,24.94c-0.72,6.78-1.9,10.93-6.14,15.81c-4.25,4.88-8.49,8.22-11.2,9.76c-2.71,1.54-5.32,3.53-4.17,6.3
          c0.75,1.81,2.56,2.38,2.56,2.38s1.28,1.32,4.58,1.28c3.3-0.04,5.6-0.18,5.6-0.18s2.2,1.63,5.64,1.5c3.44-0.13,5.42-1.67,6.7-3.35
          s2.34-1.28,3.26-5.29c0.93-4.01,4.18-4.59,6.04-5.38c2.31-0.98,3.86-2.66,3.59-5.96c-0.26-3.3-1.87-5.21-1.83-7.59
          c0.02-1.85,0.75-4.97,0.97-6.33s0.31-4.16-0.22-6.72c-0.53-2.56-1.01-5.77-1.1-7.93c-0.09-2.16,0.09-10.57,0.26-12.38
          c0.18-1.81,2.07-16.13,2.29-17.71s1.76-15.42,1.45-21.02c-0.31-5.6-1.1-12.78-1.67-15.91c-0.57-3.13-1.5-12.47-0.4-15.42
          c1.1-2.95,2.91-8.94,3.66-13.09s1.23-11.1,0.97-15.6c-0.26-4.49-1.15-17.49-1.28-19.87s-0.44-17.98-0.35-20.53
          C121.29,332.81,122.35,315.54,122.57,313.16z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.31,284.58c0.02-0.33,0.04-0.66,0.05-0.99C72.35,283.92,72.33,284.25,72.31,284.58z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.17,287.44c0.02-0.36,0.03-0.71,0.05-1.07C72.2,286.73,72.19,287.09,72.17,287.44z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.73,277.88c0.01-0.18,0.03-0.35,0.04-0.53C72.76,277.53,72.75,277.71,72.73,277.88z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.5,281.44c0.01-0.22,0.03-0.45,0.04-0.67C72.52,280.99,72.51,281.22,72.5,281.44z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.07,290.2c0.01-0.35,0.02-0.7,0.04-1.05C72.09,289.5,72.08,289.85,72.07,290.2z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.01,292.87c0-0.31,0.01-0.63,0.02-0.94C72.02,292.24,72.01,292.55,72.01,292.87z"
        />
      </g>
      <g
        id={hipsId}
        onClick={(e) => handleClick(e.target, hipsId)}
        onMouseEnter={() => setIsHovered(hipsId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path style={applyStyling(hipsId, styling2)} d="M79.01,232.19" />
        <path
          style={applyStyling(hipsId, styling2)}
          d="M121.86,283.33c-0.61-2.88-1.18-5.96-1.04-6.81c1.36,0.5,2.65,0.79,3.77,0.76c2.1-0.05,4-0.46,5.81-1.17
          c0.11,0.83-0.44,4.72-1.04,7.54c-0.4,1.87-1.07,5.65-1.46,9.52c0,0,12.56,4,25.67,4s25.65-4,25.65-4
          c-0.13-9.39-1.19-20.17-1.81-30.53c-0.99-16.44-3.25-24.94-4.79-29.09c-0.11-0.3-0.25-0.66-0.4-1.05c-0.01,0-16.65,7.5-45.78,7.5
          c-29.14,0-47.45-7.74-47.46-7.75c-0.14,0.36-0.27,0.68-0.37,0.96c-1.54,4.16-3.79,12.65-4.79,29.09
          c-0.63,10.48-1.71,21.39-1.82,30.85c0,0,11.5,4,24.81,4s26.55-4,26.55-4C122.97,289.19,122.27,285.25,121.86,283.33z"
        />
      </g>
      <g
        id={lowerArmLeftId}
        onClick={(e) => handleClick(e.target, lowerArmLeftId)}
        onMouseEnter={() => setIsHovered(lowerArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmLeftId, styling2)}
          d="M45.13,233.27c-1.84,6.36-6.48,17.11-9.61,21.23c-3.13,4.12-5.36,4.49-6.52,5.04
          c-1.16,0.55-6.17,2.67-8.23,5.77c-2.06,3.1-5.59,6.75-6.81,7.74c-1.22,0.99-4.23,2.52-3.19,3.77c1.04,1.25,3.13,2.14,5.77,1.13
          c2.54-0.98,6.51-5.73,7.92-5.83c-0.1,0.31-0.15,0.47-0.15,0.47s-3.6,11.34-4.31,13.34s-4.31,13.56-3.38,15.21
          c0.93,1.65,2.53,1.29,3.56-1.33s5.2-12.98,7.38-15.39c0,0-2.22,7.25-3.29,10.89c-1.07,3.65-3.18,10.03-0.44,10.58
          c1.68,0.34,2.71-3.16,3.29-4.71s5.11-14.01,7.03-15.34c0,0-3.68,5.39-4.71,11.7c-0.71,4.36-1.35,7.24,0.93,6.85
          c1.82-0.32,2.49-4.8,3.6-7.56s3.42-10.27,4.36-10.32c0.93-0.04,1.42,4.31,1.51,5.69s0.13,7.2,2.53,7.2s1.29-4.27,1.42-6.14
          c0.13-1.87,1.11-9.96,1.65-11.65c0.53-1.69,2.31-6.58,2.27-10.23c-0.04-3.65,0.44-7.56,1.6-10.36c1.16-2.8,6.18-12.37,8.11-16.16
          c1.07-2.1,4.62-8.39,7.89-14.1c-6.51-1.27-12.52-4-17.08-6.57L45.13,233.27z"
        />
      </g>
      <g
        id={upperArmLeftId}
        onClick={(e) => handleClick(e.target, upperArmLeftId)}
        onMouseEnter={() => setIsHovered(upperArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path style={applyStyling(upperArmLeftId, styling3)} d="M39.71,218.59" />
        <path
          style={applyStyling(upperArmLeftId, styling4)}
          d="M93.21,98.6c-6.46,0.47-11.85,2.22-16,6.71c-2.65,3.18-7.37,8.82-8.75,17.87s-1.44,16.85-1.77,21.83
        c-0.33,4.98-1.84,25.31-2.23,31.14c-0.39,5.84-0.66,11.61-1.31,13.83c-0.66,2.23-5.84,12-7.74,16c-1.26,2.66-4.58,11.02-7.21,18.2
        c4.56,2.56,10.58,5.3,17.08,6.57c1.55-2.7,3.03-5.28,4.16-7.21c0.79-1.35,1.4-2.39,1.74-2.94c1.86-3.02,5.82-9.75,6.7-11.52
        c0.89-1.77,3.66-8.8,3.9-11.75c0.24-2.95,1.3-7.67,1.83-9.56c0.44-1.55,3.63-19.02,4.57-27.56l0.1-4.49c0,0-0.75-2.16-1.6-5.21
        c-0.85-3.04,1.1-12.66,4.14-22.51C93.17,120.44,93.29,105.27,93.21,98.6z"
        />
      </g>
      <g
        id={upperArmRightId}
        onClick={(e) => handleClick(e.target, upperArmRightId)}
        onMouseEnter={() => setIsHovered(upperArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(upperArmRightId, styling4)}
          d="M157.99,99.02c6.46,0.47,11.85,2.22,16,6.71c2.65,3.18,7.37,8.82,8.75,17.87s1.44,16.85,1.77,21.83
        c0.33,4.98,1.84,25.31,2.23,31.14c0.39,5.84,0.66,11.61,1.31,13.83c0.66,2.23,5.84,12,7.74,16c1.26,2.66,4.58,11.02,7.21,18.2
        c-4.56,2.56-10.58,5.3-17.08,6.57c-1.55-2.7-3.03-5.28-4.16-7.21c-0.79-1.35-1.4-2.39-1.74-2.94c-1.86-3.02-5.82-9.75-6.7-11.52
        c-0.89-1.77-3.66-8.8-3.9-11.75c-0.24-2.95-1.3-7.67-1.83-9.56c-0.44-1.55-3.63-19.02-4.57-27.56l-0.1-4.49c0,0,0.75-2.16,1.6-5.21
        c0.85-3.04-1.1-12.66-4.14-22.51C158.04,120.86,157.92,105.69,157.99,99.02z"
        />
      </g>
      <g
        id={lowerArmRightId}
        onClick={(e) => handleClick(e.target, lowerArmRightId)}
        onMouseEnter={() => setIsHovered(lowerArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmRightId, styling2)}
          d="M206.08,233.68c1.84,6.36,6.48,17.11,9.61,21.23c3.13,4.12,5.36,4.49,6.52,5.04
          c1.16,0.55,6.17,2.67,8.23,5.77c2.06,3.1,5.59,6.75,6.81,7.74c1.22,0.99,4.23,2.52,3.19,3.77c-1.04,1.25-3.13,2.14-5.77,1.13
          c-2.54-0.98-6.51-5.73-7.92-5.83c0.1,0.31,0.15,0.47,0.15,0.47s3.6,11.34,4.31,13.34s4.31,13.56,3.38,15.21
          c-0.93,1.65-2.53,1.29-3.56-1.33s-5.2-12.98-7.38-15.39c0,0,2.22,7.25,3.29,10.89c1.07,3.65,3.18,10.03,0.44,10.58
          c-1.68,0.34-2.71-3.16-3.29-4.71s-5.11-14.01-7.03-15.34c0,0,3.68,5.39,4.71,11.7c0.71,4.36,1.35,7.24-0.93,6.85
          c-1.82-0.32-2.49-4.8-3.6-7.56s-3.42-10.27-4.36-10.32c-0.93-0.04-1.42,4.31-1.51,5.69s-0.13,7.2-2.53,7.2s-1.29-4.27-1.42-6.14
          c-0.13-1.87-1.11-9.96-1.65-11.65c-0.53-1.69-2.31-6.58-2.27-10.23c0.04-3.65-0.44-7.56-1.6-10.36
          c-1.16-2.8-6.18-12.37-8.11-16.16c-1.07-2.1-4.62-8.39-7.89-14.1c6.51-1.27,12.52-4,17.08-6.57L206.08,233.68z"
        />
      </g>
      <g
        id={abdomenId}
        onClick={(e) => handleClick(e.target, abdomenId)}
        onMouseEnter={() => setIsHovered(abdomenId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(abdomenId, styling2)}
          d="M83.71,187.45c-0.01,0.03-0.02,0.07-0.02,0.1C83.69,187.53,83.7,187.49,83.71,187.45z"
        />
        <path
          style={applyStyling(abdomenId, styling2)}
          d="M84,186.08c-0.02,0.08-0.03,0.16-0.05,0.23C83.97,186.23,83.98,186.16,84,186.08z"
        />
        <path
          style={applyStyling(abdomenId, styling2)}
          d="M83.79,187.08c-0.01,0.05-0.02,0.11-0.03,0.16C83.77,187.19,83.78,187.13,83.79,187.08z"
        />
        <path
          style={applyStyling(abdomenId, styling2)}
          d="M83.89,186.62c-0.01,0.07-0.03,0.14-0.04,0.21C83.86,186.76,83.87,186.69,83.89,186.62z"
        />
        <path
          style={applyStyling(abdomenId, styling2)}
          d="M84.13,185.44c-0.02,0.1-0.04,0.19-0.05,0.28C84.09,185.62,84.11,185.53,84.13,185.44z"
        />
        <path
          style={applyStyling(abdomenId, styling2)}
          d="M172.22,232.51c-1.98-4.95-7.19-16.24-8.4-18.91c-2.47-5.45-5.68-13.1-7.18-17
          c-0.69-1.79-0.61-3.78,0.21-5.52c5.15-10.83,5.84-29.7,5.93-33.81c0.01,0.64,0.08,1.5,0.17,2.49l-0.09-3.98
          c0,0,0.75-2.16,1.6-5.21c0.85-3.04-1.1-12.66-4.14-22.51c-2.3-7.46-2.45-22.33-2.38-29.13c0,0-27.43,0.77-32.34,0.77
          s-31.46-1.18-31.46-1.18c-0.7,0.04-1.38,0.09-2.06,0.16c0.37-0.04,0.75-0.07,1.12-0.1c0.07,6.67-0.04,21.84-2.38,29.4
          c-3.04,9.86-4.99,19.47-4.14,22.51c0.85,3.04,1.6,5.21,1.6,5.21l0.11,0.61l0.04,0.62c0.09,4.1,0.78,22.97,5.93,33.81
          c0.82,1.73,0.9,3.72,0.21,5.52c-1.5,3.9-4.71,11.54-7.18,17c-1.21,2.68-6.47,14.08-8.43,18.99c0,0,18.32,7.75,47.46,7.75
          S172.22,232.51,172.22,232.51z"
        />
        <path
          style={applyStyling(abdomenId, styling2)}
          d="M167.67,188.36c-0.03-0.12-0.06-0.24-0.09-0.35c0.01,0.03,0.02,0.06,0.02,0.08
          C167.62,188.18,167.65,188.27,167.67,188.36z"
        />
      </g>
      <g
        id={headId}
        onClick={(e) => handleClick(e.target, headId)}
        onMouseEnter={() => setIsHovered(headId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(headId, styling2)}
          d="M94.15,98.54c0,0,21.05,1.17,31.79,1.17c8.19,0,32.48-0.76,32.48-0.76c-14.92-6.57-16.3-9.37-17.04-13.52
        c-0.74-4.13-0.68-14.55-0.68-14.55l-0.04,0c0,0,0,0,0,0c6.3-6.48,9.18-15.49,9.18-15.49s5.21,0.37,6.68-9.15s-5.03-7.78-5.03-7.78
        c0-6.31,1.46-18.39-4.57-25.61c-5.29-6.34-11.73-8.94-19.87-8.94s-14.99,2.6-20.29,8.94c-6.04,7.23-4.57,19.3-4.57,25.61
        c0,0-6.49-1.74-5.03,7.78s6.68,9.15,6.68,9.15s2.73,8.34,8.64,14.74c-0.01-0.01-0.02-0.02-0.03-0.03l-0.02,0
        c0,0,0.06,10.41-0.68,14.55C111.01,88.78,108.91,92.01,94.15,98.54z M128.49,77.24c-0.43,0.04-0.86,0.08-1.3,0.09
        C127.63,77.32,128.06,77.29,128.49,77.24z M140.05,71.48c-0.05,0.05-0.1,0.1-0.15,0.15C139.95,71.57,140,71.53,140.05,71.48z
         M138.06,73.21c-0.11,0.09-0.23,0.18-0.35,0.27C137.83,73.39,137.95,73.3,138.06,73.21z M137.19,73.87
        c-0.18,0.13-0.37,0.25-0.55,0.37C136.82,74.12,137.01,74,137.19,73.87z M135.95,74.68c-0.19,0.12-0.39,0.23-0.58,0.34
        C135.57,74.91,135.76,74.8,135.95,74.68z M134.92,75.27c-0.24,0.13-0.48,0.24-0.72,0.36C134.44,75.52,134.68,75.4,134.92,75.27z
         M133.88,75.79c-0.34,0.15-0.69,0.3-1.04,0.43C133.19,76.08,133.54,75.94,133.88,75.79z M132.47,76.35
        c-0.28,0.1-0.56,0.19-0.84,0.27C131.91,76.54,132.19,76.45,132.47,76.35z M131.29,76.72c-0.38,0.11-0.78,0.2-1.17,0.28
        C130.51,76.92,130.9,76.83,131.29,76.72z M129.85,77.05c-0.33,0.06-0.67,0.11-1.01,0.15C129.18,77.16,129.52,77.11,129.85,77.05z
         M126.24,77.33c-0.44-0.03-0.87-0.08-1.29-0.13C125.37,77.25,125.8,77.3,126.24,77.33z M124.58,77.14
        c-0.31-0.05-0.62-0.11-0.93-0.17C123.96,77.03,124.27,77.09,124.58,77.14z M123.3,76.89c-0.4-0.09-0.79-0.2-1.18-0.32
        C122.5,76.69,122.9,76.8,123.3,76.89z M121.96,76.52c-0.33-0.11-0.66-0.22-0.99-0.35C121.29,76.29,121.62,76.41,121.96,76.52z
         M120.59,76.01c-0.26-0.11-0.52-0.22-0.77-0.34C120.07,75.79,120.33,75.91,120.59,76.01z M119.46,75.51
        c-0.34-0.17-0.68-0.34-1.01-0.53C118.78,75.16,119.12,75.34,119.46,75.51z M118.15,74.8c-0.24-0.14-0.47-0.28-0.7-0.43
        C117.68,74.51,117.91,74.66,118.15,74.8z M117.03,74.1c-0.2-0.13-0.39-0.27-0.58-0.41C116.65,73.83,116.84,73.96,117.03,74.1z
         M116.06,73.4c-0.28-0.21-0.56-0.43-0.83-0.65C115.5,72.98,115.78,73.19,116.06,73.4z M114.81,72.39c-0.15-0.13-0.3-0.26-0.44-0.39
        C114.51,72.14,114.66,72.27,114.81,72.39z M113.92,71.59c-0.13-0.12-0.25-0.24-0.37-0.36C113.67,71.35,113.79,71.47,113.92,71.59z"
        />
      </g>
      <g id="Female_Front">
        <path
          style={styling5}
          d="M126.24,77.33c-15.56-0.96-22.42-21.94-22.42-21.94s-5.21,0.37-6.68-9.15c-1.46-9.51,5.03-7.78,5.03-7.78
        c0-6.31-1.46-18.39,4.57-25.61c5.29-6.34,12.14-8.94,20.29-8.94s14.57,2.6,19.87,8.94c6.04,7.23,4.57,19.3,4.57,25.61
        c0,0,6.49-1.74,5.03,7.78c-1.46,9.51-6.68,9.15-6.68,9.15s-6.87,21.55-22.64,21.95c0,0-0.22,0-0.46,0
        C126.52,77.33,126.29,77.33,126.24,77.33z"
        />
        <path style={styling6} d="M112.42,70.1c0,0,0.06,10.41-0.68,14.55c-0.74,4.13-2.83,7.36-17.54,13.87" />
        <path style={styling6} d="M140.7,70.88c0,0-0.06,10.41,0.68,14.55s2.1,6.93,16.82,13.43" />
        <path
          style={styling5}
          d="M118.07,99.37c0,0-11.39-0.86-20.03-0.92s-15.68,1.29-20.83,6.86c-2.65,3.18-7.37,8.82-8.75,17.87
        s-1.44,16.85-1.77,21.83s-1.84,25.31-2.23,31.14c-0.39,5.84-0.66,11.61-1.31,13.83c-0.66,2.23-5.84,12-7.74,16
        s-8.46,20.92-10.29,27.28c-1.84,6.36-6.48,17.11-9.61,21.23c-3.13,4.12-5.36,4.49-6.52,5.04s-6.17,2.67-8.23,5.77
        s-5.59,6.75-6.81,7.74s-4.23,2.52-3.19,3.77s3.13,2.14,5.77,1.13s6.81-6.09,8.09-5.83c1.28,0.26,5.42,1.59,7.68,0.67
        s4.26-2.87,4.26-2.87"
        />
        <path
          style={styling7}
          d="M24.31,272.58c0,0-3.6,11.34-4.31,13.34s-4.31,13.56-3.38,15.21c0.93,1.65,2.53,1.29,3.56-1.33
        s5.2-12.98,7.38-15.39c0,0-2.22,7.25-3.29,10.89c-1.07,3.65-3.18,10.03-0.44,10.58c1.68,0.34,2.71-3.16,3.29-4.71
        s5.11-14.01,7.03-15.34c0,0-3.68,5.39-4.71,11.7c-0.71,4.36-1.35,7.24,0.93,6.85c1.82-0.32,2.49-4.8,3.6-7.56
        c1.11-2.76,3.42-10.27,4.36-10.32c0.93-0.04,1.42,4.31,1.51,5.69c0.09,1.38,0.13,7.2,2.53,7.2c2.4,0,1.29-4.27,1.42-6.14
        c0.13-1.87,1.11-9.96,1.65-11.65c0.53-1.69,2.31-6.58,2.27-10.23c-0.04-3.65,0.44-7.56,1.6-10.36s6.18-12.37,8.11-16.16
        c1.94-3.8,11.93-21.23,13.79-24.25c1.86-3.02,5.82-9.75,6.7-11.52s3.66-8.8,3.9-11.75c0.24-2.95,1.3-7.67,1.83-9.56
        c0.53-1.89,5.16-27.38,4.81-31.63c0,0,0.14,22.42,5.94,34.62c0.82,1.73,0.9,3.72,0.21,5.52c-1.5,3.9-4.71,11.54-7.18,17
        c-1.3,2.88-7.27,15.8-8.8,19.95c-1.54,4.16-3.79,12.65-4.79,29.09c-0.99,16.44-3.07,33.97-0.81,45.27
        c2.26,11.29,7.68,28.19,9.13,34.6c1.45,6.42,8.85,34.97,8.85,46.71c0,6.42-1.63,11.75-1.99,13.73c-0.36,1.99-3.16,19.15-0.45,34.06
        c2.71,14.91,7.32,32.71,8.49,36.95s5.69,18.16,4.97,24.94c-0.72,6.78-1.9,10.93-6.14,15.81c-4.25,4.88-8.49,8.22-11.2,9.76
        c-2.71,1.54-5.32,3.53-4.17,6.3c0.75,1.81,2.56,2.38,2.56,2.38s1.28,1.32,4.58,1.28c3.3-0.04,5.6-0.18,5.6-0.18s2.2,1.63,5.64,1.5
        c3.44-0.13,5.42-1.67,6.7-3.35c1.28-1.67,2.34-1.28,3.26-5.29c0.93-4.01,4.18-4.59,6.04-5.38c2.31-0.98,3.86-2.66,3.59-5.96
        c-0.26-3.3-1.87-5.21-1.83-7.59c0.03-1.85,0.75-4.97,0.97-6.33c0.22-1.37,0.31-4.16-0.22-6.72c-0.53-2.56-1.01-5.77-1.1-7.93
        c-0.09-2.16,0.09-10.57,0.26-12.38c0.18-1.81,2.07-16.13,2.29-17.71c0.22-1.59,1.76-15.42,1.45-21.02s-1.1-12.78-1.67-15.91
        c-0.57-3.13-1.5-12.47-0.4-15.42c1.1-2.95,2.91-8.94,3.66-13.09c0.75-4.14,1.23-11.1,0.97-15.6s-1.15-17.49-1.28-19.87
        s-0.44-17.98-0.35-20.53c0.09-2.56,1.15-19.83,1.37-22.21c0.22-2.38,1.1-10.09,1.06-15.29s-1.19-11.85-1.76-14.54
        c-0.57-2.69-1.1-5.55-1.06-6.61"
        />
        <path
          style={styling7}
          d="M94.58,124.08c-0.07,1.87-1.2,6.29-2.92,9.21c-1.72,2.92-7.57,12.73-3.82,21.5
        c3.75,8.76,12.21,10.73,19.1,9.51c6.37-1.12,9.59-4.87,9.59-4.87"
        />
        <path style={styling7} d="M82.52,136.74c0,0,2.32,5.17,4.64,6.37" />
        <path
          style={styling7}
          d="M156.58,124.07c0.07,1.87,1.2,6.29,2.92,9.21c1.72,2.92,7.57,12.73,3.82,21.5
        c-3.75,8.76-12.21,10.73-19.1,9.51c-6.37-1.12-9.59-4.87-9.59-4.87"
        />
        <path style={styling7} d="M168.64,136.73c0,0-2.32,5.17-4.64,6.37" />
        <path style={styling7} d="M67.5,189.78c0,0.15,0.91,2.38,1.83,3.24" />
        <path style={styling7} d="M77.59,193.08c0,0-2.79,2.59-5.63,3.8" />
        <path style={styling7} d="M25.71,284.71c0,0,2.7-0.67,3.57-0.67" />
        <path style={styling7} d="M37.99,275.87c0,0,0.75,3.73,1.66,5.07" />
        <path style={styling7} d="M95.62,395.76c0,0,1.26,4.73,5.76,8.28" />
        <path style={styling7} d="M114.87,395.91c0,0-3.39,4.65-5.84,5.68" />
        <path style={styling7} d="M156.4,396.31c0,0-1.26,4.73-5.76,8.28" />
        <path style={styling7} d="M137.15,396.47c0,0,3.39,4.65,5.84,5.68" />
        <path style={styling7} d="M98.37,526.9c0,0-0.12,1.32-1.65,2.02c-1.53,0.7-4.04,1.53-3.49,4.8" />
        <path style={styling7} d="M91.12,526.38c0,0-0.24,1.28-1.44,2.32c-1.19,1.04-3.3,2.32-2.47,5.41" />
        <path style={styling7} d="M87.11,526.41c0,0-2.43,1.87-3.23,2.6c-0.8,0.73-1.11,2.15-0.9,3.58" />
        <path style={styling7} d="M84.34,526.16c0,0-3.44,2.12-3.26,5.17" />
        <path
          style={styling5}
          d="M133.16,99.69c0,0,11.39-0.86,20.03-0.92c8.64-0.06,15.68,1.29,20.83,6.86c2.65,3.18,7.37,8.82,8.75,17.87
        s1.44,16.85,1.77,21.83c0.33,4.98,1.84,25.31,2.23,31.14c0.39,5.84,0.66,11.61,1.31,13.83s5.84,12,7.74,16
        c1.9,4,8.46,20.92,10.29,27.28c1.84,6.36,6.48,17.11,9.61,21.23s5.36,4.49,6.52,5.04c1.16,0.55,6.17,2.67,8.23,5.77
        c2.06,3.1,5.59,6.75,6.81,7.74c1.22,0.99,4.23,2.52,3.19,3.77s-3.13,2.14-5.77,1.13c-2.64-1.01-6.81-6.09-8.09-5.83
        c-1.28,0.26-5.42,1.59-7.68,0.67c-2.26-0.93-4.26-2.87-4.26-2.87"
        />
        <path
          style={styling7}
          d="M226.93,272.9c0,0,3.6,11.34,4.31,13.34c0.71,2,4.31,13.56,3.38,15.21s-2.53,1.29-3.56-1.33
        c-1.02-2.62-5.2-12.98-7.38-15.39c0,0,2.22,7.25,3.29,10.89c1.07,3.65,3.18,10.03,0.44,10.58c-1.68,0.34-2.71-3.16-3.29-4.71
        c-0.58-1.56-5.11-14.01-7.03-15.34c0,0,3.68,5.39,4.71,11.7c0.71,4.36,1.35,7.24-0.93,6.85c-1.82-0.32-2.49-4.8-3.6-7.56
        c-1.11-2.76-3.42-10.27-4.36-10.32s-1.42,4.31-1.51,5.69c-0.09,1.38-0.13,7.2-2.53,7.2c-2.4,0-1.29-4.27-1.42-6.14
        s-1.11-9.96-1.65-11.65c-0.53-1.69-2.31-6.58-2.27-10.23s-0.44-7.56-1.6-10.36c-1.16-2.8-6.18-12.37-8.11-16.16
        c-1.94-3.8-11.93-21.23-13.79-24.25c-1.86-3.02-5.82-9.75-6.7-11.52c-0.89-1.77-3.66-8.8-3.9-11.75s-1.3-7.67-1.83-9.56
        c-0.53-1.89-5.16-27.38-4.81-31.63c0,0-0.14,22.42-5.94,34.62c-0.82,1.73-0.9,3.72-0.21,5.52c1.5,3.9,4.71,11.54,7.18,17
        c1.3,2.88,7.27,15.8,8.8,19.95c1.54,4.16,3.79,12.65,4.79,29.09c0.99,16.44,3.07,33.97,0.81,45.27s-7.68,28.19-9.13,34.6
        c-1.45,6.42-8.85,34.97-8.85,46.71c0,6.42,1.63,11.75,1.99,13.73c0.36,1.99,3.16,19.15,0.45,34.06
        c-2.71,14.91-7.32,32.71-8.49,36.95c-1.17,4.25-5.69,18.16-4.97,24.94c0.72,6.78,1.9,10.93,6.14,15.81
        c4.25,4.88,8.49,8.22,11.2,9.76c2.71,1.54,5.32,3.53,4.17,6.3c-0.75,1.81-2.56,2.38-2.56,2.38s-1.28,1.32-4.58,1.28
        s-5.6-0.18-5.6-0.18s-2.2,1.63-5.64,1.5c-3.44-0.13-5.42-1.67-6.7-3.35s-2.34-1.28-3.26-5.29c-0.93-4.01-4.18-4.59-6.04-5.38
        c-2.31-0.98-3.86-2.66-3.59-5.96c0.26-3.3,1.87-5.21,1.83-7.59c-0.02-1.85-0.75-4.97-0.97-6.33c-0.22-1.37-0.31-4.16,0.22-6.72
        s1.01-5.77,1.1-7.93c0.09-2.16-0.09-10.57-0.26-12.38s-2.07-16.13-2.29-17.71c-0.22-1.59-1.76-15.42-1.45-21.02
        c0.31-5.6,1.1-12.78,1.67-15.91c0.57-3.13,1.5-12.47,0.4-15.42c-1.1-2.95-2.91-8.94-3.66-13.09c-0.75-4.14-1.23-11.1-0.97-15.6
        c0.26-4.49,1.15-17.49,1.28-19.87c0.13-2.38,0.44-17.98,0.35-20.53c-0.09-2.56-1.15-19.83-1.37-22.21
        c-0.22-2.38-1.1-10.09-1.06-15.29s1.19-11.85,1.76-14.54c0.57-2.69,1.09-6.35,1.05-7.4"
        />
        <path style={styling7} d="M224.53,285.03c0,0-2.7-0.67-3.57-0.67" />
        <path style={styling7} d="M213.24,276.19c0,0-0.75,3.73-1.66,5.07" />
        <path style={styling7} d="M152.86,527.22c0,0,0.12,1.32,1.65,2.02c1.53,0.7,4.04,1.53,3.49,4.8" />
        <path style={styling7} d="M160.11,526.7c0,0,0.24,1.28,1.44,2.32c1.19,1.04,3.3,2.32,2.47,5.41" />
        <path style={styling7} d="M164.12,526.73c0,0,2.43,1.87,3.23,2.6s1.11,2.15,0.9,3.58" />
        <path style={styling7} d="M166.9,526.49c0,0,3.44,2.12,3.26,5.17" />
        <path style={styling6} d="M123.09,225.72c0,0,0.79,2.12,3.45,0.74" />
        <path style={styling6} d="M105.5,266.63c1.53,1.23,12.83,10.81,19.1,10.66c8.14-0.2,13.37-5.82,21.56-12.98" />
        <path style={styling7} d="M183.59,188.37c0,0.15-0.91,2.38-1.83,3.24" />
        <path style={styling7} d="M173.5,191.67c0,0,2.79,2.59,5.63,3.8" />
        <path style={styling7} d="M116.03,79.74c0,0,2.42,11.48,3.93,14.19" />
        <path style={styling7} d="M136.77,79.23c0,0-3.32,7.45-4.53,8.86" />
      </g>
    </svg>
  )
}
