import React, { FC } from 'react'
import { useServicesContext } from '../../../../context'
import { SearchItemLabeledList } from '../SearchItemLabeledList/SearchItemLabeledList'
import styles from './SearchResultList.module.less'

/**
 * @param props The props object
 * @param props.showMatchingFiltersServices Whether to show services matching the filters
 * @returns The SearchResultList component
 */
export const SearchResultList: FC<{
  showMatchingFiltersServices: boolean
}> = ({ showMatchingFiltersServices }) => {
  const { matchingFiltersServicesData, isMatchingFiltersServicesLoading } = useServicesContext()

  return (
    <div className={styles.container}>
      <SearchItemLabeledList
        servicesData={matchingFiltersServicesData}
        isListVisible={showMatchingFiltersServices}
        isLoading={isMatchingFiltersServicesLoading}
      />
    </div>
  )
}
