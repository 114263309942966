import React, { FC, useMemo } from 'react'
import { Col } from 'antd'
import { CSSTransition } from 'react-transition-group'
import { ServiceWithDetailedOpeningHours } from '../../../../models'
import { useServicesContext } from '../../../../context'
import { ServiceDetailsMobile } from '../ServiceDetailsMobile/ServiceDetailsMobile'
import { useServiceIconProperties } from '../../../../hooks'
import { SearchItemInfo } from '../../ServicesMenu/SearchItem/SearchItemInfo/SearchItemInfo'
import { Details, StyledCard, StyledCol, StyledRow } from '../../ServicesMenu/SearchItem/SearchItem.styled'
import { CustomIcon } from '../../../CustomIcon/CustomIcon'

/**
 * @param props The props object
 * @param props.service The service to display
 * @param props.isLoading Whether a request is loading
 * @param props.onClick The onClick handler
 * @returns The SearchItemMobile component
 */
export const SearchItemMobile: FC<{
  service: ServiceWithDetailedOpeningHours
  isLoading?: boolean
  onClick: (val: ServiceWithDetailedOpeningHours | null) => void
}> = ({ service, isLoading, onClick }) => {
  const { getServiceIcon } = useServiceIconProperties()

  const { selectedService } = useServicesContext()

  const isCurrentServiceSelected = useMemo(() => service.id === selectedService?.id, [selectedService?.id, service.id])

  return (
    <CSSTransition key={service.id} in={!isLoading} timeout={200} classNames="servicesItemAnimation">
      <StyledCard>
        <StyledRow onClick={() => isCurrentServiceSelected && onClick(null)}>
          <StyledCol flex="20px">{getServiceIcon(service.type)}</StyledCol>

          <SearchItemInfo service={service} />

          <Col flex="20px">
            <Details onClick={() => !isCurrentServiceSelected && onClick(service)}>
              <CustomIcon icon="showMore" />
            </Details>
          </Col>
        </StyledRow>

        {isCurrentServiceSelected && <ServiceDetailsMobile service={service} />}
      </StyledCard>
    </CSSTransition>
  )
}
