import React, { useCallback, useMemo, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'

import { Col } from 'antd'
import { useServicesContext } from '../../../context'
import { AutocompleteResult } from '../../../models'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../../constants'
import { Filters } from './Filters/Filters'
import { SearchResultList } from './SearchResultList/SearchResultList'
import { ServicesMenuSearchBar } from './ServicesMenuSearchBar/ServicesMenuSearchBar'
import {
  CloseMenuContainer,
  Container,
  Menu,
  MenuButton,
  SearchContainer,
  SearchRow,
  StyledIcon,
} from './ServicesMenu.styled'

export type ActiveFilter = {
  [key: string]: boolean | undefined
  name?: boolean
  gender?: boolean
  insurers?: boolean
  specializations?: boolean
  openingHours?: boolean
  location?: boolean
}

/**
 * @returns The ServicesMenu component
 */
export const ServicesMenu = () => {
  const [activeFilters, setActiveFilters] = useState<ActiveFilter>({})
  const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteResult[]>([])

  const {
    setValue: setSpecializationsSearchText,
    debouncedValue: specializationsSearchText,
    value: specializationsValue,
  } = useDebouncedState('', DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS)

  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [isSpecializationsDetailedViewVisible, setIsSpecializationsDetailedViewVisible] = useState(false)
  const [isLocationsDetailedViewVisible, setIsLocationsDetailedViewVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(true)

  const {
    filterObject,
    setRequestFilter,
    searchText,
    setSearchText,
    isMatchingFiltersServicesLoading,
    setSelectedService,
  } = useServicesContext()

  const showMatchingFiltersServices = useMemo(
    () => !isFiltersOpen && !isMatchingFiltersServicesLoading,
    [isFiltersOpen, isMatchingFiltersServicesLoading],
  )

  /**
   * The menu button onClick handler
   */
  const menuOnClickHandler = useCallback(() => {
    if (isFiltersOpen) {
      if (isSpecializationsDetailedViewVisible) {
        setIsSpecializationsDetailedViewVisible(false)
        return
      }
      if (isLocationsDetailedViewVisible) {
        setIsLocationsDetailedViewVisible(false)
        return
      }
      setIsFiltersOpen(false)
    } else {
      setIsMenuOpen(true)
      setIsFiltersOpen(true)
    }
  }, [
    isFiltersOpen,
    setIsFiltersOpen,
    setIsSpecializationsDetailedViewVisible,
    isSpecializationsDetailedViewVisible,
    isLocationsDetailedViewVisible,
  ])

  const dynamicSearchValue = useMemo(
    () => (isSpecializationsDetailedViewVisible ? specializationsValue : searchText),
    [isSpecializationsDetailedViewVisible, specializationsValue, searchText],
  )

  return (
    <Container isMenuOpen={isMenuOpen}>
      {(!isFiltersOpen || isSpecializationsDetailedViewVisible || isLocationsDetailedViewVisible) && (
        <Menu>
          <SearchRow align="middle">
            <SearchContainer flex="auto">
              <ServicesMenuSearchBar
                value={dynamicSearchValue}
                setSearchText={setSearchText}
                isSpecializationsDetailedViewVisible={isSpecializationsDetailedViewVisible}
                setSpecializationsSearchText={setSpecializationsSearchText}
                isAutocomplete={isLocationsDetailedViewVisible}
                setAutocompleteResults={setAutocompleteResults}
              />
            </SearchContainer>

            <Col flex="33px">
              <MenuButton onClick={menuOnClickHandler}>
                <StyledIcon
                  icon={
                    !isSpecializationsDetailedViewVisible && !isLocationsDetailedViewVisible
                      ? 'horizontalFilter'
                      : 'close'
                  }
                />
              </MenuButton>
            </Col>

            {!isSpecializationsDetailedViewVisible && !isLocationsDetailedViewVisible && (
              <CloseMenuContainer flex="33px">
                <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <StyledIcon icon={isMenuOpen ? 'close' : 'showMore'} />
                </MenuButton>
              </CloseMenuContainer>
            )}
          </SearchRow>
        </Menu>
      )}

      {isFiltersOpen && (
        <Filters
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          searchText={specializationsSearchText}
          autocompleteResults={autocompleteResults}
          onCloseFilters={() => {
            setIsFiltersOpen(false)
            setSelectedService(null)
            setRequestFilter(filterObject)
          }}
          isLocationsDetailedViewVisible={isLocationsDetailedViewVisible}
          setIsLocationsDetailedViewVisible={setIsLocationsDetailedViewVisible}
          isSpecializationsDetailedViewVisible={isSpecializationsDetailedViewVisible}
          setIsSpecializationsDetailedViewVisible={setIsSpecializationsDetailedViewVisible}
        />
      )}

      <SearchResultList showMatchingFiltersServices={showMatchingFiltersServices} />
    </Container>
  )
}
