import React, { FC, useCallback, useMemo } from 'react'
import { ActiveFilter } from '../../ServicesMenu'
import { Specialization } from '../../../../../models'
import { FiltersWithKeys, GenderFilter, OpeningHourFilter, InsurersFilter } from '../../../../../models/servicesFilters'
import { OpeningHoursFilter } from '../OpeningHoursFilter/OpeningHoursFilter'
import { SpecializationsFilter } from '../SpecializationsFilter/SpecializationsFilter'
import { GendersFilter } from '../GendersFilter/GendersFilter'
import { LocationsFilter } from '../LocationsFilter/LocationsFilter'
import { InsurerFilter } from '../InsurerFilter/InsurerFilter'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'
import { CollapseIcon, Filter, FilterContainer, FilterRow } from './FilterHandler.styled'

/**
 * @param props The props object
 * @param props.filter The current filter
 * @param props.activeFilters The active filters
 * @param props.setActiveFilters The active filters setter
 * @param props.specializations The array of specializations
 * @param props.setIsSpecializationsDetailedViewVisible The setter for whether the specializations detailed view is visible
 * @param props.setIsLocationsDetailedViewVisible The setter for whether the locations detailed view is visible
 * @returns The FilterHandler component
 */
export const FilterHandler: FC<{
  filter: FiltersWithKeys
  specializations?: Specialization[]
  activeFilters: ActiveFilter
  setActiveFilters: (val: ActiveFilter) => void
  setIsSpecializationsDetailedViewVisible: (val: boolean) => void
  setIsLocationsDetailedViewVisible: (val: boolean) => void
}> = ({
  filter,
  specializations,
  activeFilters,
  setActiveFilters,
  setIsSpecializationsDetailedViewVisible,
  setIsLocationsDetailedViewVisible,
}) => {
  const dynamicTransformValue = useMemo(
    () => ({
      transform: `rotate(${activeFilters[filter.key] ? '-45deg' : '0deg'})`,
      transition: ' all 0.3s ease-in',
    }),
    [activeFilters, filter],
  )

  /**
   * @param isSelected Whether the button is selected
   * @returns The appropriate CSS styles
   */
  const selectedButtonStyles = useCallback((isSelected = false) => {
    return isSelected
      ? {
          background: CSS_VARS.sideMenuBackgroundColor,
          color: 'white',
        }
      : {
          background: CSS_VARS.lightPrimaryColor,
          color: 'white',
        }
  }, [])

  return (
    <FilterContainer>
      <FilterRow
        justify="space-between"
        align="middle"
        onClick={() => setActiveFilters({ ...activeFilters, [filter.key]: !activeFilters[filter.key] })}
      >
        <Filter style={{ color: CSS_VARS.filledButtonFontColor }}>{filter.text}</Filter>
        <CollapseIcon style={dynamicTransformValue} />{' '}
      </FilterRow>

      <SpecializationsFilter
        activeFilters={activeFilters}
        currentFilter={filter.key}
        specializations={specializations}
        selectedButtonStyles={selectedButtonStyles}
        setIsSpecializationsDetailedViewVisible={setIsSpecializationsDetailedViewVisible}
      />

      <GendersFilter
        activeFilters={activeFilters}
        currentFilter={filter.key}
        filter={filter as GenderFilter}
        selectedButtonStyles={selectedButtonStyles}
      />

      <InsurerFilter
        activeFilters={activeFilters}
        currentFilter={filter.key}
        filter={filter as InsurersFilter}
        selectedButtonStyles={selectedButtonStyles}
      />

      <OpeningHoursFilter
        activeFilters={activeFilters}
        currentFilter={filter.key}
        filter={filter as OpeningHourFilter}
      />

      <LocationsFilter
        activeFilters={activeFilters}
        currentFilter={filter.key}
        selectedButtonStyles={selectedButtonStyles}
        setIsLocationsDetailedViewVisible={setIsLocationsDetailedViewVisible}
      />
    </FilterContainer>
  )
}
