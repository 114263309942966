import styled from '@emotion/styled'

export const EmptyStateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`

export const DroppyImage = styled.img`
  margin-top: 30px;
`
