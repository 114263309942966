import React, { FC, useMemo } from 'react'
import { Slider } from 'antd'
import { SliderLabel } from '../SliderLabel/SliderLabel'
import { useScreenSize, useBreakpoints } from '../../../hooks'
import { OptionItem } from '../../../models/api/response/OptionItem'
import { TEMPERATURE_STEP_SIZE } from '../../../constants'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.values The option values of the current question
 * @param props.value The value provided in this property will be rendered
 * @param props.setValue This function will be called when a change on the slider is triggered
 * @param props.minValue The minimum value the slider can be set to
 * @param props.maxValue The maximum value the slider can be set to
 * @returns The TemperatureSlider component
 */
export const TemperatureSlider: FC<{
  values: OptionItem[]
  value: number
  setValue: (val: number) => void
  minValue: number
  maxValue: number
}> = ({ values, value, setValue, minValue, maxValue }) => {
  const { isScreenSizeXS } = useBreakpoints()
  const { isMobileView, isMobileLandscapeView, isTabletView, isTabletLandscapeView } = useScreenSize()

  const indicatorPosition = useMemo(() => {
    return ((value - minValue) / (maxValue - minValue)) * 100
  }, [value, minValue, maxValue])

  /**
   * These values are used for offsetting the labels, if they are not used, the labels will collide
   */
  const temperatureDifference = useMemo(() => {
    if (isMobileView) {
      return 0.9
    } else if (isMobileLandscapeView || isTabletView || isTabletLandscapeView) {
      return 0.7
    } else {
      return 0.4
    }
  }, [isMobileLandscapeView, isMobileView, isTabletLandscapeView, isTabletView])

  const temperatureQuestionMarks = useMemo(
    () => ({
      [minValue]: {
        style: { top: '5px', left: 0, transform: 'none' },
        label: <SliderLabel value={value <= minValue + temperatureDifference ? `${value}°C` : `${minValue}°C`} />,
      },
      [maxValue]: {
        style: { top: '5px', right: 0, left: 'auto', transform: 'none' },
        label: <SliderLabel value={value >= maxValue - temperatureDifference ? `${value}°C` : `${maxValue}°C`} />,
      },
      [maxValue + 100]: {
        style: { top: '-40px', left: `${indicatorPosition}%` },
        label: (
          <span style={{ color: CSS_VARS.chatFontColor, fontSize: isScreenSizeXS ? '3vw' : CSS_VARS.chatFontSize }}>
            <strong>
              {value > minValue + temperatureDifference && value < maxValue - temperatureDifference && `${value}°C`}
            </strong>
          </span>
        ),
      },
    }),
    [value, minValue, temperatureDifference, maxValue, isScreenSizeXS, indicatorPosition],
  )

  return (
    <Slider
      id="temperature_slider"
      min={minValue}
      max={maxValue}
      marks={temperatureQuestionMarks}
      // Z-index values are needed for making the background of the track dynamically stylable
      trackStyle={{ backgroundColor: CSS_VARS.sliderTrackColor, zIndex: 1 }}
      handleStyle={{
        zIndex: 2,
      }}
      step={TEMPERATURE_STEP_SIZE}
      tooltip={{ open: false }}
      onChange={(val) => setValue(val)}
      value={value}
    />
  )
}
