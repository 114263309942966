// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hV3wmshwBiST48rOpaHR {\n  width: 100%;\n}\n.q0fvIclCm61TqE_IbomG {\n  max-height: 400px;\n  overflow: scroll;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SymptomSuggestionSelector/SymptomSuggestionSelector.module.less"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAIA;EACE,iBAAA;EACA,gBAAA;AAFF","sourcesContent":["@import '../../variables.less';\n\n.container {\n  width: 100%;\n}\n\n.symptomItemList {\n  max-height: 400px;\n  overflow: scroll;\n}\n\n@media screen and (max-height: @mobile-max-width) and (orientation: portrait),\n  screen and (max-width: @mobile-max-width) and (orientation: portrait) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "hV3wmshwBiST48rOpaHR",
	"symptomItemList": "q0fvIclCm61TqE_IbomG"
};
export default ___CSS_LOADER_EXPORT___;
