// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mQU7uzsnHNwpUOoBz8Lj {\n  height: 10%;\n  overflow: scroll;\n}\n.mQU7uzsnHNwpUOoBz8Lj .nGg99qdQeVaxJyNIrwrN {\n  margin: 10px 0;\n  cursor: pointer;\n}\n.mQU7uzsnHNwpUOoBz8Lj .Kf84FkEgemAe2Hd7jRWm {\n  border-radius: 50%;\n  padding: 5px;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid transparent;\n  cursor: pointer;\n  transition: all 0.2s ease-in-out;\n}\n@media screen and (max-width: 576px) and (max-height: 1368px) {\n  .nGg99qdQeVaxJyNIrwrN {\n    min-width: 85vw;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Services/ServicesMap/ServicesMapMarkerPopoverContent/ServicesMapMarkerPopoverContent.module.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,gBAAA;AADF;AADA;EAKI,cAAA;EACA,eAAA;AADJ;AALA;EAUI,kBAAA;EACA,YAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;EACA,eAAA;EACA,gCAAA;AAFJ;AAMA;EAKI;IACE,eAAA;EARJ;AACF","sourcesContent":["@import '../../../../variables.less';\n\n.markerGroupList {\n  height: 10%;\n  overflow: scroll;\n\n  .markerGroupItem {\n    margin: 10px 0;\n    cursor: pointer;\n  }\n  \n  .markerGroupItemIcon {\n    border-radius: 50%;\n    padding: 5px;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid transparent;\n    cursor: pointer;\n    transition: all 0.2s ease-in-out;\n  }\n}\n\n@media \n screen\n and (max-width: @mobile-max-width)\n and (max-height: @tablet-large-max-width)\n {\n    .markerGroupItem {\n      min-width: 85vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markerGroupList": "mQU7uzsnHNwpUOoBz8Lj",
	"markerGroupItem": "nGg99qdQeVaxJyNIrwrN",
	"markerGroupItemIcon": "Kf84FkEgemAe2Hd7jRWm"
};
export default ___CSS_LOADER_EXPORT___;
