/**
 * @param userDistance The user distance in meters
 * @returns The formatted distance in meters or kilometers
 */
export const formatDistanceToMetersOrKilometers = (userDistance: number) => {
  const kilometerInMeters = 1000

  const isUserDistanceLessThanAKilometer = userDistance < kilometerInMeters

  if (isUserDistanceLessThanAKilometer) {
    return `${userDistance}m`
  } else {
    return `${(userDistance / kilometerInMeters).toFixed(1)}km`
  }
}
