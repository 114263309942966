import React, { CSSProperties, FC, useMemo } from 'react'
import { backIds } from '../bodyPartIds'
import { femaleStyles } from '../styles'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

const { occiputId, backId, upperArmLeftId, upperArmRightId, lowerArmLeftId, lowerArmRightId, lowerBackId, legsId } =
  backIds

/**
 * @param props The props object
 * @param props.handleClick The click handler method
 * @param props.applyStyling The method which applies appropriate stylings for components
 * @param props.setIsHovered The setter for whether a component is hovered
 * @returns The FemaleBodyBackSVG component
 */
export const FemaleBodyBackSVG: FC<{
  handleClick: (eventTarget: EventTarget, bodyPartId: string) => void
  applyStyling: (id: string, styling: CSSProperties) => CSSProperties
  setIsHovered: (val: string) => void
}> = ({ handleClick, applyStyling, setIsHovered }) => {
  const femaleStylings = useMemo(
    () => femaleStyles(CSS_VARS.bodyPartSelectorSVGBodyOutlineAndHoverColor, CSS_VARS.bodyPartSelectorSVGColor),
    [],
  )

  const { styling2, styling3, styling4, styling5, styling6, styling7 } = femaleStylings

  return (
    <svg
      data-testid="femaleBackSVG"
      className="bodySvg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 253.84 541.61"
    >
      <g
        id={legsId}
        onClick={(e) => handleClick(e.target, legsId)}
        onMouseEnter={() => setIsHovered(legsId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path style={applyStyling(legsId, styling2)} d="M123.35,293.1" />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.95,274.87c0.02-0.33,0.05-0.66,0.07-0.99C73,274.21,72.97,274.54,72.95,274.87z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M127.61,298.19c-0.04,5.2,0.84,12.91,1.06,15.29s1.28,19.65,1.37,22.21s-0.22,18.15-0.35,20.53
          c-0.13,2.38-1.01,15.38-1.28,19.87s0.22,11.46,0.97,15.6s2.56,10.13,3.66,13.09c1.1,2.95,0.18,12.29-0.4,15.42
          c-0.57,3.13-1.37,10.31-1.67,15.91c-0.31,5.6,1.23,19.43,1.45,21.02c0.22,1.59,2.11,15.91,2.29,17.71
          c0.18,1.81,0.64,10.45,0.55,12.61s0.28,7.66-0.25,10.22c-0.53,2.56-1.35,7.05-1.13,8.42c0.22,1.37,0.26,4.02,0.29,5.87
          c0.03,2.38-2.74,15.97-0.77,19.39c1.5,2.61,3.48,4.13,7.33,4.29c2.02,0.08,2.87,0.16,4.74-0.4c3.84-1.15,4.27-1.91,4.49-2.08
          c1.68-1.26,4.6-4.28,8.04-4.15c3.44,0.13,7-1.72,7-1.72s1.21-0.62,2.31-1.64c2.43-2.24,1.78-1.89,1.78-1.89s0.89,0,1.64-1.81
          c1.15-2.77-3.72-3.3-7.28-3.75c-3.09-0.39-4.37-1.98-7.9-4.32c-2.23-1.48-4.56-1.79-5.42-3.7c-1.21-2.71-0.21-8.66,0.48-12.59
          c1.67-9.42,3.22-19.01,4.39-23.26c1.17-4.25,5-22.39,7.71-37.3c2.71-14.91-0.09-32.08-0.45-34.06c-0.36-1.99-1.99-7.32-1.99-13.73
          c0-11.75,7.41-40.3,8.85-46.71c1.45-6.42,6.87-23.31,9.13-34.6c0.84-4.18,1.08-9.22,1-14.74c-0.04,0.01-12.56,4-25.65,4
          c-13.11,0-25.67-4-25.67-4s-0.21,1.16-0.25,2.36C127.63,296.74,127.61,298.19,127.61,298.19z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M122.57,313.16c0.22-2.38,1.1-10.09,1.06-15.29c-0.01-1.49-0.12-3.1-0.27-4.69c-0.02,0.01-13.24,4-26.54,4
          c-13.26,0-24.71-3.97-24.8-4c-0.06,5.39,0.18,10.31,1,14.41c2.26,11.29,7.68,28.19,9.13,34.6c1.45,6.42,8.85,34.97,8.85,46.71
          c0,6.41-1.63,11.75-1.99,13.73c-0.36,1.99-3.16,19.15-0.45,34.06c2.71,14.91,7.32,32.71,8.49,36.95
          c1.17,4.25,4.36,18.99,4.83,26.21c0.29,4.42,1.42,8.98,0.23,10.85c-0.33,0.52-1.64,1.5-3.19,1.97c-1.75,0.53-4.11,2.85-5.54,3.66
          c-2.71,1.54-12.89,2.49-11.74,5.26c0.75,1.81,3.52,4.23,3.52,4.23s3.32,2.62,8.78,3.24c1.64,0.19,2.68,0.75,2.68,0.75
          s0.99-0.38,3.15,1.69c2.22,2.12,3.29,1.93,5.59,3.24c1.83,1.04,3.95,0.61,5.68,0.8c4.09,0.44,5.14-1.84,7-2.63
          c2.31-0.98,0.68-14.16,0.42-17.46c-0.26-3.3-0.17-7.87-0.14-10.25c0.02-1.85-0.94-2.32-0.72-3.68s-0.28-3.46-0.6-6.85
          c-0.24-2.6-0.64-5.64-0.72-7.8c-0.09-2.16,0.09-10.57,0.26-12.38c0.18-1.81,2.07-16.13,2.29-17.71s1.76-15.42,1.45-21.02
          c-0.31-5.6-1.1-12.78-1.67-15.91c-0.57-3.13-1.5-12.47-0.4-15.42c1.1-2.95,2.91-8.94,3.66-13.09s1.23-11.1,0.97-15.6
          c-0.26-4.49-1.15-17.49-1.28-19.87s-0.44-17.98-0.35-20.53C121.29,332.81,122.35,315.54,122.57,313.16z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.31,284.58c0.02-0.33,0.04-0.66,0.05-0.99C72.35,283.92,72.33,284.25,72.31,284.58z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.17,287.44c0.02-0.36,0.03-0.71,0.05-1.07C72.2,286.73,72.19,287.09,72.17,287.44z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.73,277.88c0.01-0.18,0.03-0.35,0.04-0.53C72.76,277.53,72.75,277.71,72.73,277.88z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.5,281.44c0.01-0.22,0.03-0.45,0.04-0.67C72.52,280.99,72.51,281.22,72.5,281.44z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.07,290.2c0.01-0.35,0.02-0.7,0.04-1.05C72.09,289.5,72.08,289.85,72.07,290.2z"
        />
        <path
          style={applyStyling(legsId, styling2)}
          d="M72.01,292.87c0-0.31,0.01-0.63,0.02-0.94C72.02,292.24,72.01,292.55,72.01,292.87z"
        />
      </g>
      <g
        id={lowerBackId}
        onClick={(e) => handleClick(e.target, lowerBackId)}
        onMouseEnter={() => setIsHovered(lowerBackId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path style={applyStyling(lowerBackId, styling2)} d="M79.01,232.19" />
        <path
          style={applyStyling(lowerBackId, styling2)}
          d="M121.86,283.33c-0.28-1.58,1.88-3.13,1.88-3.13s2.07-3.62,2.1-3.46c0.41,2.06,1.56,3.24,1.56,3.24
          s2.51,1.14,1.97,3.68c-0.39,1.87-1.07,5.65-1.46,9.52c0,0,12.56,4,25.67,4s25.65-4,25.65-4c-0.13-9.39-1.19-20.17-1.81-30.53
          c-0.99-16.44-3.25-24.94-4.79-29.09c-0.11-0.3-0.25-0.66-0.4-1.05c-0.01,0-16.65,7.5-45.78,7.5c-29.14,0-47.45-7.74-47.46-7.75
          c-0.14,0.36-0.27,0.68-0.37,0.96c-1.54,4.16-3.79,12.65-4.79,29.09c-0.63,10.48-1.71,21.39-1.82,30.85c0,0,11.5,4,24.81,4
          s26.55-4,26.55-4C122.97,289.19,122.21,285.26,121.86,283.33z"
        />
      </g>
      <g
        id={lowerArmLeftId}
        onClick={(e) => handleClick(e.target, lowerArmLeftId)}
        onMouseEnter={() => setIsHovered(lowerArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmLeftId, styling2)}
          d="M45.13,233.27c-1.84,6.36-2.03,6.35-4.12,11.66c-2.57,6.52-5.03,11.87-5.77,12.91
          c-2.46,3.42-5.72,4.73-7.3,8.1c-3.45,7.37-11.79,16.07-13.01,17.06c-1.22,0.99-2.54,1.94-1.49,3.19c1.04,1.25,1.74,0.18,4.38-0.83
          c2.54-0.98,5.85-4.94,7.27-5.04c-0.1,0.31,0.8,0.53,0.8,0.53s-0.88,2.01-1.59,4.02s-6.77,12.21-6.24,13.21
          c0.89,1.67,2.56,2.26,3.58-0.37s4.55-4.75,7.13-12.48c0,0-3.43,6.44-4.5,10.09c-1.07,3.65-4.88,8.7-2.14,9.26
          c1.68,0.34,3.15-1.87,4.11-3.22c2.85-4.02,7.3-14.01,8.63-14.27c0,0-2.22,7.53-5.97,15.13c-1.95,3.96-0.07,4.11,1.46,3.92
          c1.83-0.24,6.69-9.36,8.1-11.98c4.6-8.59,6.11-13.21,7.04-13.26c0.93-0.04,1.33,6.41,1.42,7.79s1.24,4.88,3.64,4.88
          s2.2-0.33,2.34-2.2c0.13-1.87-1.01-5.46-0.48-7.15c0.53-1.69,1.24-8.59,0.21-12.85c-0.86-3.54-2.6-7.05-2.06-10.03
          c0.82-4.6,4.93-12.67,6.86-16.47c1.07-2.1,4.62-8.39,7.89-14.1c-6.51-1.27-12.52-4-17.08-6.57L45.13,233.27z"
        />
      </g>
      <g
        id={upperArmLeftId}
        onClick={(e) => handleClick(e.target, upperArmLeftId)}
        onMouseEnter={() => setIsHovered(upperArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path style={applyStyling(upperArmLeftId, styling3)} d="M39.71,218.59" />
        <path
          style={applyStyling(upperArmLeftId, styling4)}
          d="M93.21,98.6c-6.46,0.47-11.85,2.22-16,6.71c-2.65,3.18-7.37,8.82-8.75,17.87s-1.44,16.85-1.77,21.83
        c-0.33,4.98-1.84,25.31-2.23,31.14c-0.39,5.84-0.66,11.61-1.31,13.83c-0.66,2.23-5.84,12-7.74,16c-1.26,2.66-4.58,11.02-7.21,18.2
        c4.56,2.56,10.58,5.3,17.08,6.57c1.55-2.7,3.03-5.28,4.16-7.21c0.79-1.35,1.4-2.39,1.74-2.94c1.86-3.02,5.82-9.75,6.7-11.52
        c0.89-1.77,3.66-8.8,3.9-11.75c0.24-2.95,1.3-7.67,1.83-9.56c0.44-1.55,3.63-19.02,4.57-27.56l0.1-4.49c0,0,0.63-4.49-0.22-7.54
        c-0.46-1.63,0.16-2.56,0.2-7.23c0.03-2.94,1.15-8.39,2.56-12.96C93.17,120.44,93.29,105.27,93.21,98.6z"
        />
      </g>
      <g
        id={lowerArmRightId}
        onClick={(e) => handleClick(e.target, lowerArmRightId)}
        onMouseEnter={() => setIsHovered(lowerArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmRightId, styling2)}
          d="M206.74,234.82c1.84,6.36,2.05,5.62,4.14,10.93c2.57,6.52,5.17,11.2,5.92,12.24c2.46,3.42,6.13,5.91,7.3,8.1
          c3.85,7.17,11.79,16.07,13.01,17.06c1.22,0.99,2.54,1.94,1.49,3.19c-1.04,1.25-1.74,0.18-4.38-0.83
          c-2.54-0.98-5.85-4.94-7.27-5.04c0.1,0.31-0.8,0.53-0.8,0.53s0.88,2.01,1.59,4.02s6.77,12.21,6.24,13.21
          c-0.89,1.67-2.41,1.35-3.58-0.37c-1.59-2.33-4.55-4.75-7.13-12.48c0,0,3.43,6.44,4.5,10.09c1.07,3.65,4.88,8.7,2.14,9.26
          c-1.68,0.34-3.15-1.87-4.11-3.22c-2.85-4.02-7.3-14.01-8.63-14.27c0,0,2.22,7.53,5.97,15.13c1.95,3.96,0.07,4.11-1.46,3.92
          c-1.83-0.24-6.69-9.36-8.1-11.98c-4.6-8.59-6.11-13.21-7.04-13.26c-0.93-0.04-0.61,6.69-0.7,8.07s-1.95,4.6-4.36,4.6
          s-1.83,0.56-1.96-1.31c-0.13-1.87,0.75-7.49,0.21-9.18c-0.53-1.69-1.35-7.45-0.31-11.71c0.86-3.54,2.6-7.05,2.06-10.03
          c-0.82-4.6-5.54-12.09-7.48-15.88c-1.07-2.1-4.83-8.72-8.1-14.42c6.51-1.27,12.52-4,17.08-6.57L206.74,234.82z"
        />
      </g>
      <g
        id={upperArmRightId}
        onClick={(e) => handleClick(e.target, upperArmRightId)}
        onMouseEnter={() => setIsHovered(upperArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(upperArmRightId, styling4)}
          d="M157.99,99.02c6.46,0.47,11.85,2.22,16,6.71c2.65,3.18,7.37,8.82,8.75,17.87s1.44,16.85,1.77,21.83
        c0.33,4.98,1.84,25.31,2.23,31.14c0.39,5.84,0.66,11.61,1.31,13.83c0.66,2.23,5.84,12,7.74,16c1.26,2.66,4.58,11.02,7.21,18.2
        c-4.56,2.56-10.58,5.3-17.08,6.57c-1.55-2.7-3.03-5.28-4.16-7.21c-0.79-1.35-1.4-2.39-1.74-2.94c-1.86-3.02-5.82-9.75-6.7-11.52
        c-0.89-1.77-3.66-8.8-3.9-11.75c-0.24-2.95-1.3-7.67-1.83-9.56c-0.44-1.55-3.63-19.02-4.57-27.56l-0.1-4.49
        c0,0,0.12-2.42,0.18-6.22c0.05-3.16,0.33-11.64-2.72-21.5C158.04,120.86,157.92,105.69,157.99,99.02z"
        />
      </g>
      <g
        id={backId}
        onClick={(e) => handleClick(e.target, backId)}
        onMouseEnter={() => setIsHovered(backId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(backId, styling2)}
          d="M83.71,187.45c-0.01,0.03-0.02,0.07-0.02,0.1C83.69,187.53,83.7,187.49,83.71,187.45z"
        />
        <path
          style={applyStyling(backId, styling2)}
          d="M84,186.08c-0.02,0.08-0.03,0.16-0.05,0.23C83.97,186.23,83.98,186.16,84,186.08z"
        />
        <path
          style={applyStyling(backId, styling2)}
          d="M83.79,187.08c-0.01,0.05-0.02,0.11-0.03,0.16C83.77,187.19,83.78,187.13,83.79,187.08z"
        />
        <path
          style={applyStyling(backId, styling2)}
          d="M83.89,186.62c-0.01,0.07-0.03,0.14-0.04,0.21C83.86,186.76,83.87,186.69,83.89,186.62z"
        />
        <path
          style={applyStyling(backId, styling2)}
          d="M84.13,185.44c-0.02,0.1-0.04,0.19-0.05,0.28C84.09,185.62,84.11,185.53,84.13,185.44z"
        />
        <path
          style={applyStyling(backId, styling2)}
          d="M172.22,232.51c-1.98-4.95-7.19-16.24-8.4-18.91c-2.47-5.45-5.68-13.1-7.18-17
          c-0.69-1.79-0.61-3.78,0.21-5.52c5.15-10.83,5.84-29.7,5.93-33.81c0.01,0.64,0.08,1.5,0.17,2.49l-0.09-3.98
          c0,0,0.04-1.9,0.22-5.87c-0.01-5.94,0.29-11.99-2.76-21.85c-2.3-7.46-2.45-22.33-2.38-29.13c0,0-27.43,0.77-32.34,0.77
          s-31.46-1.18-31.46-1.18c-0.7,0.04-1.38,0.09-2.06,0.16c0.37-0.04,0.75-0.07,1.12-0.1c0.07,6.67-0.04,21.84-2.38,29.4
          c-3.04,9.86-2.95,15.75-2.69,22.53c0.85,3.04,0.16,5.19,0.16,5.19l0.11,0.61l0.04,0.62c0.09,4.1,0.78,22.97,5.93,33.81
          c0.82,1.73,0.9,3.72,0.21,5.52c-1.5,3.9-4.71,11.54-7.18,17c-1.21,2.68-6.47,14.08-8.43,18.99c0,0,18.32,7.75,47.46,7.75
          S172.22,232.51,172.22,232.51z"
        />
        <path
          style={applyStyling(backId, styling2)}
          d="M167.67,188.36c-0.03-0.12-0.06-0.24-0.09-0.35c0.01,0.03,0.02,0.06,0.02,0.08
          C167.62,188.18,167.65,188.27,167.67,188.36z"
        />
      </g>
      <g
        id={occiputId}
        onClick={(e) => handleClick(e.target, occiputId)}
        onMouseEnter={() => setIsHovered(occiputId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(occiputId, styling2)}
          d="M94.15,98.54c0,0,21.05,1.17,31.79,1.17c8.19,0,32.48-0.76,32.48-0.76c-14.92-6.57-16.3-9.37-17.04-13.52
        c-0.74-4.13-0.68-14.55-0.68-14.55l-0.04,0c0,0,0,0,0,0c6.3-6.48,9.18-15.49,9.18-15.49s5.21,0.37,6.68-9.15s-5.03-7.78-5.03-7.78
        c0-6.31,1.46-18.39-4.57-25.61c-5.29-6.34-11.73-8.94-19.87-8.94s-14.99,2.6-20.29,8.94c-6.04,7.23-4.57,19.3-4.57,25.61
        c0,0-6.49-1.74-5.03,7.78s6.68,9.15,6.68,9.15s2.73,8.34,8.64,14.74c-0.01-0.01-0.02-0.02-0.03-0.03l-0.02,0
        c0,0,0.06,10.41-0.68,14.55C111.01,88.78,108.91,92.01,94.15,98.54z M128.49,77.24c-0.43,0.04-0.86,0.08-1.3,0.09
        C127.63,77.32,128.06,77.29,128.49,77.24z M140.05,71.48c-0.05,0.05-0.1,0.1-0.15,0.15C139.95,71.57,140,71.53,140.05,71.48z
         M138.06,73.21c-0.11,0.09-0.23,0.18-0.35,0.27C137.83,73.39,137.95,73.3,138.06,73.21z M137.19,73.87
        c-0.18,0.13-0.37,0.25-0.55,0.37C136.82,74.12,137.01,74,137.19,73.87z M135.95,74.68c-0.19,0.12-0.39,0.23-0.58,0.34
        C135.57,74.91,135.76,74.8,135.95,74.68z M134.92,75.27c-0.24,0.13-0.48,0.24-0.72,0.36C134.44,75.52,134.68,75.4,134.92,75.27z
         M133.88,75.79c-0.34,0.15-0.69,0.3-1.04,0.43C133.19,76.08,133.54,75.94,133.88,75.79z M132.47,76.35
        c-0.28,0.1-0.56,0.19-0.84,0.27C131.91,76.54,132.19,76.45,132.47,76.35z M131.29,76.72c-0.38,0.11-0.78,0.2-1.17,0.28
        C130.51,76.92,130.9,76.83,131.29,76.72z M129.85,77.05c-0.33,0.06-0.67,0.11-1.01,0.15C129.18,77.16,129.52,77.11,129.85,77.05z
         M126.24,77.33c-0.44-0.03-0.87-0.08-1.29-0.13C125.37,77.25,125.8,77.3,126.24,77.33z M124.58,77.14
        c-0.31-0.05-0.62-0.11-0.93-0.17C123.96,77.03,124.27,77.09,124.58,77.14z M123.3,76.89c-0.4-0.09-0.79-0.2-1.18-0.32
        C122.5,76.69,122.9,76.8,123.3,76.89z M121.96,76.52c-0.33-0.11-0.66-0.22-0.99-0.35C121.29,76.29,121.62,76.41,121.96,76.52z
         M120.59,76.01c-0.26-0.11-0.52-0.22-0.77-0.34C120.07,75.79,120.33,75.91,120.59,76.01z M119.46,75.51
        c-0.34-0.17-0.68-0.34-1.01-0.53C118.78,75.16,119.12,75.34,119.46,75.51z M118.15,74.8c-0.24-0.14-0.47-0.28-0.7-0.43
        C117.68,74.51,117.91,74.66,118.15,74.8z M117.03,74.1c-0.2-0.13-0.39-0.27-0.58-0.41C116.65,73.83,116.84,73.96,117.03,74.1z
         M116.06,73.4c-0.28-0.21-0.56-0.43-0.83-0.65C115.5,72.98,115.78,73.19,116.06,73.4z M114.81,72.39c-0.15-0.13-0.3-0.26-0.44-0.39
        C114.51,72.14,114.66,72.27,114.81,72.39z M113.92,71.59c-0.13-0.12-0.25-0.24-0.37-0.36C113.67,71.35,113.79,71.47,113.92,71.59z"
        />
      </g>
      <g id="Female_Back">
        <path
          style={styling5}
          d="M121.71,282.47c0.06,0.3,0.13,0.61,0.19,0.91c0.57,2.69,1.72,9.34,1.76,14.54s-0.84,12.91-1.06,15.29
        s-1.28,19.65-1.37,22.21s0.22,18.15,0.35,20.53s1.01,15.38,1.28,19.87s-0.22,11.46-0.97,15.6s-2.56,10.13-3.66,13.09
        s-0.18,12.29,0.4,15.42s1.37,10.31,1.67,15.91s-1.23,19.43-1.45,21.02s-2.11,15.91-2.29,17.71c-0.18,1.81-0.39,8.9-0.31,11.06
        c0.12,2.99,0.64,9.97,0.78,11.24c0.27,2.42,0.27,3.62,0.94,5.91c0.67,2.28,0.34,5.64,0,7.92c-0.34,2.28,0.34,5.84,0.81,7.99
        c0.47,2.15,1.54,8.32,0.34,12.22c-1.21,3.89-3.96,4.7-7.59,4.83c-3.62,0.13-8.86-1.54-11.48-4.03c-2.62-2.48-6.11-2.75-7.72-2.89
        c-1.61-0.13-5.75-0.99-7.08-2.71c-1.33-1.72-1.94-2.1-2.56-2.46c-0.62-0.36-1.91-1.49-0.74-3.24c1.17-1.75,2.88-1.75,3.79-1.75
        s4.86-0.36,6.83-1.78c1.97-1.42,7.8-5.41,9.58-5.76"
        />
        <path
          style={styling5}
          d="M38.94,270.17c0,0-0.02,2.79,0.88,5.06c0.89,2.27,4.72,4.34,5.79,5.08s0.3,7.26,1.39,9.87s3.18,3.49,4.81,3.38
        c1.62-0.11,0.65-3.35,0.51-4.91s0.06-6.64,0.87-10.27c0.82-3.63-1.13-8.71-1.53-9.93s-1.68-3.09-0.87-8.2
        c0.21-1.31,0.33-2.3,1.27-4.86c2.19-4.99,4.21-8.19,5.39-10.5c1.94-3.8,11.93-21.23,13.79-24.25c1.86-3.02,5.82-9.75,6.7-11.52
        c0.89-1.77,3.66-8.8,3.9-11.75s1.3-7.67,1.83-9.56c0.39-1.38,4.82-17.66,4.61-46.85c-0.02-3.42,0.29,16.38,0.2,15.22
        c0,0,0.14,22.42,5.94,34.62c0.82,1.73,0.9,3.72,0.21,5.52c-1.5,3.9-4.71,11.54-7.18,17c-1.3,2.88-7.27,15.8-8.8,19.95
        s-3.79,12.65-4.79,29.09c-0.99,16.44-3.07,33.97-0.81,45.27c2.26,11.29,7.68,28.19,9.13,34.6s8.85,34.97,8.85,46.71
        c0,6.41-1.63,11.75-1.99,13.73s-3.16,19.15-0.45,34.06c2.59,14.26,6.92,31.16,8.32,36.32c0.13,0.55,0.25,1,0.35,1.36
        c0.84,3.05,2.95,14.28,4.06,21.08c0,0,1.73,13.71,0.78,16.82c-0.32,1.05-1.54,2.03-1.54,2.03"
        />
        <path style={styling6} d="M82.56,136.79c0,0,2.34,5.65,5.62,7.19" />
        <path style={styling6} d="M168.68,136.79c0,0-2.09,5.52-5.6,7.19" />
        <path style={styling6} d="M67.54,189.84c0,0.15,0.91,2.38,1.83,3.24" />
        <path style={styling6} d="M77.63,193.13c0,0-2.79,2.59-5.63,3.8" />
        <path style={styling6} d="M35.91,287.65c0,0-1.38-2.41-1.98-3.04" />
        <path style={styling6} d="M33.64,273.26c0,0-3.21,2.03-4.81,2.31" />
        <path style={styling6} d="M96.99,402.83c0,0,1.69,3.77,5.58,6.1" />
        <path style={styling6} d="M112.63,401.24c0,0-2.27,3.22-3.91,3.93" />
        <path style={styling6} d="M155.2,402.59c0,0-1.69,3.77-5.58,6.1" />
        <path style={styling6} d="M139.57,401c0,0,2.27,3.22,3.91,3.93" />
        <path
          style={styling6}
          d="M213.23,270.68c0,0,0.03,2.79-0.85,5.07s-4.7,4.37-5.77,5.11c-1.07,0.75-0.26,7.26-1.34,9.87
        s-3.17,3.51-4.79,3.41s-0.66-3.34-0.54-4.91c0.12-1.56-0.09-6.64-0.92-10.27c-0.83-3.63,1.08-8.72,1.48-9.94s1.76-2.93,0.92-8.03
        c-0.06-0.39-0.28-1.08-0.46-1.79c-1.86-3.91-5.49-10.79-7.11-13.97c-1.94-3.8-11.93-21.23-13.79-24.25
        c-1.86-3.02-5.82-9.75-6.7-11.52c-0.89-1.77-3.66-8.8-3.9-11.75c-0.24-2.95-1.28-7.68-1.83-9.56c-1.84-6.27-5.75-32.81-4.66-47.18
        c0,0,1.03,34.74-6.09,50.17c-0.8,1.74-0.9,3.72-0.21,5.52c1.5,3.9,4.71,11.54,7.18,17c1.3,2.88,7.27,15.8,8.8,19.95
        c1.54,4.16,3.79,12.65,4.79,29.09c0.99,16.44,3.07,33.97,0.81,45.27c-2.26,11.29-7.68,28.19-9.13,34.6s-8.85,34.97-8.85,46.71
        c0,6.42,1.63,11.75,1.99,13.73c0.36,1.99,3.16,19.15,0.45,34.06s-6.56,33-7.73,37.25c-0.84,3.05-2.95,14.28-4.06,21.08
        c0,0-1.73,13.71-0.78,16.82c0.32,1.05,1.54,2.03,1.54,2.03"
        />
        <path
          style={styling6}
          d="M129.7,282.24c-0.09,0.5-0.19,1-0.29,1.46c-0.57,2.69-1.72,9.34-1.76,14.54s0.84,12.91,1.06,15.29
        s1.28,19.65,1.37,22.21s-0.22,18.15-0.35,20.53s-1.01,15.38-1.28,19.87s0.22,11.46,0.97,15.6s2.56,10.13,3.66,13.09
        c1.1,2.95,0.18,12.29-0.4,15.42c-0.57,3.13-1.37,10.31-1.67,15.91c-0.31,5.6,1.23,19.43,1.45,21.02s2.11,15.91,2.29,17.71
        c0.18,1.81,0.47,10.58,0.53,12.73c0.07,2.22,0.09,7.73-0.07,9.13c-0.27,2.42-0.27,3.62-0.94,5.91c-0.67,2.28-0.34,5.64,0,7.92
        c0.34,2.28-0.34,5.84-0.81,7.99c-0.47,2.15-1.54,8.32-0.34,12.22c1.21,3.89,3.96,4.7,7.59,4.83c3.62,0.13,8.86-1.54,11.48-4.03
        c2.62-2.48,6.11-2.75,7.72-2.89s5.75-0.99,7.08-2.71c1.33-1.72,1.94-2.1,2.56-2.46c0.62-0.36,1.91-1.49,0.74-3.24
        s-2.88-1.75-3.79-1.75c-0.91,0-4.86-0.36-6.83-1.78c-1.97-1.42-7.8-5.41-9.58-5.76"
        />
        <path
          style={styling6}
          d="M206.73,281.4c0,0,5.75,10.42,6.71,12.31s6.87,12.46,8.71,12.92c1.84,0.46,2.68-0.95,1.48-3.5
        s-5.84-12.71-6.08-15.95c0,0,3.73,6.6,5.64,9.89c1.91,3.28,5.09,9.21,7.37,7.61c1.41-0.99-0.42-4.14-1.15-5.63
        s-6.64-13.35-6.29-15.66c0,0,1.37,6.38,5.24,11.47c2.67,3.52,4.32,5.97,5.61,4.04c1.02-1.54-1.77-5.11-3-7.82
        s-5.09-9.55-4.48-10.26s4.11,1.94,5.17,2.82s5.32,4.86,6.97,3.12s-1.77-3.65-3.5-5.42c-1.31-1.34-6.44-7.5-7.29-9.05
        s-3.18-6.21-5.86-8.69c-2.68-2.48-4.91-5.47-6.31-8.16c-0.25-0.48-0.78-1.42-1.32-2.48c-3.2-5.43-6.53-13.57-8.19-19.32
        c-1.84-6.36-8.39-23.28-10.29-27.28s-7.08-13.77-7.74-16s-0.92-8-1.31-13.83s-1.9-26.16-2.23-31.14s-0.39-12.79-1.77-21.83
        c-1.38-9.05-6.1-14.69-8.75-17.87c-3.14-3.4-6.98-5.23-11.46-6.12c-4.49-0.9-8.81-2.54-12.81-4.76c-6.89-3.82-7.81-6.19-8.37-9.33
        c-0.74-4.13-0.68-14.55-0.68-14.55s0-0.02,0-0.04c6.27-6.47,9.13-15.45,9.13-15.45s5.21,0.37,6.68-9.15s-5.03-7.78-5.03-7.78
        c0-6.31,1.46-18.39-4.57-25.61c-5.29-6.34-11.73-8.94-19.87-8.94s-14.99,2.6-20.29,8.94c-6.04,7.23-4.57,19.3-4.57,25.61
        c0,0-6.49-1.74-5.03,7.78c1.46,9.51,6.68,9.15,6.68,9.15s2.71,8.3,8.6,14.7c0,0.01,0.06,10.43-0.68,14.56
        c-0.5,2.81-1.62,5.2-6.91,8.48c-4.85,3.02-10.29,4.94-15.91,6.02c-4.59,0.88-8.52,2.71-11.71,6.17c-2.65,3.18-7.37,8.82-8.75,17.87
        s-1.44,16.85-1.77,21.83c-0.33,4.98-1.84,25.31-2.23,31.14s-0.66,11.61-1.31,13.83s-5.84,12-7.74,16s-8.46,20.92-10.29,27.28
        c-1.32,4.56-3.66,10.27-6.16,16.28c-1.11,2.36-2.07,4.6-2.5,5.58c-1.22,2.77-3.67,5.48-6.33,7.97s-4.96,7.16-5.82,8.71
        c-0.85,1.55-6.02,7.86-7.27,9.25c-1.26,1.39-5.11,3.54-3.45,5.27s5.9-2.26,6.95-3.15c1.06-0.89,4.54-3.55,5.16-2.85
        s-3.21,7.57-4.43,10.28s-4,6.3-2.97,7.83c1.3,1.92,2.94-0.54,5.59-4.06c3.84-5.11,5.18-11.49,5.18-11.49
        c0.36,2.3-5.49,14.19-6.22,15.69c-0.72,1.49-2.54,4.66-1.13,5.64c2.29,1.59,5.44-4.35,7.33-7.64s5.59-9.91,5.59-9.91
        c-0.23,3.23-4.81,13.42-6,15.97s-0.34,3.96,1.5,3.49c1.83-0.46,7.69-11.06,8.64-12.96s6.65-12.34,6.65-12.34"
        />
        <path style={styling6} d="M183.63,188.43c0,0.15-0.91,2.38-1.83,3.24" />
        <path style={styling6} d="M173.54,191.72c0,0,2.79,2.59,5.63,3.8" />
        <path style={styling7} d="M87.9,284.65c0,0,9.89,4.4,20.71,2.38s16.31-6.05,16.95-10.81v-28.49" />
        <path style={styling7} d="M163.24,284.39c0,0-9.89,4.4-20.71,2.38c-10.81-2.02-16.31-6.05-16.95-10.81" />
        <line style={styling6} x1="125.57" y1="194.79" x2="125.57" y2="208" />
        <path style={styling6} d="M139.3,123.59c0,0-4.04,11.96-4.04,17.63s2.64,11.19,2.64,11.19" />
        <path style={styling6} d="M112.52,123.59c0,0,4.04,11.96,4.04,17.63s-2.64,11.19-2.64,11.19" />
        <path style={styling6} d="M218.14,271.05c0,0,3.22,2.02,4.83,2.28" />
        <path style={styling6} d="M216.32,288.61c0,0,1.38-2.41,1.98-3.04" />
      </g>
    </svg>
  )
}
