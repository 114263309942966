import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { OptionItem } from '../../models/api/response/OptionItem'
import {
  DEFAULT_SCALE_VALUE,
  DEFAULT_TEMPERATURE,
  SCALE_STEP_SIZE,
  TEMPERATURE_STEP_SIZE,
  SLIDER_MIN,
  SLIDER_MAX,
} from '../../constants'
import { useQuestionContext } from '../../context/QuestionContext'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { TemperatureSlider } from './TemperatureSlider/TemperatureSlider'
import { ScaleSlider } from './ScaleSlider/ScaleSlider'
import styles from './SliderInput.module.less'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.isLoading The boolean that decides whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The SliderInput component
 */
export const SliderInput: FC<{
  question: Question
  animationStartsWhen: boolean
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, animationStartsWhen, isLoading, sendAnswer }) => {
  const { setNextButtonLogic } = useQuestionContext()

  /**
   * @returns The default value for the slider input
   */
  const determineDefaultValue = useCallback(() => {
    if (question.detailedType === 'TEMPERATURE') {
      return DEFAULT_TEMPERATURE
    } else {
      return DEFAULT_SCALE_VALUE
    }
  }, [question.detailedType])

  /**
   * @returns The default step size for the slider input
   */
  const stepSize = useMemo(() => {
    if (question.detailedType === 'TEMPERATURE') {
      return TEMPERATURE_STEP_SIZE
    } else {
      return SCALE_STEP_SIZE
    }
  }, [question.detailedType])

  const [value, setValue] = useState(() => determineDefaultValue())

  const minimumValue = useMemo(() => {
    return (
      question.options?.values.reduce((previous, current) =>
        (current.from ?? SLIDER_MIN) < (previous.from ?? SLIDER_MIN) ? current : previous,
      ).from ?? SLIDER_MIN
    )
  }, [question.options?.values])

  const maximumValue = useMemo(() => {
    return (
      question.options?.values.reduce((previous, current) =>
        (current.to ?? SLIDER_MAX) > (previous.to ?? SLIDER_MAX) ? current : previous,
      ).to ?? SLIDER_MAX
    )
  }, [question.options?.values])

  /**
   * @returns The value for the answer id
   */
  const determineComponentToUse = useCallback(() => {
    if (question.detailedType === 'TEMPERATURE') {
      return (
        <TemperatureSlider
          value={+value}
          values={question.options?.values as OptionItem[]}
          setValue={setValue}
          minValue={minimumValue}
          maxValue={maximumValue}
        />
      )
    } else {
      return (
        <ScaleSlider
          value={+value}
          values={question.options?.values as OptionItem[]}
          setValue={setValue}
          minValue={minimumValue}
          maxValue={maximumValue}
        />
      )
    }
  }, [question.detailedType, question.options?.values, value, minimumValue, maximumValue])

  /**
   * @returns The value for the answer id
   */
  const determineAnswerId = useCallback(() => {
    if (+value <= minimumValue) {
      return question.options?.values.find((option) => option.from === minimumValue)?.id
    }
    if (+value >= maximumValue) {
      return question.options?.values.find((option) => option.to === maximumValue)?.id
    }

    return question.options?.values.find(
      (option) => option.from !== undefined && option.to !== undefined && option.from <= +value && option.to > +value,
    )?.id
  }, [question.options, minimumValue, maximumValue, value])

  useEffect(() => {
    setNextButtonLogic(() => () => {
      sendAnswer({
        questionId: question.id,
        answer: {
          id: determineAnswerId(),
        },
      })
    })
  }, [determineAnswerId, question.id, sendAnswer, setNextButtonLogic])

  /**
   * Increases the value of the slider by the determined step size if the new value is within the valid range
   */
  const increase = () => {
    const newValue = Math.round((value + stepSize) * 10) / 10
    if (newValue <= maximumValue) {
      setValue(newValue)
    }
  }

  /**
   * Decreases the value of the slider by the determined step size if the new value is within the valid range
   */
  const decrease = () => {
    const newValue = Math.round((value - stepSize) * 10) / 10
    if (newValue >= minimumValue) {
      setValue(newValue)
    }
  }

  return (
    <WrapperWithTitleAndAnimation title={question.text} isAnimated animationStartsWhen={animationStartsWhen}>
      <div className={styles.buttonContainer}>
        <button style={{ color: CSS_VARS.questionFontColor }} className={styles.finetune} onClick={decrease}>
          <MinusOutlined />
        </button>
        <div className={styles.container}>{determineComponentToUse()}</div>
        <button style={{ color: CSS_VARS.questionFontColor }} className={styles.finetune} onClick={increase}>
          <PlusOutlined />
        </button>
      </div>
    </WrapperWithTitleAndAnimation>
  )
}
