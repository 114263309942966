import React, { FC, useCallback, useState } from 'react'
import { ChatbotQuestionAnswer } from '../../../models'
import {
  baseStyle,
  hover,
  lateralAbdomenLeftId,
  lateralAbdomenRightId,
  lowerCentralAbdomenId,
  lowerLeftAbdomenId,
  lowerRightAbdomenId,
  navelId,
  upperCentralAbdomenId,
  upperLeftAbdomenId,
  upperRightAbdomenId,
} from '../abdominalZoomResources'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.questionId The id of the current question
 * @param props.sendAnswer The method that sends the answer
 * @returns The FemaleAbdominalZoomSVG component
 */
export const FemaleAbdominalZoomSVG: FC<{ questionId: string; sendAnswer: (val: ChatbotQuestionAnswer) => void }> = ({
  questionId,
  sendAnswer,
}) => {
  const [isHovered, setIsHovered] = useState('')

  /**
   * @param value The answer id to send
   */
  const onClick = useCallback(
    (value: string) => {
      setIsHovered(value)
      sendAnswer({ questionId, answer: { id: value } })
    },
    [questionId, setIsHovered, sendAnswer],
  )

  /**
   * @param id The id to use
   * @returns The appropriate styling
   */
  const applyStyling = useCallback((id: string) => (isHovered === id ? hover : baseStyle), [isHovered])

  return (
    <div className="abdominal">
      <svg width="365px" height="215px" viewBox="0 0 380 260" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="iPhone-XS-Copy-20-(1)" transform="translate(16.000000, -260.000000)">
            <g id="Front-Female">
              <g id="Group" transform="translate(0.935829, 0.804196)">
                <rect
                  id="Rectangle"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                  x="169.4"
                  y="426.8"
                  width="9.4"
                  height="1.9"
                ></rect>
                <path
                  d="M53.2,370.9 L51.5,370.8 C51.5,371.8 51.1,380.3 54.5,382 L55.2,380.2 C53.5,379.3 53.1,374 53.2,370.9 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M294.9,370.9 C295,374 294.6,379.3 292.9,380.2 L293.6,382 C297,380.3 296.7,371.8 296.6,370.8 L294.9,370.9 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path d="M336.1,519.2 L332.2,519.2" id="Path" fill="#CCCCCC" fillRule="nonzero"></path>
                <path d="M311.4,519.2 L307.3,519.2" id="Path" fill="#CCCCCC" fillRule="nonzero"></path>
                <path d="M42.7,519.2 L38.6,519.2" id="Path" fill="#CCCCCC" fillRule="nonzero"></path>
                <path d="M17.8,519.2 L13.9,519.2" id="Path" fill="#CCCCCC" fillRule="nonzero"></path>
                <path d="M282.9,519.2 L279,519.2" id="Path" fill="#CCCCCC" fillRule="nonzero"></path>
                <path d="M71,519.2 L67.1,519.2" id="Path" fill="#CCCCCC" fillRule="nonzero"></path>
                <path
                  d="M252,306.9 C252,307.1 251.9,307.4 251.9,307.6 C253,306.6 254.1,305.7 255.2,304.9 C255.2,304.8 255.2,304.7 255.2,304.6 C252,307 255.2,304.5 252,306.9 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M94.7,306.1 C95.6,306.6 96.5,307.1 97.3,307.5 C95.2,305.9 95.9,306.3 94.7,305.6 C94.8,305.8 94.8,306 94.7,306.1 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M53.5,371.1 L53.7,370.7 L53.7,370.2 C53.7,369.6 50.5,312.1 52.8,267 C53,264 53.2,261 53.4,258.2 L49.5,258.2 C49.3,261 49.1,263.8 48.9,266.8 C46.7,309.9 49.5,364.3 49.8,370 C34.8,402.2 18.1,484.9 13.9,519.3 L17.8,519.3 C21.9,485 38.5,402.7 53.5,371.1 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M293.1,470.3 C287.9,456.9 283.4,445.3 281.9,440.4 C280.4,435.3 277.9,429.5 275.2,423.4 C269.5,410.2 263,395.2 261.4,377.7 C259.1,351.7 259.1,303.1 259.1,302 C257.8,302.9 256.5,303.9 255.2,304.9 C255.7,333.9 256.5,365.3 257.6,378 C259.2,396.2 266.1,412.1 271.7,424.9 C274.3,430.9 276.8,436.6 278.2,441.5 C279.7,446.5 284.2,458.2 289.5,471.7 C294.8,485.4 302,503.9 307.3,519.3 L311.4,519.3 C306.9,506 300.4,489.1 293.1,470.3 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M301.1,266.8 C300.9,263.8 300.7,261 300.5,258.2 L296.6,258.2 C296.9,261 297.1,263.9 297.2,267 C299.5,312.1 296.3,369.7 296.3,370.2 L296.3,370.7 L296.5,371.1 C311.4,402.7 328.1,485 332.2,519.2 L336.1,519.2 C332,484.9 315.3,402.2 300.2,369.9 C300.5,364.2 303.3,309.9 301.1,266.8 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M244.3,385.6 C245.4,358.9 248.7,332.8 251.9,307.6 C249.9,309.3 248,311.1 246,313.1 C242.8,338.6 241.6,358.4 240.5,385.5 C239.4,411.9 249,434.9 259.1,459.2 C266.9,477.8 274.8,496.9 279,519.2 L282.9,519.2 C278.8,496.2 270.6,476.7 262.7,457.7 C252.7,433.8 243.3,411.2 244.3,385.6 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M90.9,459.2 C101.1,434.9 110.6,412 109.5,385.5 C108.4,360.1 105.4,335.3 102.4,311.2 C102.3,310.3 100.1,309.1 97.3,307.6 C97.6,307.8 97.8,308 98.2,308.3 C101.4,333.8 104.5,358.8 105.6,385.7 C106.7,411.3 97.2,433.8 87.3,457.7 C79.4,476.7 71.2,496.2 67.1,519.2 L71,519.2 C75.1,496.9 83.1,477.7 90.9,459.2 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M71.7,441.5 C73.2,436.6 75.6,430.9 78.2,424.9 C83.8,412.1 90.7,396.1 92.3,377.9 C93.4,365.4 94.2,334.7 94.7,306.1 C93.4,305.4 92.1,304.7 90.9,303.9 C90.4,333.1 89.6,364.9 88.5,377.6 C86.9,395.2 80.4,410.1 74.7,423.4 C72,429.5 69.5,435.3 68,440.4 C66.5,445.2 62.1,456.8 56.9,470.2 C49.6,489.1 43.1,506 38.5,519.2 L42.6,519.2 C47.9,503.9 55.2,485.3 60.5,471.6 C65.7,458.1 70.2,446.5 71.7,441.5 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <polygon
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                  points="109.5 284 112 286.7 113.2 285.7 110.8 283"
                ></polygon>
                <path
                  d="M86.4,263.6 C82.2,271.8 78.6,281.1 84.3,295.5 C92.9,317.2 121.2,318.5 127.8,318.5 C128.5,318.5 129,318.5 129.1,318.5 L129,315.6 C128.7,315.6 95.8,316.7 87,294.4 C81.7,281.1 85.1,272.5 89,264.9 C89.6,263.6 90.4,262.4 91,261.2 C91.6,260.2 92.2,259.2 92.7,258.2 L89.5,258.2 C89.2,258.7 88.9,259.2 88.6,259.8 C87.8,261 87.1,262.2 86.4,263.6 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
                <g id="Fill-32_00000172421039507630736750000004256090613132323509_"></g>
                <path
                  d="M219,316.6 C219,316.6 252.9,318 262.1,294.3 C267.5,280.4 264.1,271.4 260.1,263.3"
                  id="Fill-32_00000170960621236194700320000012277361920042139801_"
                ></path>
                <polygon
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                  points="234 285.7 235.2 286.7 237.7 284 236.4 283"
                ></polygon>
                <path
                  d="M258.2,264.9 C262.1,272.6 265.4,281.1 260.2,294.4 C251.4,316.6 218.5,315.6 218.2,315.6 L218.1,318.5 C218.2,318.5 218.7,318.5 219.4,318.5 C226,318.5 254.3,317.2 262.9,295.5 C268.6,281.1 265,271.8 260.8,263.6 C260.1,262.3 259.4,261 258.7,259.8 C258.4,259.3 258.1,258.7 257.8,258.2 L254.6,258.2 C255.1,259.2 255.7,260.2 256.3,261.2 C256.9,262.4 257.6,263.6 258.2,264.9 Z"
                  id="Path"
                  fill={CSS_VARS.abdominalZoomSVGBodyOutlineColor}
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
            <g
              id="Group-5"
              transform="translate(96.000000, 323.000000)"
              fill={CSS_VARS.abdominalZoomSVGColor}
              fillOpacity="0.5"
              fillRule="nonzero"
              stroke={CSS_VARS.chatBackgroundColor}
              strokeOpacity="0.61"
            >
              <path
                d="M107.5,83.2 L51.9,83.2 C47.6,83.2 44.1,86.6 43.9,90.9 L43,113.3 C42.8,117.6 46.1,121.3 50.4,121.6 C64.7,122.7 74.3,123.2 79.2,123.2 C84.1,123.2 93.8,122.6 108.2,121.5 C112.4,121.2 115.6,117.7 115.6,113.5 L115.6,91.2 C115.5,86.8 111.9,83.2 107.5,83.2 Z"
                id={navelId}
                onMouseEnter={() => setIsHovered(navelId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick(navelId)}
                style={applyStyling(navelId)}
              ></path>
              <path
                d="M90.8,126.9 L67.6,126.9 C63.2,126.9 59.7,130.4 59.6,134.8 L58.9,172.3 C58.9,174.8 59.9,177.1 61.8,178.6 C70.6,185.8 76.4,189.4 79.2,189.4 C82,189.4 87.6,185.8 96,178.6 C97.8,177.1 98.8,174.9 98.8,172.5 L98.8,134.9 C98.8,130.5 95.3,126.9 90.8,126.9 Z"
                id={lowerCentralAbdomenId}
                onMouseEnter={() => setIsHovered(lowerCentralAbdomenId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick(lowerCentralAbdomenId)}
                style={applyStyling(lowerCentralAbdomenId)}
              ></path>
              <path
                d="M141.6,122.4 C135.8,123.7 131.4,125.8 124.8,125.8 C118.7,125.8 113.7,125.3 109.8,126.9 C101.3,130.5 102.4,138.1 102.4,144.7 C102.4,151.9 100.1,173.5 107.6,176.7 C113.6,179.3 123.2,180.1 131,178.4 C148.4,174.6 159.4,150 157.4,135.9 C155.3,121.8 159,118.6 141.6,122.4 Z"
                id={lowerLeftAbdomenId}
                onMouseEnter={() => setIsHovered(lowerLeftAbdomenId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick(lowerLeftAbdomenId)}
                style={applyStyling(lowerLeftAbdomenId)}
              ></path>
              <path
                d="M32.3,57.8 C19.1,57.8 18.4,57.8 19,68.9 C19.2,71.6 17.2,84.3 14.1,95.7 C11.4,105.4 7.3,114.3 9.7,115.7 C13.9,118.2 20.9,120 26.8,120 C29.4,120 34.4,121.8 36.6,118.9 C39.5,115 39.2,105.4 39.2,97.8 C39.2,83.6 40.7,75.3 40.7,70.4 C40.8,61.2 45.5,57.8 32.3,57.8 Z"
                id={lateralAbdomenRightId}
                onMouseEnter={() => setIsHovered(lateralAbdomenRightId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick('bauchSeitlich')}
                style={applyStyling(lateralAbdomenRightId)}
              ></path>
              <path
                d="M140,68.9 C140.6,57.8 140,57.8 126.7,57.8 C113.4,57.8 118.2,61.2 118.2,70.4 C118.2,75.3 119.7,83.6 119.7,97.8 C119.7,105.4 119.4,115 122.3,118.9 C124.4,121.8 129.4,120 132.1,120 C138,120 145,118.1 149.2,115.7 C151.6,114.3 147.4,105.5 144.8,95.7 C141.8,84.3 139.8,71.6 140,68.9 Z"
                id={lateralAbdomenLeftId}
                onMouseEnter={() => setIsHovered(lateralAbdomenLeftId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick('bauchSeitlich')}
                style={applyStyling(lateralAbdomenLeftId)}
              ></path>
              <path
                d="M48.8,126.9 C44.9,125.3 39.9,125.8 33.8,125.8 C27.2,125.8 22.8,123.7 17,122.4 C-0.4,118.6 3.2,121.8 1.2,135.9 C-0.8,150 10.2,174.6 27.6,178.4 C35.4,180.1 45,179.3 51,176.7 C58.5,173.5 56.2,151.9 56.2,144.7 C56.2,138.1 57.4,130.5 48.8,126.9 Z"
                id={lowerRightAbdomenId}
                onMouseEnter={() => setIsHovered(lowerRightAbdomenId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick(lowerRightAbdomenId)}
                style={applyStyling(lowerRightAbdomenId)}
              ></path>
              <path
                d="M99.7,48.1 C97.7,42.3 96.9,32.8 97.1,19.4 C97.1,17.7 96.6,16.1 95.5,14.8 C87.3,4.9 81.8,0 79.1,0 C76.4,0 70.7,4.9 62.1,14.7 C60.9,16 60.3,17.8 60.4,19.6 C60.9,32.8 60.1,42.3 58,48.1 C56.4,52.6 52.5,59.2 46.3,67.9 C44.1,71 44.8,75.4 47.9,77.7 C49.1,78.6 50.5,79 51.9,79 L105.1,79 C108.9,79 112,75.9 112,72 C112,70.6 111.6,69.2 110.8,68.1 C104.9,59.2 101.2,52.6 99.7,48.1 Z"
                id={upperCentralAbdomenId}
                onMouseEnter={() => setIsHovered(upperCentralAbdomenId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick(upperCentralAbdomenId)}
                style={applyStyling(upperCentralAbdomenId)}
              ></path>
              <path
                d="M123.8,8.7 C118.2,7.3 111.3,8 107,10.1 C101.6,12.7 103.3,30.1 103.3,35.9 C103.3,41.2 102.5,47.3 108.6,50.2 C111.4,51.5 115,51.1 119.4,51.1 C124.1,51.1 127.3,52.8 131.5,53.8 C144,56.9 141.4,54.3 142.8,42.9 C144.3,31.6 136.3,11.8 123.8,8.7 Z"
                id={upperLeftAbdomenId}
                onMouseEnter={() => setIsHovered(upperLeftAbdomenId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick(upperLeftAbdomenId)}
                style={applyStyling(upperLeftAbdomenId)}
              ></path>
              <path
                d="M51,10.1 C46.7,8 39.8,7.3 34.2,8.7 C21.7,11.8 13.7,31.6 15.2,42.9 C16.7,54.2 14,56.9 26.5,53.8 C30.7,52.8 33.9,51.1 38.6,51.1 C43,51.1 46.6,51.5 49.4,50.2 C55.6,47.3 54.7,41.2 54.7,35.9 C54.8,30 56.4,12.6 51,10.1 Z"
                id={upperRightAbdomenId}
                onMouseEnter={() => setIsHovered(upperRightAbdomenId)}
                onMouseLeave={() => setIsHovered('')}
                onClick={() => onClick(upperRightAbdomenId)}
                style={applyStyling(upperRightAbdomenId)}
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
