import React, { CSSProperties, FC, useMemo } from 'react'
import { backIds } from '../bodyPartIds'
import { maleStyles } from '../styles'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

const { occiputId, backId, upperArmLeftId, upperArmRightId, lowerArmLeftId, lowerArmRightId, lowerBackId, legsId } =
  backIds

/**
 * @param props The props object
 * @param props.handleClick The click handler method
 * @param props.applyStyling The method which applies appropriate stylings for components
 * @param props.setIsHovered The setter for whether a component is hovered
 * @returns The MaleBodyBackSVG component
 */
export const MaleBodyBackSVG: FC<{
  handleClick: (eventTarget: EventTarget, bodyPartId: string) => void
  applyStyling: (id: string, styling: CSSProperties) => CSSProperties
  setIsHovered: (val: string) => void
}> = ({ handleClick, applyStyling, setIsHovered }) => {
  const maleStylings = useMemo(
    () => maleStyles(CSS_VARS.bodyPartSelectorSVGBodyOutlineAndHoverColor, CSS_VARS.bodyPartSelectorSVGColor),
    [],
  )

  const { styling1, styling2, styling3 } = maleStylings

  return (
    <svg
      data-testid="maleBackSVG"
      className="bodySvg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 253.84 541.61"
    >
      <g
        id={legsId}
        onClick={(e) => handleClick(e.target, legsId)}
        onMouseEnter={() => setIsHovered(legsId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.71,276.27c0.05,0.85,0.09,1.71,0.12,2.59C178.8,277.98,178.76,277.11,178.71,276.27z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.85,279.26c0.03,0.84,0.06,1.69,0.09,2.54C178.92,280.95,178.89,280.1,178.85,279.26z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.95,282.29c0.02,0.87,0.04,1.74,0.06,2.62C179,284.03,178.98,283.16,178.95,282.29z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.07,291.23c0,0.9,0,1.81-0.01,2.7C179.07,293.04,179.07,292.14,179.07,291.23z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.06,288.07c0.01,0.93,0.01,1.87,0.01,2.8C179.07,289.94,179.06,289,179.06,288.07z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.02,285.26c0.02,0.9,0.03,1.8,0.04,2.71C179.05,287.06,179.04,286.16,179.02,285.26z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.27,287.98c0.01-0.93,0.02-1.86,0.04-2.79C75.29,286.12,75.28,287.05,75.27,287.98z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.4,519.1c-0.68-0.75-1.17-0.98-2.6-3.03s-2.36-1.45-4.13-2.89c-1.77-1.43-3.61-1.09-4.6-2.35
          c-1.45-1.86-2.74-3.35-2.85-6.56c-0.18-5.27-1.23-9.01-1.3-12.97s1.64-11.25,2.8-16.09c1.16-4.84,3.82-16.36,5.45-22.91
          c1.64-6.55,2.86-13.3,2.93-19.7s-2.32-16.91-2.93-20.18c-0.61-3.27-3-16.23-3.07-24.95s0.89-12.34,1.77-19.77
          c0.89-7.43,3.48-19.23,4.5-25.77c1.02-6.55,2.66-18.14,2.86-21.55c0.12-1.93,0.56-10.33,0.75-20.37c0,0-16.01,3.25-23.42,3.25
          c-7.41,0-25.11-3.25-25.11-3.25c0-0.03-0.01-0.06-0.01-0.09c0.95,9,2.25,18.34,3.68,28.64c1.43,10.3,1.5,25.77,1.57,27.61
          c0.07,1.84-0.41,16.57-0.55,22.43c-0.14,5.86,0.82,11.18,1.5,13.57s4.57,11.25,5.52,13.02c0.95,1.77,1.64,4.36,1.64,5.59
          c0,1.23-0.68,4.3-1.02,7.57c-0.34,3.27,0,15.07,0.27,17.52c0.27,2.45,2.18,10.64,2.8,13.57c0.61,2.93,2.73,12.89,3.55,18.34
          s-0.34,12.82-0.75,15.2c-0.41,2.39-0.41,6.07-0.48,7.77c-0.07,1.7-0.61,9.75-0.95,12.55c-0.34,2.8-0.63,11.6-0.33,13.58
          c0.21,1.35,1.13,3.01,1.34,5.28c0.18,1.92-0.75,5.73-0.89,7.98s1.92,3.14,4.2,4.06c2.73,1.11,3.89,0.6,6.07,1.01
          s8.11-1.54,8.11-1.54c2.85-0.29,4.85-5.38,4.85-5.38c2.6-1,3.35-1.89,3.35-1.89c3.99-1.46,5.06-2.92,5.06-2.92
          c1.32-0.86,1.25-0.59,1.14-2.32C180.01,519.54,180.08,519.85,179.4,519.1z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.03,297.06c0.01-0.9,0.02-1.81,0.03-2.73C179.05,295.24,179.04,296.15,179.03,297.06z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.98,300c0.02-0.93,0.03-1.87,0.05-2.81C179.02,298.14,179,299.08,178.98,300z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M76.1,320.26c0.2,3.41,1.84,15,2.86,21.55c1.02,6.55,3.61,18.34,4.5,25.77c0.89,7.43,1.84,11.05,1.77,19.77
          s-2.45,21.68-3.07,24.95c-0.61,3.27-3,13.77-2.93,20.18s1.3,13.16,2.93,19.7c1.64,6.55,4.3,18.07,5.45,22.91
          c1.16,4.84,2.86,12.14,2.8,16.09s-0.94,7.24-1.04,10.45c-0.14,4.69-1.46,6.1-1.8,7.67c-0.34,1.57-5.38,2.72-7.16,4.15
          s-3.55,2.71-4.98,4.75c-1.43,2.05-0.92,1.69-1,2.8c-0.08,1.01,3.03,4.12,5.21,4.55c0,0,2.98,1.23,5.24,3.52
          c0,0,2.83,4.44,3.89,4.24c0,0,4.14,1.84,6.33,1.43c2.18-0.41,8.37,0.19,10.28-2.06c1.91-2.25,1.99-3.17,1.86-5.42
          s-0.91-4.79-0.63-6.48c0.4-2.34,0.89-3.33,1-5.21c0.13-2.16-0.15-9.59-0.49-12.39c-0.34-2.8-0.89-10.84-0.95-12.55
          c-0.07-1.7-0.07-5.39-0.48-7.77c-0.41-2.39-1.57-9.75-0.75-15.2c0.82-5.45,2.93-15.41,3.55-18.34c0.61-2.93,2.52-11.11,2.8-13.57
          s0.61-14.25,0.27-17.52c-0.34-3.27-1.02-6.34-1.02-7.57c0-1.23,0.68-3.82,1.64-5.59c0.95-1.77,4.84-10.64,5.52-13.02
          s1.64-7.7,1.5-13.57c-0.14-5.86-0.61-20.59-0.55-22.43c0.07-1.84,0.14-17.32,1.57-27.61c1.42-10.21,2.71-19.49,3.66-28.42
          c0,0-11.97,3.72-24.42,3.72c-12.45,0-24.1-3.72-24.1-3.72L76.1,320.26z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.26,290.82c0-0.95,0-1.89,0.01-2.84C75.27,288.93,75.26,289.88,75.26,290.82z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.39,281.71c0.03-0.86,0.05-1.72,0.09-2.56C75.44,279.99,75.42,280.84,75.39,281.71z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.5,278.76c0.04-0.88,0.08-1.76,0.13-2.61C75.57,277,75.53,277.87,75.5,278.76z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.32,284.82c0.02-0.88,0.04-1.76,0.06-2.63C75.35,283.06,75.33,283.94,75.32,284.82z"
        />
      </g>
      <g
        id={lowerBackId}
        onClick={(e) => handleClick(e.target, lowerBackId)}
        onMouseEnter={() => setIsHovered(lowerBackId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerBackId, styling2)}
          d="M123.87,300.01c0.01-0.07,0.02-0.14,0.02-0.21c0.95-9,1.16-10.86,1.16-10.86s2.04-14.94,2.06-15.1l0.81-0.05
        c0,0.04,1.33,14.81,1.33,14.81s0.24,2.32,1.19,11.32c0,0.03,0.01,0.06,0.01,0.09c0,0,17.71,3.25,25.11,3.25
        c7.41,0,23.42-3.25,23.42-3.25c0.15-7.68,0.14-16.32-0.27-23.75c-0.66-11.77-3.18-23.56-4.69-29.78c-0.8-3.27-1.3-6.6-1.48-9.96
        c0.04,0.8,0.11,1.6,0.19,2.39c0.12,1.2-22.7,5.6-45.54,5.6c-22.83,0-45.68-4.39-45.58-5.6c-0.26,2.51-0.69,5-1.28,7.45
        c-1.51,6.22-4.04,18.02-4.69,29.78c-0.42,7.46-0.42,16.16-0.27,23.87c0,0,11.65,3.72,24.1,3.72S123.87,300.01,123.87,300.01z"
        />
      </g>
      <g
        id={lowerArmLeftId}
        onClick={(e) => handleClick(e.target, lowerArmLeftId)}
        onMouseEnter={() => setIsHovered(lowerArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmLeftId, styling2)}
          d="M50.47,221.58c-5.9-1.57-11.41-5.53-11.41-5.53c-0.22,2.8-0.37,5.26-0.53,6.97
        c-0.33,3.42-4.14,25.92-4.14,25.92s-3.01,8.88-4.63,9.42c-1.62,0.54-5.93,18.65-6.23,20.16c-1.09,5.53-4.9,7.45-6.07,10.94
        c-1.18,3.48-1.71,3.62-0.54,4.44c0.97,0.68,1.79,0.14,3.81-2.22c3.07-3.58,4.38-5.83,5.37-6.46c1-0.63,0.95-0.56,0.54,0.66
        s-3.93,9.61-6.27,14.05c-1.95,3.7-1.41,4.8,1.32,5.22c2.21,0.33,5.13-6.15,5.92-7.94c2.65-6.03,4.37-9.59,4.55-9.91
        c0.18-0.32-3.17,11.22-5.25,16.87c-1.28,3.49-1.75,6.19,0.52,6.19s3.48-3.49,4.7-6.13c1.79-3.87,4.7-14.25,5.06-14.61
        s0.11,0.26,0.16,1.71s-3.09,14.41-3,15.45c0.09,1.04-0.1,4.16,1.44,3.35c1.54-0.81,1.44-0.51,2.14-1.44
        c0.13-0.17,1.83-4.53,2.37-6.66s4.61-14.39,5.33-16.11c0.37-0.87,3.89-6.42,5.79-7.5c1.28-0.72,1.06,12.71,7.35,12.78
        c0.98,0.01-0.23-7.49-0.11-8.32c0.43-3-0.94-12.13-1.31-13.99s-9.27-11.05-8.73-12.49c0.54-1.45,1.51-5.7,3.03-8.87
        c1.52-3.17,7.45-15.44,9.38-19.1c0.73-1.39,1.76-3.3,2.79-5.32C63.83,223.1,56.37,223.15,50.47,221.58z"
        />
      </g>
      <g
        id={upperArmLeftId}
        onClick={(e) => handleClick(e.target, upperArmLeftId)}
        onMouseEnter={() => setIsHovered(upperArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(upperArmLeftId, styling2)}
          d="M63.83,223.1c1.67-3.3,3.32-6.87,3.62-8.88c0.48-3.24,0.76-14.34,0.76-16.34c0-2-0.07-4.07,0.83-6.34
        c0.9-2.28,3.72-11.31,4.27-13.72c0.54-2.36,2.92-11.5,2.76-17c-0.59-2.81-2.02-11.06-1.07-20.86c1.82-18.71,0.88-33.91,0.06-41.84
        c0,0,0.01,0,0.01,0c-3.63,1.17-15.12,5.34-18.95,10.01c-4,4.88-4.78,16.69-3.61,27.71c0.59,5.58,1.36,10.61,1.97,14.22
        c-0.06,0.14-0.11,0.28-0.11,0.28s-5.34,13.77-4.84,34.05c0,0-7.96,12.39-9.51,22.7c-0.46,3.05-0.75,6.18-0.97,8.97
        c0,0,5.51,3.96,11.41,5.53S63.83,223.1,63.83,223.1z"
        />
      </g>
      <g
        id={lowerArmRightId}
        onClick={(e) => handleClick(e.target, lowerArmRightId)}
        onMouseEnter={() => setIsHovered(lowerArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmRightId, styling2)}
          d="M203.84,221.63c5.9-1.57,11.41-5.53,11.41-5.53c0.22,2.8,0.32,5.26,0.53,6.97
        c1.92,15.55,5.31,31.19,5.31,31.19s3.48,2.82,5.18,4.48c1.22,1.19,3,5.42,3.64,7.19c1.37,3.78,4.84,13.78,5.2,15.27
        c0.36,1.49,2.6,2.98,5.15,6.67c2.09,3.02,2.93,4.4,2.27,5.34c-1.61,2.3-4.19-1.06-6.34-3.31c-2.98-3.12-3.73-3.95-4.73-4.59
        c-1-0.63,1.06,2.61,1.47,3.83s2.03,4.02,3.64,6.9c2.04,3.65,4.53,8.76,1.8,9.17c-2.21,0.33-5.43-5.01-6.29-6.76
        c-2.7-5.49-5.72-10-5.91-10.31c-0.18-0.32,2.74,7.29,4.16,11.78c1.12,3.55,6.05,8.37,2.84,10.73c-1.82,1.34-5.67-5.01-6.71-7.38
        c-1.17-2.67-4.99-11.75-5.36-12.11s0.25,2.04,0.72,3.74c0.39,1.4,2.03,6.01,1.94,7.05c-0.09,1.04,2.5,8.3,0.95,9.08
        c-3.97,1.99-5.49-10.88-11.37-22.26c-0.98-1.89-4.72-6.13-7.01-8.12c-0.62-0.54-2.03,11.12-3.33,11.99
        c-5.15,3.45-3.24-1.61-3.26-2.65c-0.05-2.7-0.46-4.16-0.72-4.77c-0.72-1.72,1.17-6.48,0.31-9.1c-0.86-2.62,0.75-5.95,1.87-7.47
        c1.71-2.33,6.53-8.82,5.99-10.27s-3.03-7.63-4.55-10.8c-1.52-3.17-7.45-15.44-9.38-19.1c-0.73-1.39-1.76-3.3-2.79-5.32
        C190.48,223.15,197.94,223.21,203.84,221.63z"
        />
      </g>
      <g
        id={upperArmRightId}
        onClick={(e) => handleClick(e.target, upperArmRightId)}
        onMouseEnter={() => setIsHovered(upperArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(upperArmRightId, styling2)}
          d="M190.48,223.15c-1.67-3.3-3.32-6.87-3.62-8.88c-0.48-3.24-0.76-14.34-0.76-16.34c0-2,0.07-4.07-0.83-6.34
        c-0.9-2.28-3.72-11.31-4.27-13.72c-0.54-2.36-2.92-11.5-2.76-17c0.59-2.81,2.02-11.06,1.07-20.86
        c-1.82-18.71-0.88-33.91-0.06-41.84c0,0-0.01,0-0.01,0c3.63,1.17,15.12,5.34,18.95,10.01c4,4.88,4.78,16.69,3.61,27.71
        c-0.59,5.58-1.36,10.61-1.97,14.22c0.06,0.14,0.11,0.28,0.11,0.28s5.34,13.77,4.84,34.05c0,0,7.96,12.39,9.51,22.7
        c0.46,3.05,0.75,6.18,0.97,8.97c0,0-5.51,3.96-11.41,5.53C197.94,223.21,190.48,223.15,190.48,223.15z"
        />
      </g>
      <g
        id={backId}
        onClick={(e) => handleClick(e.target, backId)}
        onMouseEnter={() => setIsHovered(backId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(backId, styling2)}
          d="M75.08,98.12C75.08,98.12,75.08,98.12,75.08,98.12C75.08,98.12,75.08,98.12,75.08,98.12
        c0.81,7.93,1.76,23.13-0.06,41.84c-0.96,9.8,0.48,18.06,1.07,20.86c0-0.12,0-0.26-0.01-0.38c0,0,0.07,0.46,0.19,1.29
        c-0.01-0.07-0.02-0.14-0.03-0.21c0.05,0.2,0.08,0.32,0.08,0.32l-0.01,0.11c0,0,0,0.01,0,0.01c1.13,7.61,6.08,41.77,6.22,53.93
        c0.06,4.99-0.4,14.48-0.73,20.5c-0.05,0.84-0.11,1.68-0.2,2.51c-0.12,1.2,22.74,5.6,45.58,5.6s45.66-4.4,45.54-5.6
        c-0.08-0.79-0.14-1.59-0.19-2.39c-0.33-6.02-0.78-15.51-0.73-20.5c0.16-13.72,6.45-55.45,6.45-55.45l0.05-0.27
        c0.59-2.81,2.02-10.54,1.07-20.34c-1.81-18.61-0.89-33.73-0.08-41.7c-0.18-0.06-0.37-0.12-0.51-0.16
        c-1.78-0.56-3.76-1.08-5.84-1.59l-42.47,1c-1.42,0.07-2.85,0.07-4.27,0L81.2,96.49l-0.04-0.05c-2,0.5-3.9,1-5.62,1.54
        c-0.29,0.09-0.68,0.22-1.11,0.36C74.66,98.26,74.88,98.18,75.08,98.12L75.08,98.12z"
        />
      </g>
      <g
        id={occiputId}
        onClick={(e) => handleClick(e.target, occiputId)}
        onMouseEnter={() => setIsHovered(occiputId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(occiputId, styling1)}
          d="M172.95,96.5l-0.02,0c0.49,0.12,1,0.24,1.47,0.37C173.93,96.75,173.45,96.62,172.95,96.5z"
        />
        <path
          style={applyStyling(occiputId, styling1)}
          d="M79.81,96.77c0.44-0.11,0.9-0.22,1.35-0.34l0,0C80.7,96.54,80.26,96.66,79.81,96.77z"
        />
        <path
          style={applyStyling(occiputId, styling1)}
          d="M147.04,78.67c-0.12-2.34-0.53-12.24-0.53-12.24l-0.53-0.1c1.71-3.47,2.95-7.08,3.29-10.18
          c0,0,4.07,0.95,7.1-7.45c3.24-8.97-1.56-12.47-4.33-9.7c0,0,1.65-12.78-0.61-19.32c-2.54-7.34-8.6-15.93-24.43-15.94
          c-15.84,0.01-21.91,8.6-24.45,15.94c-2.26,6.54-0.61,19.32-0.61,19.32c-2.77-2.77-7.57,0.73-4.33,9.7c3.03,8.4,7.1,7.45,7.1,7.45
          c0.34,3.09,1.57,6.67,3.27,10.13c0,0,0,0,0,0l-0.15,0.02c0,0-0.42,9.9-0.53,12.24c-0.12,2.34-0.84,10.46-3.07,11.37
          c-7.22,2.97-15.84,4.71-23.07,6.52l0.04,0.05l45.02,1.02c1.42,0.07,2.85,0.07,4.27,0l42.45-1c-7.17-1.79-15.69-3.52-22.83-6.46
          C147.87,89.12,147.15,81.01,147.04,78.67z M108.94,68.13c0.03,0.06,0.07,0.13,0.1,0.19C109,68.26,108.97,68.19,108.94,68.13z
           M110.03,70.03c0.06,0.09,0.11,0.19,0.17,0.28C110.14,70.22,110.08,70.13,110.03,70.03z M111.23,71.93
          c0.05,0.08,0.11,0.16,0.16,0.24C111.34,72.08,111.28,72.01,111.23,71.93z M145,68.22c-0.02,0.04-0.04,0.08-0.06,0.12
          C144.95,68.29,144.97,68.25,145,68.22z M143.93,70.07c-0.05,0.09-0.1,0.17-0.16,0.26C143.83,70.24,143.88,70.15,143.93,70.07z
           M142.74,71.94c-0.05,0.08-0.11,0.16-0.16,0.24C142.63,72.1,142.69,72.02,142.74,71.94z"
        />
      </g>
      <g id="Male_Back">
        <path
          style={styling3}
          d="M54.41,150.33c0,0-5.34,13.77-4.84,34.05c0,0-7.96,12.39-9.51,22.7c-0.92,6.09-1.17,12.52-1.5,15.94
        c-0.23,2.39-2.66,16.99-4.11,25.58c-0.38,1.4-2,6.86-3.57,8.34c-1.38,1.29-2.78,4.25-3.61,6.88c-0.84,2.63-2.14,8.55-2.56,10.37
        c-0.42,1.82-0.97,4.6-1.77,6.64c-0.8,2.04-3.78,6.03-4.92,7.84c-1.13,1.81-2.33,3.39-1.56,4.95c0.77,1.56,2.41,0.45,3.09-0.34
        c0.68-0.79,4.39-5.32,5.27-6.47c0.88-1.15,2.12-2.71,2.2-2.21c0.08,0.51-4.41,11.31-5.85,13.84c-1.43,2.54-2.28,5.11-0.44,6.44
        c1.84,1.32,3.89-2.77,5.84-5.94c1.95-3.17,5.63-12,5.59-11.64c-0.04,0.36-3.15,11.5-5.13,16.66c-0.7,1.82-2.39,5.08-0.45,6.2
        c2.54,1.47,3.73-2.16,5.55-5.93c1.82-3.76,4.36-12.17,4.74-13.4c0.38-1.23,0.13-1.96,0.57-0.86c0.44,1.1-1.77,11.13-2.54,14.14
        c-0.77,3.01-0.97,6.77,1.48,5.92c2.45-0.85,4.12-9.13,5.2-12.65c1.08-3.52,2.86-8.93,3.44-10.35c0.58-1.42,1.77-3.24,3.47-5.14
        c1.7-1.9,2.94-3.46,2.94-3.46"
        />
        <path
          style={styling3}
          d="M75.17,131.32c0.27,2.87,0.07,6.15-0.14,8.2c-0.96,11.29,1.06,20.92,1.06,20.92
        c0.28,5.45-2.21,14.96-2.76,17.37c-0.55,2.41-3.38,11.44-4.27,13.72c-0.9,2.27-0.83,4.34-0.83,6.34c0,2-0.28,13.1-0.76,16.34
        c-0.48,3.24-4.48,10.55-6.41,14.2c-1.93,3.65-7.86,15.92-9.38,19.1c-0.38,0.79-0.85,1.86-1.37,3.04c-1.37,2.8-1.54,6.26-1.54,6.26
        s1.65,2.7,2.64,4.08c1,1.38,6.11,7.06,6.32,8.93c0.21,1.87,0.55,5.32,0.54,6.65s0.41,3.12,0.69,4.21c0.27,1.08-0.14,2.73-0.33,3.31
        c-0.2,0.58-0.05,2.84,0.38,4.32c0.43,1.48,0.51,2.53-0.24,2.87c-0.75,0.35-3.4-0.67-4.42-3.18c-1.02-2.51-1.8-8.79-3.68-11.13
        c-2.43-3.02-3.98-3.48-5.31-4.32c-1.33-0.85-3.08-2.11-3.82-6.02"
        />
        <path
          style={styling3}
          d="M76.1,160.45c0,0,6.29,41.73,6.45,55.45c0.06,4.99-0.4,14.48-0.73,20.5c-0.18,3.36-0.68,6.69-1.48,9.96
        c-1.51,6.22-4.04,18.02-4.69,29.78c-0.95,17.11,0.27,40.7,0.48,44.11c0.2,3.41,1.84,15,2.86,21.55c1.02,6.55,3.61,18.34,4.5,25.77
        c0.89,7.43,1.84,11.05,1.77,19.77c-0.07,8.73-2.45,21.68-3.07,24.95c-0.61,3.27-3,13.77-2.93,20.18s1.3,13.16,2.93,19.7
        c1.64,6.55,4.3,18.07,5.45,22.91c1.16,4.84,2.86,12.14,2.8,16.09c-0.02,1.25-0.04,2.55-0.25,3.82c-0.22,1.38-1.05,4.69-0.96,7.92
        c0.12,3.95-1.95,7.69-3.91,10.19"
        />
        <path style={styling3} d="M38.73,290.45c0,0-1.9-1.63-2.36-2.92" />
        <path style={styling3} d="M33.92,287.25c0,0-2.05-0.47-2.59-0.75" />
        <path style={styling3} d="M35.16,272.54c0,0-2.55,2.36-4.2,2.81" />
        <path style={styling3} d="M54.1,186.78c0,0,0.37,2.74,1.12,3.87" />
        <path style={styling3} d="M62.58,191.08c0,0-2.62,3.87-4.24,4.61" />
        <path style={styling3} d="M92.87,400.22c0,0,1,2.75,3.13,4.38" />
        <path style={styling3} d="M104.98,402.85c0,0-2.63,5.06-6.23,7.64" />
        <path style={styling3} d="M68.08,133.77c0,0,3.02,4.81,6.8,6.54" />
        <path
          style={styling3}
          d="M198.81,155.87c0,0,1.85-8.88,3.02-19.91c1.17-11.03,0.39-22.83-3.61-27.71c-4-4.88-16.37-9.21-19.41-10.15
        c-7.94-2.47-19.47-4.27-28.69-8.05c-2.23-0.92-2.95-9.04-3.07-11.37s-0.78-12.92-0.78-12.92c1.56-3.29,2.68-6.66,3.01-9.59
        c0,0,4.07,0.95,7.1-7.45c3.24-8.97-1.56-12.47-4.33-9.7c0,0,1.65-12.78-0.61-19.32c-2.54-7.34-8.6-15.93-24.43-15.94
        c-15.84,0.01-21.91,8.6-24.45,15.94c-2.26,6.54-0.61,19.32-0.61,19.32c-2.77-2.77-7.57,0.73-4.33,9.7c3.03,8.4,7.1,7.45,7.1,7.45
        c0.33,3,1.5,6.47,3.12,9.83c0,0.15-0.42,10.21-0.54,12.55c-0.12,2.34-0.84,10.46-3.07,11.37c-9.22,3.79-20.75,5.58-28.69,8.05
        c-3.03,0.95-15.41,5.28-19.41,10.15s-4.78,16.69-3.61,27.71c1.17,11.03,3.02,19.91,3.02,19.91"
        />
        <path
          style={styling3}
          d="M199.97,150.46c0,0,5.34,13.77,4.84,34.05c0,0,7.96,12.39,9.51,22.7c0.92,6.09,1.17,12.52,1.5,15.94
        c0.26,2.65,3.23,20.35,4.55,28.17"
        />
        <path
          style={styling3}
          d="M178.75,129.88c-0.48,2.34,0.1,6.68,0.48,9c0.22,1.37,0.38,2.75,0.44,4.13c0.47,10.03-1.39,17.55-1.39,17.55
        c-0.28,5.45,2.21,14.96,2.76,17.37c0.55,2.41,3.38,11.44,4.27,13.72c0.9,2.27,0.83,4.34,0.83,6.34c0,2,0.28,13.1,0.76,16.34
        s4.48,10.55,6.41,14.2c1.93,3.65,7.86,15.92,9.38,19.1c0.86,1.8,2.26,5.11,3.41,7.89c0.78,2,1.2,3.21,1.2,3.21
        s-1.31,2.99-2.21,4.44c-0.9,1.45-5.6,7.48-5.67,9.35c-0.07,1.88-0.17,5.35-0.06,6.67s-0.19,3.14-0.39,4.24
        c-0.2,1.1,0.33,2.71,0.56,3.28c0.24,0.56,0.25,2.83-0.07,4.34s-0.33,2.56,0.44,2.85c0.77,0.29,3.34-0.91,4.18-3.48
        s1.18-8.9,2.88-11.36c2.21-3.18,3.73-3.75,4.99-4.69s2.93-2.32,3.38-6.27"
        />
        <path
          style={styling3}
          d="M206.33,280.65c0,0,1.34,1.47,3.18,3.24c1.83,1.77,3.15,3.5,3.83,4.88c0.68,1.38,2.83,6.65,4.16,10.08
        c1.33,3.43,3.56,11.65,6.09,12.25c2.71,0.63,2.04-3.06,1.06-6.01s-3.9-12.8-3.54-13.93c0.36-1.12,0.16-0.38,0.63,0.82
        c0.47,1.2,3.6,9.41,5.68,13.03c2.08,3.63,3.61,6.95,5.96,5.52c2.12-1.29-0.06-4.38-0.89-6.15c-2.34-5-6.24-15.89-6.3-16.25
        c-0.06-0.36,4.24,8.19,6.4,11.21c2.17,3.02,4.28,6.85,6.25,5.51c1.66-1.12,0.59-4.03-1.02-6.46c-1.61-2.43-6.74-12.82-6.69-13.33
        s1.39,0.96,2.35,2.04c0.96,1.08,4.98,5.33,5.71,6.08c0.74,0.74,2.45,1.73,3.11,0.12c0.66-1.61-0.65-3.11-1.91-4.83
        c-1.26-1.72-4.51-5.49-5.46-7.47c-0.94-1.98-1.69-4.72-2.24-6.5s-2.27-7.59-3.29-10.16c-1.02-2.57-2.63-5.41-4.09-6.6
        c-1.47-1.19-3.64-2.32-4.15-3.78c-0.22-0.64-0.58-1.39-0.78-2.88"
        />
        <path style={styling3} d="M219.85,291.75c0,0,1.78-1.77,2.15-3.08" />
        <path style={styling3} d="M224.43,288.22c0,0,2.01-0.61,2.53-0.93" />
        <path style={styling3} d="M222.14,273.64c0,0,2.71,2.17,4.39,2.51" />
        <path style={styling3} d="M200.28,186.91c0,0-0.37,2.74-1.12,3.87" />
        <path style={styling3} d="M191.8,191.21c0,0,2.62,3.87,4.24,4.61" />
        <path style={styling3} d="M163.21,404.26c0,0-1.4,5.1-4.39,8.14" />
        <path style={styling3} d="M150.19,404c0,0,1.52,2.04,3.61,3.09" />
        <path style={styling3} d="M186.31,133.89c0,0-3.04,4.83-6.83,6.56" />
        <path style={styling3} d="M96.45,278.42c0,0,18.99,8.51,30.89-5.16" />
        <path style={styling3} d="M127.21,249.47l0.26,23.85c11.89,13.67,30.89,5.16,30.89,5.16" />
        <path
          style={styling3}
          d="M87.32,509.89c0,0-3.04,1.78-4.71,2.57c-1.67,0.8-3.22,1.3-4.09,2.28c-0.87,0.98-1.56,1.88-2.28,2.68
        c-0.72,0.8-2.39,1.85-1.78,3.8c0.62,1.96,1.88,2.79,4.49,4.06c2.61,1.27,5,2.43,5.44,3.15"
        />
        <path
          style={styling3}
          d="M83.92,526.81c0,0,0.62,1.7,1.09,2.79c0.47,1.09,2.25,5.44,11.99,5.11s10.31-4.24,10.29-5.51
        c-0.04-2.25-1.09-5.47-1.01-7.36c0.07-1.88,1.38-4.17,1.45-5.47c0.07-1.3-0.29-8.37-0.29-10.11c0-1.74-1.31-21.01-1.72-23.4
        c-0.41-2.39-1.57-9.75-0.75-15.2c0.82-5.45,2.93-15.41,3.55-18.34c0.61-2.93,2.52-11.11,2.8-13.57c0.27-2.45,0.61-14.25,0.27-17.52
        c-0.34-3.27-1.02-6.34-1.02-7.57s0.68-3.82,1.64-5.59c0.95-1.77,4.84-10.64,5.52-13.02c0.68-2.39,1.64-7.7,1.5-13.57
        c-0.14-5.86-0.61-20.59-0.55-22.43c0.07-1.84,0.14-17.32,1.57-27.61c1.43-10.3,2.73-19.64,3.68-28.64c0.95-9,1.16-10.86,1.16-10.86
        l2.03-15.1"
        />
        <path
          style={styling3}
          d="M169.06,512.86c-1.96-2.5-4.02-6.23-3.91-10.19c0.09-3.23-0.66-5.67-0.89-7.04l-0.13-0.98
        c-0.11-1.12-0.18-2.24-0.2-3.33c-0.07-3.95,1.64-11.25,2.8-16.09s3.82-16.36,5.45-22.91c1.64-6.55,2.86-13.3,2.93-19.7
        s-2.32-16.91-2.93-20.18s-3-16.23-3.07-24.95c-0.07-8.73,0.89-12.34,1.77-19.77c0.89-7.43,3.48-19.23,4.5-25.77
        c1.02-6.55,2.66-18.14,2.86-21.55s1.43-27,0.48-44.11c-0.66-11.77-3.18-23.56-4.69-29.78c-0.8-3.27-1.3-6.6-1.48-9.96
        c-0.33-6.02-0.78-15.51-0.73-20.5c0.16-13.72,6.45-55.45,6.45-55.45"
        />
        <path
          style={styling3}
          d="M167.28,510.4c0,0,3.04,1.78,4.71,2.57c1.67,0.8,3.22,1.3,4.09,2.28c0.87,0.98,1.56,1.88,2.28,2.68
        c0.72,0.8,2.39,1.85,1.78,3.8c-0.62,1.96-1.88,2.79-4.49,4.06c-2.61,1.27-5,2.43-5.44,3.15"
        />
        <path
          style={styling3}
          d="M170.69,527.32c0,0-0.62,1.7-1.09,2.79c-0.47,1.09-2.25,5.43-11.99,5.11c-9.75-0.33-10.31-4.24-10.29-5.51
        c0.04-2.25,1.09-5.47,1.01-7.36s-1.38-4.17-1.45-5.47c-0.07-1.3,0.29-8.37,0.29-10.11c0-1.07,0.5-8.83,0.98-15.17
        c0.02-0.36,0.03-0.64,0.04-0.84c0.07-1.7,0.07-5.39,0.48-7.77s1.57-9.75,0.75-15.2c-0.82-5.45-2.93-15.41-3.55-18.34
        c-0.61-2.93-2.52-11.11-2.8-13.57c-0.27-2.45-0.61-14.25-0.27-17.52c0.34-3.27,1.02-6.34,1.02-7.57s-0.68-3.82-1.64-5.59
        c-0.95-1.77-4.84-10.64-5.52-13.02c-0.68-2.39-1.64-7.7-1.5-13.57c0.14-5.86,0.61-20.59,0.55-22.43
        c-0.07-1.84-0.14-17.32-1.57-27.61c-1.43-10.3-2.73-19.64-3.68-28.64c-0.95-9-1.19-11.32-1.19-11.32l-1.38-15.03"
        />
        <path style={styling3} d="M107.98,116.92c0,0,5.58,10.63,6.32,15.14c0.95,5.74-0.31,15.03-1.66,21.67" />
        <path style={styling3} d="M148.58,117.07c0,0-5.58,10.63-6.32,15.14c-0.95,5.74,0.31,15.03,1.66,21.67" />
        <path style={styling3} d="M250.75,310.93" />
      </g>
    </svg>
  )
}
