import React, { CSSProperties, FC, ReactNode } from 'react'
import { Typography } from 'antd'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.title The title to use
 * @param props.style The stylings to use
 * @returns The ChatText component
 */
export const ChatText: FC<{ title: ReactNode[] | string; style?: CSSProperties }> = ({ title, style }) => {
  return (
    <Typography
      style={{
        color: CSS_VARS.chatFontColor,
        fontSize: CSS_VARS.chatFontSize,
        fontWeight: CSS_VARS.chatFontWeight,
        fontFamily: CSS_VARS.fontFamily,
        ...style,
      }}
    >
      {title}
    </Typography>
  )
}
