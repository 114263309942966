/**
 * These styles are used for making the male body SVGs have dynamic colors
 *
 * @param strokeColor The stroke color - effects the outline color
 * @param fillColor The fill color - effects the 'background' of the SVG
 * @returns The augmented stylings
 */
const maleStyles = (strokeColor = '#cccccc', fillColor = '#ffffff') => ({
  styling1: { opacity: '0.35', fill: fillColor, transition: 'fill .3s ease-in' },

  styling2: { display: 'inline', opacity: '0.35', fill: fillColor, transition: 'fill .3s ease-in' },

  styling3: {
    fill: 'none',
    stroke: strokeColor,
    strokeWidth: 2,
    strokeMiterlimit: 10,
    transition: 'fill .3s ease-in',
  },
})

/**
 * These styles are used for making the female body SVGs have dynamic colors
 *
 * @param strokeColor The stroke color - effects the outline color
 * @param fillColor The fill color - effects the 'background' of the SVG
 * @returns The augmented stylings
 */
const femaleStyles = (strokeColor = '#cccccc', fillColor = '#ffffff') => ({
  styling2: { opacity: '0.35', fill: fillColor, strokeWidth: 2, transition: 'fill .3s ease-in' },

  styling3: { display: 'inline', fill: fillColor, strokeWidth: 2, transition: 'fill .3s ease-in' },

  styling4: { display: 'inline', opacity: '0.35', fill: fillColor, strokeWidth: 2, transition: 'fill .3s ease-in' },

  styling5: {
    fill: 'none',
    strokeWidth: 2,
    stroke: strokeColor,
    strokeMiterlimit: 10,
    transition: 'fill .3s ease-in',
  },

  styling6: {
    fill: 'none',
    strokeWidth: 2,
    stroke: strokeColor,
    strokeLineCap: 'round',
    strokeMiterlimit: 10,
    transition: 'fill .3s ease-in',
  },

  styling7: {
    fill: 'none',
    strokeWidth: 2,
    stroke: strokeColor,
    strokeLineCap: 'round',
    strokeLineJoin: 'round',
    strokeMiterlimit: 10,
    transition: 'fill .3s ease-in',
  },
})

/**
 * These styles are used for making the SVGs have dynamic hover colors
 *
 * @param fillColor The fill color - effects the hover color
 * @returns The augmented stylings
 */
const hover = (fillColor = '#cccccc') => ({
  fill: fillColor,
  opacity: 0.35,
  cursor: 'pointer',
  transition: 'fill .3s ease-in',
})

export { maleStyles, femaleStyles, hover }
