export const backIds = {
  legsId: 'Legs',
  lowerBackId: 'Lowerback',
  lowerArmLeftId: 'Lower_arm_left',
  upperArmLeftId: 'Upper_arm_left',
  lowerArmRightId: 'Lower_arm_right',
  upperArmRightId: 'Upper_arm_right',
  backId: 'Back',
  occiputId: 'Occiput',
}

export const frontIds = {
  legsId: 'Legs',
  hipsId: 'Hips',
  lowerArmLeftId: 'Lower_arm_left',
  upperArmLeftId: 'Upper_arm_left',
  lowerArmRightId: 'Lower_arm_right',
  upperArmRightId: 'Upper_arm_right',
  abdomenId: 'Abdomen',
  headId: 'Head',
}
