import styled from '@emotion/styled'
import { Col, Row } from 'antd'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { CSS_VARS, JS_STYLING } from '../../../resources/cssVariableConfig'

export const Container = styled.div<{ isMenuOpen: boolean }>(
  `
  height: calc(100vh - 70px);
  width: 400px;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 300;
  overflow: scroll;
  box-shadow: 12px 0px 23px 0px rgba(0, 0, 0, 0.1);
  background-color: white;

  @media screen and (orientation: portrait) and (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}),
    screen and (orientation: portrait) and (max-width: ${JS_STYLING.TABLET_STANDARD_MAX_WIDTH}),
    screen and (orientation: portrait) and (max-height: ${JS_STYLING.TABLET_LARGE_MAX_WIDTH}) {
    width: 45%;
  }

  @media screen and (orientation: landscape) and (max-height: ${JS_STYLING.MOBILE_LANDSCAPE_MAX_WIDTH}) and (max-width: ${JS_STYLING.TABLET_LARGE_MAX_WIDTH}) {
    width: 30%;
  }

  @media screen and (orientation: landscape) and (max-width: ${JS_STYLING.MOBILE_LANDSCAPE_MAX_WIDTH}) and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) {
    width: 40%;
  }

  @media screen and (orientation: landscape) and (max-width: 740px) and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) {
    width: 48%;
  }

  @media screen and (orientation: landscape) and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) {
    width: 50%;
  }
  `,
  (props) =>
    !props.isMenuOpen &&
    `
      height: 70px;
      border-bottom-right-radius: 15px;
    `,
)

export const Menu = styled.div`
  width: 100%;
  z-index: 300;
  position: relative;

  input {
    height: 40%;
    width: 100%;
    border: none;
    border: 1px solid #dbdbdb;
    outline: none;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
  }

  .ant-input-affix-wrapper {
    line-height: 14px;
    border-radius: 5px;
    border-width: 0.5px;
    padding: 5px 10px;

    svg {
      color: ${CSS_VARS.sideMenuBackgroundColor};
    }
  }
`

export const SearchRow = styled(Row)`
  margin: 20px 0 10px 0;
`

export const SearchContainer = styled(Col)`
  transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin: 0 18px 0 20px;
`

export const MenuButton = styled.button`
  margin: 5px 18px 0 0px;
  border: none;
  background: transparent;
  cursor: pointer;
`

export const CloseMenuContainer = styled(Col)`
  display: none;

  @media screen and (orientation: portrait) and (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}),
    screen and (orientation: portrait) and (max-width: ${JS_STYLING.TABLET_STANDARD_MAX_WIDTH}),
    screen and (orientation: portrait) and (max-height: ${JS_STYLING.TABLET_LARGE_MAX_WIDTH}) {
    display: block;
  }
`

export const SearchButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`

export const StyledIcon = styled(CustomIcon)`
  height: 15px;
  width: 15px;
`
