import React, { CSSProperties, FC } from 'react'
import { Button } from 'antd'
import styled from '@emotion/styled'
import { ActiveFilter } from '../../ServicesMenu'
import { useServicesContext } from '../../../../../context'
import { FilterKey, GenderFilter } from '../../../../../models/servicesFilters'
import { Container, buttonCommon } from '../Filters.common.styled'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

const StyledButton = styled(Button)`
  ${buttonCommon}

  box-shadow: none;
  border-radius: 13px !important;

  span {
    font-family: ${CSS_VARS.fontFamily};
    font-weight: ${CSS_VARS.filledButtonFontWeight};
    color: ${CSS_VARS.filledButtonFontColor};
  }
`

/**
 * @param props The props object
 * @param props.activeFilters The active filters
 * @param props.currentFilter The current filter
 * @param props.filter The genders filter
 * @param props.selectedButtonStyles The stylings for buttons in the selected state
 * @returns The GendersFilter component
 */
export const GendersFilter: FC<{
  activeFilters: ActiveFilter
  currentFilter: FilterKey
  filter: GenderFilter
  selectedButtonStyles: (val?: boolean) => CSSProperties
}> = ({ activeFilters, currentFilter, filter, selectedButtonStyles }) => {
  const { filterObject, setFilterObject } = useServicesContext()

  if (!(activeFilters[currentFilter] && currentFilter === 'genders')) {
    return null
  }

  return (
    <Container className="descriptionFadeIn">
      {filter.values.map((gender) => (
        <StyledButton
          key={gender.value}
          type="primary"
          style={selectedButtonStyles(filterObject?.[currentFilter]?.includes(gender.value))}
          onClick={() => {
            if (filterObject?.genders?.includes(gender.value)) {
              setFilterObject({
                ...filterObject,
                genders: filterObject?.genders?.filter((item) => item !== gender.value),
              })
            } else {
              setFilterObject({
                ...filterObject,
                genders: [...(filterObject.genders || []), gender.value],
              })
            }
          }}
        >
          {gender.text}
        </StyledButton>
      ))}
    </Container>
  )
}
