import { PlusOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Row, Typography } from 'antd'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

export const FilterRow = styled(Row)`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const Filter = styled(Typography)`
  color: ${CSS_VARS.chatFontColor} !important;
  font-size: ${CSS_VARS.chatFontSize};
  font-family: ${CSS_VARS.fontFamily};
  font-style: ${CSS_VARS.chatFontStyle};
  font-weight: ${CSS_VARS.chatFontWeight};
`

export const FilterContainer = styled.div`
  border-bottom: 1px solid #d9d9d9;
  user-select: none;
`

export const CollapseIcon = styled(PlusOutlined)`
  color: ${CSS_VARS.sideMenuBackgroundColor};
`
