import React, { FC, useMemo } from 'react'
import { Popover } from 'antd'
import { Marker } from 'react-map-gl'
import classnames from 'classnames'
import { ServiceWithDetailedOpeningHours } from '../../../../models'
import { CustomIcon } from '../../../CustomIcon/CustomIcon'
import { useServicesContext } from '../../../../context'
import { useScreenSize, useServiceIconProperties } from '../../../../hooks'
import { ServicesMapMarkerPopoverContent } from '../ServicesMapMarkerPopoverContent/ServicesMapMarkerPopoverContent'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import styles from './ServicesMapMarker.module.less'

/**
 * @param props The props object
 * @param props.service The service to display
 * @returns The ServicesMapMarker component
 */
export const ServicesMapMarker: FC<{
  service: ServiceWithDetailedOpeningHours
}> = ({ service }) => {
  const { selectedService, setSelectedService } = useServicesContext()
  const { getServiceTypeColor } = useServiceIconProperties()
  const { isMobileView } = useScreenSize()

  const {
    type: serviceType,
    id,
    location: { longitude, latitude },
  } = service

  const lowerCaseServiceType = useMemo(() => serviceType.toLowerCase(), [serviceType])
  const isSelected = useMemo(() => selectedService?.id === id, [selectedService?.id, id])

  const currentServiceTypeColor = useMemo(() => getServiceTypeColor(serviceType), [getServiceTypeColor, serviceType])

  const serviceActiveColor = useMemo(
    () => (isSelected ? CSS_VARS.chatBackgroundColor : currentServiceTypeColor),
    [currentServiceTypeColor, isSelected],
  )

  return (
    <Marker longitude={longitude} latitude={latitude} anchor="bottom">
      <Popover
        trigger={isMobileView ? 'click' : 'hover'}
        zIndex={300}
        content={<ServicesMapMarkerPopoverContent services={[service]} />}
        destroyTooltipOnHide
      >
        <div
          className={classnames(
            isSelected ? styles[`marker_${lowerCaseServiceType}_active`] : styles[`marker_${lowerCaseServiceType}`],
          )}
          style={{
            background: isSelected ? currentServiceTypeColor : CSS_VARS.chatBackgroundColor,
            borderColor: serviceActiveColor,
          }}
          onClick={() => (isSelected ? setSelectedService(null) : setSelectedService(service))}
        >
          <CustomIcon icon={lowerCaseServiceType} style={{ height: 13, width: 13, fill: serviceActiveColor }} />
        </div>
      </Popover>
    </Marker>
  )
}
