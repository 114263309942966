import styled from '@emotion/styled'
import waveBottom from '../../../assets/images/wave_bottom.svg'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

export const WaveContainer = styled.div<{ displayWave: boolean }>(``, (props) =>
  props.displayWave
    ? `
      ::before {
        content: '';
        width: 100%;
        height: 45px;
        mask-image: url('${waveBottom}');
        background-color: ${CSS_VARS.sideMenuBackgroundColor};
        mask-repeat: repeat-x;
        display: block;
      }

      > div {
        background-color: ${CSS_VARS.waveBgColor};
        color: ${CSS_VARS.DARK_GREY_COLOR};
        user-select: none;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px;

        img {
          margin-left: 5px;
          height: 10px;
          cursor: pointer;
        }
      }
      `
    : `
      > div {
        color: ${CSS_VARS.DARK_GREY_COLOR};
        user-select: none;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        padding-bottom: 16px;

        img {
          margin-left: 5px;
          height: 10px;
          cursor: pointer;
        }
      }
      `,
)
