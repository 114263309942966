import React, { FC } from 'react'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { Container } from './SideMenuToggleMobile.styled'

/**
 * @param props The props object
 * @param props.clicked A method handling the click action
 * @returns The hamburger menu for the navbar
 */
export const SideMenuToggleMobile: FC<{
  clicked: () => void
}> = ({ clicked }) => {
  return (
    <Container id="menu-btn" onClick={clicked}>
      <CustomIcon icon="menuV2" />
    </Container>
  )
}
