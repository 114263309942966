import styled from '@emotion/styled'

export const MainContainer = styled.div`
  height: calc(100vh - 70px);
  width: 100%;
`

export const InnerContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;

  .mapboxgl-map {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 70px;
    bottom: 0;
  }
`

export const InnerContainerMobile = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  position: fixed;

  .mapboxgl-map {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .mapboxgl-ctrl-top-right {
    top: 72px;
  }
`

export const Alert = styled.span`
  position: absolute;
  top: 20%;
  left: calc(75% - 30%);
  width: 30%;
  padding: 5px;
  background: white;
  border-radius: 5px;
  z-index: 100000;
  border: 1px solid #000;
  text-align: center;
`
