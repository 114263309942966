import React, { FC } from 'react'
import { Row, Typography } from 'antd'
import { CustomIcon } from '../../../CustomIcon/CustomIcon'
import { SearchItemMobile } from '../../ServicesMenuMobile/SearchItemMobile/SearchItemMobile'
import { useServicesContext } from '../../../../context'
import { useServiceIconProperties, useScreenSize } from '../../../../hooks'
import { ServiceWithDetailedOpeningHours } from '../../../../models'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import styles from './ServicesMapMarkerPopoverContent.module.less'

/**
 * @param props The props object
 * @param props.services The services to display
 * @returns The ServicesMapMarkerPopoverContent component
 */
export const ServicesMapMarkerPopoverContent: FC<{ services: ServiceWithDetailedOpeningHours[] }> = ({ services }) => {
  const { selectedService, setSelectedService } = useServicesContext()
  const { getServiceTypeColor } = useServiceIconProperties()
  const { isMobileView } = useScreenSize()

  return (
    <div className={styles.markerGroupList}>
      {services.map((service) => {
        const serviceType = service.type.toLowerCase()

        const isServiceSelected = selectedService?.id === service.id

        const serviceColor = getServiceTypeColor(service.type)

        return (
          <div key={service.id}>
            <Row
              align="middle"
              key={service.id}
              className={styles.markerGroupItem}
              onClick={() => (isServiceSelected ? setSelectedService(null) : setSelectedService(service))}
            >
              <span
                className={styles.markerGroupItemIcon}
                style={{ borderColor: isServiceSelected ? serviceColor : CSS_VARS.chatBackgroundColor }}
              >
                <CustomIcon
                  icon={serviceType}
                  style={{ fill: serviceColor, stroke: serviceColor, height: 13, width: 13 }}
                />
              </span>{' '}
              <Typography style={{ color: CSS_VARS.chatFontColor, padding: 5, maxWidth: '90%' }}>
                {service.name}
              </Typography>
            </Row>

            {isServiceSelected && isMobileView && (
              <SearchItemMobile service={service} onClick={() => setSelectedService(null)} />
            )}
          </div>
        )
      })}
    </div>
  )
}
