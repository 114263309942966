import React, { CSSProperties, FC, useMemo } from 'react'
import { Popover } from 'antd'
import { Marker } from 'react-map-gl'
import { ServiceWithDetailedOpeningHours } from '../../../../models'
import { useServicesContext } from '../../../../context'
import { ServicesMapMarkerPopoverContent } from '../ServicesMapMarkerPopoverContent/ServicesMapMarkerPopoverContent'
import { useScreenSize } from '../../../../hooks'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import styles from './ServicesMapMarkerGroup.module.less'

/**
 * @param props The props object
 * @param props.coordinates Where the Marker group should appear on the map
 * @param props.services The service to display
 * @param props.style The additional stylings
 * @param props.setZoom The setter for the current zoom level
 * @returns The ServicesMapMarkerGroup component
 */
export const ServicesMapMarkerGroup: FC<{
  coordinates: [number, number]
  services: ServiceWithDetailedOpeningHours[]
  style: CSSProperties
  setZoom: React.Dispatch<React.SetStateAction<number>>
}> = ({ coordinates, services, style, setZoom }) => {
  const { selectedService } = useServicesContext()
  const { isMobileView } = useScreenSize()

  const [longitude, latitude] = coordinates

  const isSelected = useMemo(
    () => services.some((item) => item.id === selectedService?.id),
    [selectedService?.id, services],
  )

  const showMarkerGroupPopover = useMemo(() => services.length <= 12, [services.length])

  return (
    <Marker longitude={longitude} latitude={latitude} anchor="bottom">
      <Popover
        zIndex={300}
        trigger={isMobileView ? 'click' : 'hover'}
        overlayClassName={styles.popover}
        content={showMarkerGroupPopover && <ServicesMapMarkerPopoverContent services={services} />}
        destroyTooltipOnHide
      >
        <div
          className={styles.markerGroup}
          style={{
            background: isSelected ? CSS_VARS.chatBackgroundColor : CSS_VARS.filledButtonBackgroundColor,
            color: isSelected ? CSS_VARS.filledButtonBackgroundColor : CSS_VARS.chatBackgroundColor,
            border: isSelected ? `1px solid ${CSS_VARS.filledButtonBackgroundColor}` : 'none',
            transform: isSelected ? 'scale(1.4)' : '',
            ...style,
          }}
          onDoubleClick={(e) => e.detail === 1 && setZoom((zoom) => zoom + 1)}
        >
          {services.length}
        </div>
      </Popover>
    </Marker>
  )
}
