// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TBepp399LuVKs9iWiCaC {\n  margin: 0;\n  font-weight: 700;\n}\n.HF8N0LtqXRTf0Jz5uTc9 {\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 0.5px solid #bfbfbf;\n  display: flex;\n  min-height: 54px;\n  padding-bottom: 7px;\n  padding-top: 5px;\n}\n.HF8N0LtqXRTf0Jz5uTc9:hover {\n  background-color: #f7f8fa;\n}\n.eTjZp0S1pgvecPt1Qrzu {\n  margin: 0;\n}\n@media screen and (max-width: 576px) {\n  .TBepp399LuVKs9iWiCaC {\n    line-height: 1.2;\n  }\n  .eTjZp0S1pgvecPt1Qrzu {\n    max-width: fit-content;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/SymptomSuggestionSelector/SymptomItem/SymptomItem.module.less"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,gBAAA;AADF;AAIA;EACE,mBAAA;EACA,8BAAA;EACA,kCAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AAFF;AAGE;EACE,yBAAA;AADJ;AAKA;EACE,SAAA;AAHF;AAMA;EACE;IACE,gBAAA;EAJF;EAOA;IACE,sBAAA;EALF;AACF","sourcesContent":["@import '../../../variables.less';\n\n.symptomItemTitle {\n  margin: 0;\n  font-weight: 700;\n}\n\n.symptomSuggestionItem {\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 0.5px solid #bfbfbf;\n  display: flex;\n  min-height: 54px;\n  padding-bottom: 7px;\n  padding-top: 5px;\n  &:hover {\n    background-color: #f7f8fa;\n  }\n}\n\n.symptomItemDescription {\n  margin: 0;\n}\n\n@media screen and (max-width: @mobile-max-width) {\n  .symptomItemTitle {\n    line-height: 1.2;\n  }\n\n  .symptomItemDescription {\n    max-width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"symptomItemTitle": "TBepp399LuVKs9iWiCaC",
	"symptomSuggestionItem": "HF8N0LtqXRTf0Jz5uTc9",
	"symptomItemDescription": "eTjZp0S1pgvecPt1Qrzu"
};
export default ___CSS_LOADER_EXPORT___;
