import React, { FC } from 'react'
import { useBreakpoints } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.value The value to display
 * @returns The SliderLabel component
 */
export const SliderLabel: FC<{ value: number | string }> = ({ value }) => {
  const { isScreenSizeXS } = useBreakpoints()

  return (
    <span
      style={{
        color: CSS_VARS.chatFontColor,
        fontSize: isScreenSizeXS ? '3vw' : CSS_VARS.chatFontSize,
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        maxWidth: '100px',
        display: 'block',
        lineHeight: '1em',
      }}
    >
      {value}
    </span>
  )
}
