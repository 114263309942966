import React, { FC, useMemo } from 'react'
import { useElementSize } from 'usehooks-ts'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { ProgressDetailedData } from '../../models/api/response/detailedTypeData/ProgressDetailedData'
import { mobileMaxWidth } from '../../hooks'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.isLoading The boolean whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The Progress component
 */
export const Progress: FC<{
  question: Question
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, isLoading, sendAnswer }) => {
  const { text, detailedTypeData } = question
  const [ref, { width }] = useElementSize()

  const marginSize = useMemo(() => (width > mobileMaxWidth ? 150 : 25), [width])
  const icon = useMemo(() => {
    switch ((detailedTypeData as ProgressDetailedData).currentStep) {
      case 1:
        return 'infoMainSymptomSelection'
      case 2:
        return 'infoAdditionalSymptoms'
      case 3:
        return 'infoIllnessRelatedQuestions'
      default:
        return 'infoMainSymptomSelection'
    }
  }, [detailedTypeData])

  return (
    <WrapperWithTitleAndAnimation
      elementReference={ref}
      title={text}
      tagline={(detailedTypeData as ProgressDetailedData).title}
      icon={icon}
      isAnimated
      animationStartsWhen={!isLoading}
      style={{
        textAlign: 'center',
        alignItems: 'center',
        marginLeft: marginSize,
        marginRight: marginSize,
        letterSpacing: 0.17,
        marginTop: 100,
      }}
    />
  )
}
