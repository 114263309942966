import { CSS_VARS, JS_STYLING } from './cssVariableConfig'

/**
 * @returns The modified classes to use
 */
const customizedAntDesignStylings = () => {
  return `
${JS_STYLING.fontCssUrl ? '*' : 'body, h3, h4, p'} {
  font-family: ${CSS_VARS.fontFamily};
}

.ant-steps.ant-steps-small .ant-steps-item-title {
  font-size: ${CSS_VARS.chatFontSize};
}

.ant-steps.ant-steps-small .ant-steps-item {
  min-width: fit-content;
}

.ant-steps.ant-steps-small .ant-steps-item-container {
  min-width: fit-content;
}

@media (max-width: 800px) {
  .ant-steps.ant-steps-small .ant-steps-item-icon {
    margin-inline: 0 4px;
  }
  .ant-steps.ant-steps-small .ant-steps-item-title {
    padding-inline-end: 0px;
    font-size: 12px;
  }
}

/* Start of Status bar styles ---> */

/* Selected item */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: ${CSS_VARS.chatBackgroundColor};
 }
.ant-steps-item-process .ant-steps-item-icon {
  border-color: ${CSS_VARS.statusBarColor};
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: ${CSS_VARS.filledButtonBorderColor};
}

/* Not selected items */
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: ${CSS_VARS.chatBackgroundColor};
  border-color: #BFBFBF;
}
.ant-steps-item-wait .ant-steps-item-icon {
  border-color: ${CSS_VARS.statusBarColor};
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #BFBFBF;
}

/* Items with the check mark icon */
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: ${CSS_VARS.statusBarColor};
  border-color: ${CSS_VARS.statusBarColor};
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: ${CSS_VARS.filledButtonFontColor};
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: ${CSS_VARS.statusBarColor};
}
/* Item labels */
.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  margin-top: 0px;
}

/* Vertical line */
.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  padding: 4px 15px;
}
.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #BFBFBF;
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #BFBFBF;
}

/* <--- Status bar inactive background styles */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: ${CSS_VARS.inactiveElementColor};
}
/* <--- End of Status bar styles */

/* Slider track background */
.ant-slider-step {
  border-radius: 5px;
  background-color: ${CSS_VARS.inactiveElementColor};
  z-index: 0;
}

/* Illness icons */
svg[height="511px"] g g, 
.illnessIcon svg g g {
 stroke: ${CSS_VARS.questionFontColor};
 stroke-width: 18;
}

.quickSolutionIcon svg path,
.quickSolutionIconColor svg path {
 fill: ${CSS_VARS.questionFontColor};
 fill-opacity: 1;
}

/* Search bar */
input:hover,
input:focus {
 border-color: ${CSS_VARS.questionFontColor} !important;
}

/* Buttons */
.ant-btn-primary:hover {
  opacity: 0.8;
}
.ant-btn:not(.ant-btn.ant-btn-primary):hover {
  color: ${CSS_VARS.filledButtonBorderColor} !important;
  border-color: ${CSS_VARS.filledButtonBorderColor};
}
.ant-btn.ant-btn-link.ant-btn-icon-only {
  border: none;
}

.ant-card.ant-card-bordered:not(.ant-card-hoverable):hover {
  background: ${CSS_VARS.filledButtonBorderColor}10;
  border-color: ${CSS_VARS.filledButtonBorderColor}10;
}

.ant-btn span {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

#icon {
  stroke: ${CSS_VARS.questionFontColor};
}

#iconFill {
  fill: ${CSS_VARS.questionFontColor};
}

/* Geolocation controller user position icon override */
.mapboxgl-user-location-dot, .mapboxgl-user-location-dot:after {
  background-color: ${CSS_VARS.filledButtonBorderColor};
}
.mapboxgl-user-location-dot:after {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: ${CSS_VARS.filledButtonBorderColor};
}
.mapboxgl-user-location-dot, .mapboxgl-user-location-dot:before {
  width: 20px;
  height: 20px;
}
`
}

export const appendCustomizedStylesToDOM = () => {
  const css = document.createElement('style')

  const styles = customizedAntDesignStylings()

  css.appendChild(document.createTextNode(styles))

  document.getElementsByTagName('head')[0].appendChild(css)
}
