import { useEffect, useState } from 'react'

/**
 * The hook is responsible for loading the token from fragment and storing in local storage
 *
 * @returns The token
 */
export const useAuthToken = () => {
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const tokenFromFragment = window.location.hash?.slice(1)
    if (tokenFromFragment) {
      window.appStorage.setItem('token', tokenFromFragment)
      window.location.replace('#')
      if (typeof window.history.replaceState == 'function') {
        history.replaceState({}, '', window.location.href.slice(0, -1))
      }
    }
    const providedOrStoredToken = tokenFromFragment || window.appStorage.getItem('token')
    if (!providedOrStoredToken) {
      throw new Error('Token not found')
    }
    setToken(providedOrStoredToken)
  }, [])

  return token
}
