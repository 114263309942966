import styled from '@emotion/styled'

export const Container = styled.div<{ isMenuOpen: boolean }>(
  `
    height: 100%;
    overflow: scroll;
    position: fixed;
    width: 100%;
    z-index: 300;
  `,
  (props) => (props.isMenuOpen ? { top: '67px' } : { top: '77px' }),
)

export const InnerContainer = styled.div`
  height: 89.5%;
  overflow: scroll;
`
