import React, { FC, useMemo, CSSProperties, ReactNode } from 'react'
import { Slider } from 'antd'
import { OptionItem } from '../../../models/api/response/OptionItem'
import { SliderLabel } from '../SliderLabel/SliderLabel'
import { useBreakpoints } from '../../../hooks'
import { SCALE_STEP_SIZE } from '../../../constants'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.values The option values of the current question
 * @param props.value The value provided in this property will be rendered
 * @param props.setValue This function will be called when a change on the slider is triggered
 * @param props.minValue The minimum value the slider can be set to
 * @param props.maxValue The maximum value the slider can be set to
 * @returns The ScaleSlider component
 */
export const ScaleSlider: FC<{
  values: OptionItem[]
  value: number
  setValue: (val: number) => void
  minValue: number
  maxValue: number
}> = ({ values, value, setValue, minValue, maxValue }) => {
  const { isScreenSizeXS } = useBreakpoints()

  const scaleQuestionMarks = useMemo(() => {
    const marks: {
      [key: number]: {
        style: CSSProperties
        label: ReactNode
      }
    } = {}
    values.forEach((mark: OptionItem, index: number) => {
      let markIndex: number
      let position: CSSProperties

      if (mark.from === minValue) {
        markIndex = minValue
        position = { left: 0, transform: 'none' }
      } else if (mark.to === maxValue) {
        markIndex = maxValue
        position = { left: 'auto', right: 0, transform: 'none' }
      } else {
        markIndex = ((mark.from as number) + (mark.to as number) + minValue) / 2
        const left = ((markIndex - minValue) / (maxValue - minValue)) * 100
        position = { left: `${left}%` }
      }

      marks[markIndex] = {
        style: { top: '5px', ...position },
        label: <SliderLabel value={values[index].text} />,
      }
    })

    const indicatorPosition = ((value - minValue) / (maxValue - minValue)) * 100

    marks[maxValue + 100] = {
      style: { top: '-40px', left: `${indicatorPosition}%` },
      label: (
        <span style={{ color: CSS_VARS.chatFontColor, fontSize: isScreenSizeXS ? '3vw' : CSS_VARS.chatFontSize }}>
          <strong>{value}</strong>
        </span>
      ),
    }

    return marks
  }, [values, value, minValue, maxValue, isScreenSizeXS])

  return (
    <Slider
      id="scale_slider"
      min={minValue}
      max={maxValue}
      marks={scaleQuestionMarks}
      // Z-index values are needed for making the background of the track dynamically stylable
      trackStyle={{ backgroundColor: CSS_VARS.sliderTrackColor, zIndex: 1 }}
      handleStyle={{
        borderColor: CSS_VARS.sliderHandleColor,
        backgroundColor: CSS_VARS.chatBackgroundColor,
        zIndex: 2,
      }}
      step={SCALE_STEP_SIZE}
      tooltip={{ open: false }}
      onChange={(val) => setValue(val)}
      value={value}
    />
  )
}
