// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q7awG0CJ_459d6aYGpZS {\n  font-size: 14px;\n  font-weight: 700;\n  margin-bottom: 0;\n}\n@media screen and (max-width: 576px) {\n  .Q7awG0CJ_459d6aYGpZS {\n    font-size: 15px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/SymptomAndIllnessSelector/SymptomAndIllnessList/ListItem/ListItem.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AADF;AAIA;EACE;IACE,eAAA;EAFF;AACF","sourcesContent":["@import '../../../../variables.less';\n\n.listText {\n  font-size: 14px;\n  font-weight: 700;\n  margin-bottom: 0;\n}\n\n@media screen and (max-width: @mobile-max-width) {\n  .listText {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listText": "Q7awG0CJ_459d6aYGpZS"
};
export default ___CSS_LOADER_EXPORT___;
