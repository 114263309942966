export const hover = {
  fillOpacity: 1,
  cursor: 'pointer',
  transition: 'fill-opacity .3s ease-in',
}

export const baseStyle = {
  fillOpacity: 0.3,
  transition: 'fill-opacity .3s ease-in',
}

export const upperRightAbdomenId = 'rechterOberbauch'
export const upperCentralAbdomenId = 'oberbauchMittig'
export const upperLeftAbdomenId = 'linkerOberbauch'
export const lateralAbdomenLeftId = 'bauchSeitlichLinks'
export const lateralAbdomenRightId = 'bauchSeitlichRechts'
export const navelId = 'nabel'
export const lowerRightAbdomenId = 'rechterUnterbauch'
export const lowerCentralAbdomenId = 'unterbauchMittig'
export const lowerLeftAbdomenId = 'linkerUnterbauch'

export const abdominalZoomIds = [
  upperRightAbdomenId,
  upperCentralAbdomenId,
  upperLeftAbdomenId,
  lateralAbdomenLeftId,
  lateralAbdomenRightId,
  navelId,
  lowerRightAbdomenId,
  lowerCentralAbdomenId,
  lowerLeftAbdomenId,
]
