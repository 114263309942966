import React, { FC, useMemo } from 'react'
import { Col } from 'antd'
import distance from '@turf/distance'
import mapboxgl from 'mapbox-gl'
import { point } from '@turf/helpers'
import { useServicesContext } from '../../../../../context'
import { Service } from '../../../../../models'
import { formatDistanceToMetersOrKilometers } from '../../../../../utils/services/formatDistanceToMetersOrKilometers'
import { ServiceOpeningHoursText } from './ServiceOpeningHoursText/ServiceOpeningHoursText'
import { StyledTitle, StyledTypography } from './SearchItemInfo.styled'

/**
 * @param props The props object
 * @param props.service The current service
 * @returns The SearchItemInfo component
 */
export const SearchItemInfo: FC<{ service: Service }> = ({ service }) => {
  const {
    coordinates: { longitude, latitude },
  } = useServicesContext()

  const { selectedService } = useServicesContext()

  const isCurrentServiceSelected = useMemo(() => service.id === selectedService?.id, [selectedService?.id, service.id])

  const from = useMemo(() => point([longitude, latitude]), [longitude, latitude])

  const to = useMemo(
    () => point([service.location.longitude, service.location.latitude]),
    [service.location.longitude, service.location.latitude],
  )
  const isMapboxSupported = useMemo(() => mapboxgl.supported(), [])

  const userDistance = useMemo(() => Math.round(distance(from, to, { units: 'meters' })), [from, to])

  return (
    <Col flex="auto">
      <StyledTitle level={5}>{service.name}</StyledTitle>

      <StyledTypography>
        {service?.specializations?.length &&
          service.specializations.map((spec, index) => (
            <span key={`${spec.id}-${index}`}>
              {spec.name}
              {index !== (service.specializations || []).length - 1 && <span>, </span>}
            </span>
          ))}
      </StyledTypography>

      {isMapboxSupported && !isCurrentServiceSelected && (
        <StyledTypography>{formatDistanceToMetersOrKilometers(userDistance)}</StyledTypography>
      )}

      {!isCurrentServiceSelected && (
        <StyledTypography>
          <ServiceOpeningHoursText openingHours={service.openingHours} />
        </StyledTypography>
      )}
    </Col>
  )
}
