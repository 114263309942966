import React, { FC, useEffect, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useElementSize } from 'usehooks-ts'
import { OptionItem } from '../../models/api/response/OptionItem'
import { OpaqueListOverlay } from '../OpaqueListOverlay/OpaqueListOverlay'
import { useQuestionContext } from '../../context/QuestionContext'
import { SymptomItem } from './SymptomItem/SymptomItem'
import styles from './SymptomSuggestionSelector.module.less'

/**
 * @param props The props object
 * @param props.symptomSuggestions The current symptom suggestions
 * @param props.onSelected The method handling selection
 * @returns The SymptomItemList component
 */
export const SymptomItemList: FC<{
  symptomSuggestions: OptionItem[]
  onSelected: (id: string, isSelected: boolean) => void
}> = ({ symptomSuggestions, onSelected }) => {
  const [options, setOptions] = useState(symptomSuggestions)
  const [atScrollEnd, setAtScrollEnd] = useState(false)
  const [isScrollable, setIsScrollable] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const [symptomRef, { height }] = useElementSize()
  const { isLoading } = useQuestionContext()

  useEffect(() => {
    setOptions(symptomSuggestions)
  }, [symptomSuggestions])

  useEffect(() => {
    setIsScrollable((ref?.current?.scrollHeight ?? 0) > (ref?.current?.clientHeight ?? 0))
  }, [options])

  return (
    <TransitionGroup component={null}>
      <div
        ref={ref}
        className={styles.symptomItemList}
        onScroll={(event) => {
          const target = event.target as HTMLElement
          setAtScrollEnd(target.offsetHeight + target.scrollTop >= target.scrollHeight)
        }}
      >
        {options.map((item, index) => (
          <CSSTransition key={item.id} in={!isLoading} timeout={200} classNames="symptomSuggestionAnimation">
            <SymptomItem
              elementReference={index === 0 ? symptomRef : undefined}
              key={item.id}
              title={item.text}
              item={item}
              onSelected={onSelected}
              isLoading={isLoading}
            />
          </CSSTransition>
        ))}
      </div>
      {isScrollable && <OpaqueListOverlay isHidden={atScrollEnd} height={height} />}
    </TransitionGroup>
  )
}
