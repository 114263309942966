import { createContext, useContext } from 'react'
import { ErrorResponseType } from '../components/ErrorBoundary'
import { WebAppConfig } from '../models/WebAppConfig'
import { defaultWebAppConfig } from '../resources/defaultWebAppConfig'

const initialValue: {
  webAppConfig: WebAppConfig
  isWebAppConfigLoading: boolean
  webAppConfigError: Error | ErrorResponseType | null
} = {
  webAppConfig: defaultWebAppConfig,
  isWebAppConfigLoading: false,
  webAppConfigError: null,
}

export const WebAppConfigContext = createContext(initialValue)

/**
 * The custom hook for the Web App context
 *
 * @returns The custom hook for the Web App context
 */
export const useWebAppConfigContext = () => useContext(WebAppConfigContext)
