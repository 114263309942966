import React, { FC } from 'react'
import { Col, Row } from 'antd'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { EmergencyDetailedData } from '../../models/api/response/detailedTypeData/EmergencyDetailedData'
import { CustomIcon } from '../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import styles from './Emergency.module.less'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.isLoading The boolean whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The Emergency component
 */
export const Emergency: FC<{
  question: Question
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, isLoading, sendAnswer }) => {
  const { title, description, symptoms } = question.detailedTypeData as EmergencyDetailedData

  const isWarningSignsPopupEnabled = window.xundEnvironment.WARNING_SIGNS_POPUP_ENABLED === 'true'
  const [symptomDisclaimer, warning] = description.split('. ')

  if (!isWarningSignsPopupEnabled) {
    return <></>
  }

  return (
    <WrapperWithTitleAndAnimation isAnimated animationStartsWhen={!isLoading}>
      <Col className={styles.container} style={{ background: CSS_VARS.RED_COLOR, color: CSS_VARS.chatBackgroundColor }}>
        <div style={{ textAlign: 'center', marginBottom: 15 }}>
          <CustomIcon
            icon="warning"
            className={styles.icon}
            style={{
              fill: CSS_VARS.chatBackgroundColor,
              stroke: CSS_VARS.chatBackgroundColor,
              width: 55,
              height: 55,
            }}
          />
        </div>
        <h1 style={{ color: CSS_VARS.chatBackgroundColor, fontSize: 20 }}>{title}</h1>
        <div style={{ textAlign: 'center' }}>
          <p style={{ color: CSS_VARS.chatBackgroundColor }} dangerouslySetInnerHTML={{ __html: warning }} />
        </div>
      </Col>
      {(symptoms.length ?? 0) > 0 && (
        <Col className={styles.disclaimer}>
          <p style={{ color: CSS_VARS.RED_COLOR }}>{symptomDisclaimer}</p>

          <Row className={styles.symptomList}>
            <div>
              {symptoms.map((symptom) => (
                <p key={symptom.id} className={styles.symptomListItem} style={{ color: CSS_VARS.RED_COLOR }}>
                  {symptom.name}
                </p>
              ))}
            </div>
          </Row>
        </Col>
      )}
    </WrapperWithTitleAndAnimation>
  )
}
