import styled from '@emotion/styled'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

export const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (orientation: portrait) and (max-width: @mobile-max-width) {
    padding: 0 5px 5px 5px;
  }
`
export const Article = styled.article`
  color: ${CSS_VARS.chatFontColor};
  font-family: ${CSS_VARS.fontFamily};
  font-size: ${CSS_VARS.chatFontSize};
  font-weight: ${CSS_VARS.chatFontWeight};
  text-align: center;
`
