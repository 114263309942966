import styled from '@emotion/styled'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

export const Container = styled.div`
  position: absolute;
  top: 18px;
  right: 15px;
  z-index: 300;
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);

  svg {
    width: 12px;
    height: 12px;
    color: ${CSS_VARS.sideMenuBackgroundColor};
  }
`
