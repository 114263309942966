import React from 'react'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

/**
 * @returns The EmergencyWaveBackground component
 */
export const EmergencyWaveBackground = () => {
  return (
    <svg
      width="862px"
      height="167px"
      viewBox="0 0 862 167"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Webapp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-291.000000, -1371.000000)"
          data-testid="EmergencyWaveBackground"
          fill={CSS_VARS.deleteButtonBackgroundColor}
        >
          <g transform="translate(290.000000, 1016.793333)">
            <g transform="translate(1.000000, 354.206667)">
              <path d="M0,0 L862,0 L862,155.910376 C770.771725,163.303459 689.552169,167 618.341333,167 C511.52508,167 320.782928,155.910376 218.373333,155.910376 C150.10027,155.910376 77.3091589,159.606917 0,167 L0,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
