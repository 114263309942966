import styled from '@emotion/styled'
import { Row } from 'antd'
import { Filters } from '../ServicesMenu/Filters/Filters.styled'
import { Menu as ServicesMenu } from '../ServicesMenu/ServicesMenu.styled'
import { JS_STYLING } from '../../../resources/cssVariableConfig'

export const Menu = styled(ServicesMenu)`
  display: flex;
  flex-direction: column;
`

export const Container = styled(Filters)<{ isMenuOpen: boolean }>(
  `
    height: auto !important;

    @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
        top: auto;
    }
    `,
  (props) =>
    props.isMenuOpen
      ? `
        top: 72px !important;
        height: 100% !important;
        `
      : `
        bottom: 0;
        height: 77px !important;
        min-height: 77px !important;
        overflow: hidden !important;
        `,
)

export const SearchRow = styled(Row)`
  padding-top: 8px;
`

export const IconContainer = styled.div`
  align-self: center;
`
