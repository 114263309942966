// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tKhO1bPa7Y5jXv3fDXq8 {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 15px 0;\n}\n.ZvGc5yX3TJdk7dvZEneV {\n  margin-right: 20px;\n  margin-left: 20px;\n  stroke-width: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SideMenu/MenuItem/MenuItem.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AACF;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;AAAF","sourcesContent":[".Container {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 15px 0;\n}\n\n.icon {\n  margin-right: 20px;\n  margin-left: 20px;\n  stroke-width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "tKhO1bPa7Y5jXv3fDXq8",
	"icon": "ZvGc5yX3TJdk7dvZEneV"
};
export default ___CSS_LOADER_EXPORT___;
