// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jY9_sHDVGToH83m5x5bV {\n  border-top: 1px #f2f2f2;\n  border-style: solid none none none;\n  padding-top: 25px;\n  margin: 25px auto 50px;\n}\n.jY9_sHDVGToH83m5x5bV > * {\n  margin-bottom: 25px;\n}\n.hwKKPm7x_7YGB9YxoQ9X {\n  margin: 40px auto 0px;\n}\n@media (min-width: 1440px) {\n  .jY9_sHDVGToH83m5x5bV {\n    display: grid;\n    gap: 25px;\n    grid-template-columns: repeat(2, 1fr);\n    align-items: center;\n  }\n  .jY9_sHDVGToH83m5x5bV > * {\n    margin-bottom: 0px;\n    max-width: 300px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Report/VirtualUrlServices/VirtualUrlServiceList.module.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kCAAA;EACA,iBAAA;EACA,sBAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,qBAAA;AADF;AAIA;EACE;IACE,aAAA;IACA,SAAA;IACA,qCAAA;IACA,mBAAA;EAFF;EAKA;IACE,kBAAA;IACA,gBAAA;EAHF;AACF","sourcesContent":[".virtualServicesGrid  {\n  border-top: 1px rgba(242,242,242, 1);\n  border-style: solid none none none;\n  padding-top: 25px;\n  margin: 25px auto 50px;\n}\n\n.virtualServicesGrid > * {\n  margin-bottom: 25px;\n}\n\n.loadMoreButton {\n  margin: 40px auto 0px;\n}\n\n@media (min-width: 1440px) {\n  .virtualServicesGrid {\n    display: grid;\n    gap: 25px;\n    grid-template-columns: repeat(2, 1fr);\n    align-items: center;\n  }\n\n  .virtualServicesGrid > * {\n    margin-bottom: 0px;\n    max-width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"virtualServicesGrid": "jY9_sHDVGToH83m5x5bV",
	"loadMoreButton": "hwKKPm7x_7YGB9YxoQ9X"
};
export default ___CSS_LOADER_EXPORT___;
