import React, { CSSProperties, FC, useMemo } from 'react'
import { frontIds } from '../bodyPartIds'
import { maleStyles } from '../styles'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

const { headId, upperArmLeftId, upperArmRightId, lowerArmLeftId, lowerArmRightId, abdomenId, hipsId, legsId } = frontIds

/**
 * @param props The props object
 * @param props.handleClick The click handler method
 * @param props.applyStyling The method which applies appropriate stylings for components
 * @param props.setIsHovered The setter for whether a component is hovered
 * @returns The MaleBodyFrontSVG component
 */
export const MaleBodyFrontSVG: FC<{
  handleClick: (eventTarget: EventTarget, bodyPartId: string) => void
  applyStyling: (id: string, styling: CSSProperties) => CSSProperties
  setIsHovered: (val: string) => void
}> = ({ handleClick, applyStyling, setIsHovered }) => {
  const maleStylings = useMemo(
    () => maleStyles(CSS_VARS.bodyPartSelectorSVGBodyOutlineAndHoverColor, CSS_VARS.bodyPartSelectorSVGColor),
    [],
  )

  const { styling1, styling2, styling3 } = maleStylings

  return (
    <svg
      data-testid="maleFrontSVG"
      className="bodySvg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 253.84 541.61"
    >
      <g
        id={legsId}
        onClick={(e) => handleClick(e.target, legsId)}
        onMouseEnter={() => setIsHovered(legsId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.71,276.27c0.05,0.85,0.09,1.71,0.12,2.59C178.8,277.98,178.76,277.11,178.71,276.27z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.85,279.26c0.03,0.84,0.06,1.69,0.09,2.54C178.92,280.95,178.89,280.1,178.85,279.26z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.95,282.29c0.02,0.87,0.04,1.74,0.06,2.62C179,284.03,178.98,283.16,178.95,282.29z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.07,291.23c0,0.9,0,1.81-0.01,2.7C179.07,293.04,179.07,292.14,179.07,291.23z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.06,288.07c0.01,0.93,0.01,1.87,0.01,2.8C179.07,289.94,179.06,289,179.06,288.07z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.02,285.26c0.02,0.9,0.03,1.8,0.04,2.71C179.05,287.06,179.04,286.16,179.02,285.26z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.27,287.98c0.01-0.93,0.02-1.86,0.04-2.79C75.29,286.12,75.28,287.05,75.27,287.98z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M180.69,526.97c-0.68-0.75-1.7-1.7-3.14-3.75s-3.34-3.89-5.11-5.32c-1.77-1.43-2.93-3.75-3.27-5.32
			c-0.34-1.57-1.91-7.02-3.2-9.95c-1.3-2.93-1.98-7.36-2.05-11.32s1.64-11.25,2.8-16.09c1.16-4.84,3.82-16.36,5.45-22.91
			c1.64-6.55,2.86-13.3,2.93-19.7s-2.32-16.91-2.93-20.18c-0.61-3.27-3-16.23-3.07-24.95s0.89-12.34,1.77-19.77
			c0.89-7.43,3.48-19.23,4.5-25.77c1.02-6.55,2.66-18.14,2.86-21.55c0.12-1.93,0.56-10.33,0.75-20.37c0,0-16.01,3.25-23.42,3.25
			c-7.41,0-25.11-3.25-25.11-3.25c0-0.03-0.01-0.06-0.01-0.09c0.95,9,2.25,18.34,3.68,28.64c1.43,10.3,1.5,25.77,1.57,27.61
			c0.07,1.84-0.41,16.57-0.55,22.43c-0.14,5.86,0.82,11.18,1.5,13.57s4.57,11.25,5.52,13.02c0.95,1.77,1.64,4.36,1.64,5.59
			c0,1.23-0.68,4.3-1.02,7.57c-0.34,3.27,0,15.07,0.27,17.52c0.27,2.45,2.18,10.64,2.8,13.57c0.61,2.93,2.73,12.89,3.55,18.34
			s-0.34,12.82-0.75,15.2c-0.41,2.39-0.41,6.07-0.48,7.77c-0.07,1.7-0.61,9.75-0.95,12.55c-0.34,2.8-1.39,9.95-1.09,11.93
			c0.21,1.35,1.84,3.34,3.34,5.18c1.5,1.84,1.02,4.5,0.89,6.75s-0.14,3.34,1.77,5.59s4.91,3.68,7.09,4.09s5.59-1.02,5.59-1.02
			c2.8,0.89,5.39-0.61,5.39-0.61c3.48,0.2,5.11-0.82,5.11-0.82c3.96-0.24,4.16-1.65,4.16-1.65c1.37-0.16,3.17-0.44,3.07-2.17
			C182.43,528.95,181.37,527.72,180.69,526.97z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M179.03,297.06c0.01-0.9,0.02-1.81,0.03-2.73C179.05,295.24,179.04,296.15,179.03,297.06z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M178.98,300c0.02-0.93,0.03-1.87,0.05-2.81C179.02,298.14,179,299.08,178.98,300z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M76.1,320.26c0.2,3.41,1.84,15,2.86,21.55c1.02,6.55,3.61,18.34,4.5,25.77c0.89,7.43,1.84,11.05,1.77,19.77
			s-2.45,21.68-3.07,24.95c-0.61,3.27-3,13.77-2.93,20.18s1.3,13.16,2.93,19.7c1.64,6.55,4.3,18.07,5.45,22.91
			c1.16,4.84,2.86,12.14,2.8,16.09s-0.75,8.39-2.05,11.32c-1.3,2.93-2.86,8.39-3.2,9.95c-0.34,1.57-1.5,3.89-3.27,5.32
			s-3.68,3.27-5.11,5.32c-1.43,2.05-2.45,3-3.14,3.75s-1.74,1.98-1.84,3.61c-0.11,1.73,1.7,2,3.07,2.17c0,0,0.2,1.41,4.16,1.65
			c0,0,1.64,1.02,5.11,0.82c0,0,2.59,1.5,5.39,0.61c0,0,3.41,1.43,5.59,1.02c2.18-0.41,5.18-1.84,7.09-4.09
			c1.91-2.25,1.91-3.34,1.77-5.59s-0.61-4.91,0.89-6.75c1.5-1.84,3.23-3.31,3.34-5.18c0.13-2.16-0.75-9.14-1.09-11.93
			c-0.34-2.8-0.89-10.84-0.95-12.55c-0.07-1.7-0.07-5.39-0.48-7.77c-0.41-2.39-1.57-9.75-0.75-15.2c0.82-5.45,2.93-15.41,3.55-18.34
			c0.61-2.93,2.52-11.11,2.8-13.57s0.61-14.25,0.27-17.52c-0.34-3.27-1.02-6.34-1.02-7.57c0-1.23,0.68-3.82,1.64-5.59
			c0.95-1.77,4.84-10.64,5.52-13.02s1.64-7.7,1.5-13.57c-0.14-5.86-0.61-20.59-0.55-22.43c0.07-1.84,0.14-17.32,1.57-27.61
			c1.42-10.21,2.71-19.49,3.66-28.42c0,0-11.97,3.72-24.42,3.72c-12.45,0-24.1-3.72-24.1-3.72L76.1,320.26z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.26,290.82c0-0.95,0-1.89,0.01-2.84C75.27,288.93,75.26,289.88,75.26,290.82z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.39,281.71c0.03-0.86,0.05-1.72,0.09-2.56C75.44,279.99,75.42,280.84,75.39,281.71z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.5,278.76c0.04-0.88,0.08-1.76,0.13-2.61C75.57,277,75.53,277.87,75.5,278.76z"
        />
        <path
          style={applyStyling(legsId, styling1)}
          d="M75.32,284.82c0.02-0.88,0.04-1.76,0.06-2.63C75.35,283.06,75.33,283.94,75.32,284.82z"
        />
      </g>
      <g
        id={hipsId}
        onClick={(e) => handleClick(e.target, hipsId)}
        onMouseEnter={() => setIsHovered(hipsId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(hipsId, styling2)}
          d="M123.87,300.01c0.01-0.07,0.02-0.14,0.02-0.21c0.95-9,1.16-10.86,1.16-10.86s0.01-0.11,0.03-0.27
		c1.33,0.84,2.84,0.91,4.16-0.14c0,0.04,0.01,0.08,0.01,0.08s0.24,2.32,1.19,11.32c0,0.03,0.01,0.06,0.01,0.09
		c0,0,17.71,3.25,25.11,3.25c7.41,0,23.42-3.25,23.42-3.25c0.15-7.68,0.14-16.32-0.27-23.75c-0.66-11.77-3.18-23.56-4.69-29.78
		c-0.8-3.27-1.3-6.6-1.48-9.96c0.04,0.8,0.11,1.6,0.19,2.39c0.12,1.2-22.7,5.6-45.54,5.6c-22.83,0-45.68-4.39-45.58-5.6
		c-0.26,2.51-0.69,5-1.28,7.45c-1.51,6.22-4.04,18.02-4.69,29.78c-0.42,7.46-0.42,16.16-0.27,23.87c0,0,11.65,3.72,24.1,3.72
		S123.87,300.01,123.87,300.01z"
        />
      </g>
      <g
        id={lowerArmLeftId}
        onClick={(e) => handleClick(e.target, lowerArmLeftId)}
        onMouseEnter={() => setIsHovered(lowerArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmLeftId, styling2)}
          d="M50.47,221.58c-5.9-1.57-11.41-5.53-11.41-5.53c-0.22,2.8-0.37,5.26-0.53,6.97
		c-0.33,3.42-5.17,31.88-5.17,31.88s-3.54,1.29-5.15,1.83c-1.62,0.54-9.09,2.16-10.34,3.55s-3.55,4-4.32,5.08
		c-0.76,1.08-2.16,2.29-3.01,3.01c-0.85,0.72-1.48,2.29-1.66,2.88s-1.62,2.34-2.83,3.28c-1.21,0.94-1.89,1.75-1.48,2.47
		c0.4,0.72,3.15,1.44,5.44,0c1.92-1.21,5.25-4.66,7.98-6.22c0,0.07,0.01,0.14,0.01,0.14s0.09,1.99,0.36,4.52s0.18,4.71-0.18,6.2
		c-0.36,1.49-2.08,6.92-3.26,10.41c-1.18,3.48-4.9,11.36-3.17,13.3c1.87,2.09,3.53-1.04,4.66-3.94s5.2-12.33,6.2-12.96
		c1-0.63,0.36-0.19-0.04,1.03s-3.26,9.53-3.98,13.65c-0.72,4.12-1.69,7.64,1.04,8.05c2.21,0.33,2.76-3.4,3.26-5.29
		c1.4-5.34,5.38-16.2,5.57-16.52c0.18-0.32-1.99,9-2.26,12.71c-0.27,3.71-1,8.24,1.27,8.24s3.1-2.51,3.42-5.4
		c0.32-2.9,2.96-14.37,3.33-14.73s0.45,1.63,0.5,3.08s0.41,7.28,0.5,8.33c0.09,1.04,0.77,2.9,2.31,2.08
		c1.54-0.81,1.49-2.81,1.63-4.93s0.05-7.1,0.59-9.23s1.72-4.71,2.44-6.43c0.72-1.72,3.12-7.28,3.98-9.91
		c0.86-2.62,1.45-5.84,1.09-7.69s-1.31-4.12-0.77-5.57s3.71-9.26,5.23-12.43c1.52-3.17,7.45-15.44,9.38-19.1
		c0.73-1.39,1.76-3.3,2.79-5.32C63.83,223.1,56.37,223.15,50.47,221.58z"
        />
      </g>
      <g
        id={upperArmLeftId}
        onClick={(e) => handleClick(e.target, upperArmLeftId)}
        onMouseEnter={() => setIsHovered(upperArmLeftId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(upperArmLeftId, styling2)}
          d="M63.83,223.1c1.67-3.3,3.32-6.87,3.62-8.88c0.48-3.24,0.76-14.34,0.76-16.34c0-2-0.07-4.07,0.83-6.34
		c0.9-2.28,3.72-11.31,4.27-13.72c0.54-2.36,2.92-11.5,2.76-17c-0.59-2.81-2.02-11.06-1.07-20.86c1.82-18.71,0.88-33.91,0.06-41.84
		c0,0,0.01,0,0.01,0c-3.63,1.17-15.12,5.34-18.95,10.01c-4,4.88-4.78,16.69-3.61,27.71c0.59,5.58,1.36,10.61,1.97,14.22
		c-0.06,0.14-0.11,0.28-0.11,0.28s-5.34,13.77-4.84,34.05c0,0-7.96,12.39-9.51,22.7c-0.46,3.05-0.75,6.18-0.97,8.97
		c0,0,5.51,3.96,11.41,5.53S63.83,223.1,63.83,223.1z"
        />
      </g>
      <g
        id={lowerArmRightId}
        onClick={(e) => handleClick(e.target, lowerArmRightId)}
        onMouseEnter={() => setIsHovered(lowerArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(lowerArmRightId, styling1)}
          d="M203.84,221.63c5.9-1.57,11.41-5.53,11.41-5.53c0.22,2.8,0.37,5.26,0.53,6.97c0.33,3.42,5.17,31.88,5.17,31.88
		s3.54,1.29,5.15,1.83c1.62,0.54,9.09,2.16,10.34,3.55s3.55,4,4.32,5.08c0.76,1.08,2.16,2.29,3.01,3.01
		c0.85,0.72,1.48,2.29,1.66,2.88s1.62,2.34,2.83,3.28c1.21,0.94,1.89,1.75,1.48,2.47c-0.4,0.72-3.15,1.44-5.44,0
		c-1.92-1.21-5.25-4.66-7.98-6.22c0,0.07-0.01,0.14-0.01,0.14s-0.09,1.99-0.36,4.52s-0.18,4.71,0.18,6.2
		c0.36,1.49,2.08,6.92,3.26,10.41c1.18,3.48,4.9,11.36,3.17,13.3c-1.87,2.09-3.53-1.04-4.66-3.94s-5.2-12.33-6.2-12.96
		c-1-0.63-0.36-0.19,0.04,1.03s3.26,9.53,3.98,13.65c0.72,4.12,1.69,7.64-1.04,8.05c-2.21,0.33-2.76-3.4-3.26-5.29
		c-1.4-5.34-5.38-16.2-5.57-16.52c-0.18-0.32,1.99,9,2.26,12.71c0.27,3.71,1,8.24-1.27,8.24s-3.1-2.51-3.42-5.4
		c-0.32-2.9-2.96-14.37-3.33-14.73s-0.45,1.63-0.5,3.08s-0.41,7.28-0.5,8.33c-0.09,1.04-0.77,2.9-2.31,2.08
		c-1.54-0.81-1.49-2.81-1.63-4.93s-0.05-7.1-0.59-9.23s-1.72-4.71-2.44-6.43c-0.72-1.72-3.12-7.28-3.98-9.91
		c-0.86-2.62-1.45-5.84-1.09-7.69s1.31-4.12,0.77-5.57s-3.71-9.26-5.23-12.43c-1.52-3.17-7.45-15.44-9.38-19.1
		c-0.73-1.39-1.76-3.3-2.79-5.32C190.48,223.15,197.94,223.21,203.84,221.63z"
        />
      </g>
      <g
        id={upperArmRightId}
        onClick={(e) => handleClick(e.target, upperArmRightId)}
        onMouseEnter={() => setIsHovered(upperArmRightId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(upperArmRightId, styling1)}
          d="M190.48,223.15c-1.67-3.3-3.32-6.87-3.62-8.88c-0.48-3.24-0.76-14.34-0.76-16.34c0-2,0.07-4.07-0.83-6.34
		c-0.9-2.28-3.72-11.31-4.27-13.72c-0.54-2.36-2.92-11.5-2.76-17c0.59-2.81,2.02-11.06,1.07-20.86
		c-1.82-18.71-0.88-33.91-0.06-41.84c0,0-0.01,0-0.01,0c3.63,1.17,15.12,5.34,18.95,10.01c4,4.88,4.78,16.69,3.61,27.71
		c-0.59,5.58-1.36,10.61-1.97,14.22c0.06,0.14,0.11,0.28,0.11,0.28s5.34,13.77,4.84,34.05c0,0,7.96,12.39,9.51,22.7
		c0.46,3.05,0.75,6.18,0.97,8.97c0,0-5.51,3.96-11.41,5.53C197.94,223.21,190.48,223.15,190.48,223.15z"
        />
      </g>
      <g
        id={abdomenId}
        onClick={(e) => handleClick(e.target, abdomenId)}
        onMouseEnter={() => setIsHovered(abdomenId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(abdomenId, styling1)}
          d="M75.08,98.12C75.08,98.12,75.08,98.12,75.08,98.12C75.08,98.12,75.08,98.12,75.08,98.12
		c0.81,7.93,1.76,23.13-0.06,41.84c-0.96,9.8,0.48,18.06,1.07,20.86c0-0.12,0-0.26-0.01-0.38c0,0,0.07,0.46,0.19,1.29
		c-0.01-0.07-0.02-0.14-0.03-0.21c0.05,0.2,0.08,0.32,0.08,0.32l-0.01,0.11c0,0,0,0.01,0,0.01c1.13,7.61,6.08,41.77,6.22,53.93
		c0.06,4.99-0.4,14.48-0.73,20.5c-0.05,0.84-0.11,1.68-0.2,2.51c-0.12,1.2,22.74,5.6,45.58,5.6s45.66-4.4,45.54-5.6
		c-0.08-0.79-0.14-1.59-0.19-2.39c-0.33-6.02-0.78-15.51-0.73-20.5c0.16-13.72,6.45-55.45,6.45-55.45l0.05-0.27
		c0.59-2.81,2.02-10.54,1.07-20.34c-1.81-18.61-0.89-33.73-0.08-41.7c-0.18-0.06-0.37-0.12-0.51-0.16
		c-1.78-0.56-3.76-1.08-5.84-1.59l-42.47,1c-1.42,0.07-2.85,0.07-4.27,0L81.2,96.49l-0.04-0.05c-2,0.5-3.9,1-5.62,1.54
		c-0.29,0.09-0.68,0.22-1.11,0.36C74.66,98.26,74.88,98.18,75.08,98.12L75.08,98.12z"
        />
      </g>
      <g
        id={headId}
        onClick={(e) => handleClick(e.target, headId)}
        onMouseEnter={() => setIsHovered(headId)}
        onMouseLeave={() => setIsHovered('')}
      >
        <path
          style={applyStyling(headId, styling1)}
          d="M172.95,96.5l-0.02,0c0.49,0.12,1,0.24,1.47,0.37C173.93,96.75,173.45,96.62,172.95,96.5z"
        />
        <path
          style={applyStyling(headId, styling1)}
          d="M79.81,96.77c0.44-0.11,0.9-0.22,1.35-0.34l0,0C80.7,96.54,80.26,96.66,79.81,96.77z"
        />
        <path
          style={applyStyling(headId, styling1)}
          d="M147.04,78.67c-0.12-2.34-0.53-12.24-0.53-12.24l-0.53-0.1c1.71-3.47,2.95-7.08,3.29-10.18
			c0,0,4.07,0.95,7.1-7.45c3.24-8.97-1.56-12.47-4.33-9.7c0,0,1.65-12.78-0.61-19.32c-2.54-7.34-8.6-15.93-24.43-15.94
			c-15.84,0.01-21.91,8.6-24.45,15.94c-2.26,6.54-0.61,19.32-0.61,19.32c-2.77-2.77-7.57,0.73-4.33,9.7c3.03,8.4,7.1,7.45,7.1,7.45
			c0.34,3.09,1.57,6.67,3.27,10.13c0,0,0,0,0,0l-0.15,0.02c0,0-0.42,9.9-0.53,12.24c-0.12,2.34-0.84,10.46-3.07,11.37
			c-7.22,2.97-15.84,4.71-23.07,6.52l0.04,0.05l45.02,1.02c1.42,0.07,2.85,0.07,4.27,0l42.45-1c-7.17-1.79-15.69-3.52-22.83-6.46
			C147.87,89.12,147.15,81.01,147.04,78.67z M108.94,68.13c0.03,0.06,0.07,0.13,0.1,0.19C109,68.26,108.97,68.19,108.94,68.13z
			 M110.03,70.03c0.06,0.09,0.11,0.19,0.17,0.28C110.14,70.22,110.08,70.13,110.03,70.03z M111.23,71.93
			c0.05,0.08,0.11,0.16,0.16,0.24C111.34,72.08,111.28,72.01,111.23,71.93z M145,68.22c-0.02,0.04-0.04,0.08-0.06,0.12
			C144.95,68.29,144.97,68.25,145,68.22z M143.93,70.07c-0.05,0.09-0.1,0.17-0.16,0.26C143.83,70.24,143.88,70.15,143.93,70.07z
			 M142.74,71.94c-0.05,0.08-0.11,0.16-0.16,0.24C142.63,72.1,142.69,72.02,142.74,71.94z"
        />
      </g>
      <g id="Male_Front">
        <path
          style={styling3}
          d="M107.83,66.31c0,0-0.42,9.9-0.53,12.24c-0.12,2.34-0.84,10.46-3.07,11.37c-9.22,3.79-20.75,5.58-28.69,8.05
		c-3.03,0.95-15.41,5.28-19.41,10.15s-4.78,16.69-3.61,27.71c1.17,11.03,3.02,19.91,3.02,19.91"
        />
        <path
          style={styling3}
          d="M54.39,150.33c0,0-5.34,13.77-4.84,34.05c0,0-7.96,12.39-9.51,22.7c-0.92,6.09-1.17,12.52-1.5,15.94
		c-0.33,3.42-5.17,31.88-5.17,31.88s-3.54,1.29-5.15,1.83c-1.62,0.54-9.09,2.16-10.34,3.55s-3.55,4-4.32,5.08
		c-0.76,1.08-2.16,2.29-3.01,3.01c-0.85,0.72-1.48,2.29-1.66,2.88c-0.18,0.58-1.62,2.34-2.83,3.28c-1.21,0.94-1.89,1.75-1.48,2.47
		s3.15,1.44,5.44,0c2.29-1.44,6.6-6.08,9.49-6.88c3.73-1.03,5.26-0.49,6.84-0.4c1.57,0.09,3.73,0.09,6.61-2.65"
        />
        <path
          style={styling3}
          d="M74.85,140.31c-0.96,11.29,1.22,20.13,1.22,20.13c0.28,5.45-2.21,14.96-2.76,17.37
		c-0.55,2.41-3.38,11.44-4.27,13.72c-0.9,2.27-0.83,4.34-0.83,6.34c0,2-0.28,13.1-0.76,16.34c-0.48,3.24-4.48,10.55-6.41,14.2
		c-1.93,3.65-7.86,15.92-9.38,19.1c-1.52,3.17-4.69,10.98-5.23,12.43s0.41,3.71,0.77,5.57c0.36,1.86-0.23,5.07-1.09,7.69
		c-0.86,2.62-3.26,8.19-3.98,9.91s-1.9,4.3-2.44,6.43c-0.54,2.13-0.45,7.1-0.59,9.23c-0.14,2.13-0.09,4.12-1.63,4.93
		c-1.54,0.81-2.22-1.04-2.31-2.08s-0.45-6.88-0.5-8.33c-0.05-1.45-0.14-3.44-0.5-3.08c-0.36,0.36-3.01,11.84-3.33,14.73
		s-1.15,5.4-3.42,5.4s-1.54-4.52-1.27-8.24c0.27-3.71,2.44-13.03,2.26-12.71c-0.18,0.32-4.16,11.18-5.57,16.52
		c-0.5,1.89-1.04,5.63-3.26,5.29c-2.73-0.41-1.76-3.94-1.04-8.05c0.72-4.12,3.58-12.43,3.98-13.65c0.41-1.22,1.04-1.67,0.04-1.03
		s-5.07,10.07-6.2,12.96s-2.79,6.03-4.66,3.94c-1.73-1.94,1.99-9.82,3.17-13.3s2.9-8.91,3.26-10.41s0.45-3.67,0.18-6.2
		c-0.27-2.53-0.36-4.52-0.36-4.52"
        />
        <path
          style={styling3}
          d="M76.07,160.45c0,0,6.29,41.73,6.45,55.45c0.06,4.99-0.4,14.48-0.73,20.5c-0.18,3.36-0.68,6.69-1.48,9.96
		c-1.51,6.22-4.04,18.02-4.69,29.78c-0.95,17.11,0.27,40.7,0.48,44.11c0.2,3.41,1.84,15,2.86,21.55c1.02,6.55,3.61,18.34,4.5,25.77
		c0.89,7.43,1.84,11.05,1.77,19.77c-0.07,8.73-2.45,21.68-3.07,24.95s-3,13.77-2.93,20.18s1.3,13.16,2.93,19.7
		c1.64,6.55,4.3,18.07,5.45,22.91c1.16,4.84,2.86,12.14,2.8,16.09c-0.07,3.95-0.75,8.39-2.05,11.32c-1.3,2.93-2.86,8.39-3.2,9.95
		c-0.34,1.57-1.5,3.89-3.27,5.32c-1.77,1.43-3.68,3.27-5.11,5.32c-1.43,2.05-2.45,3-3.14,3.75s-1.74,1.98-1.84,3.61
		c-0.11,1.73,1.7,2,3.07,2.17c0,0,0.2,1.41,4.16,1.65c0,0,1.64,1.02,5.11,0.82c0,0,2.59,1.5,5.39,0.61c0,0,3.41,1.43,5.59,1.02
		c2.18-0.41,5.18-1.84,7.09-4.09s1.91-3.34,1.77-5.59c-0.14-2.25-0.61-4.91,0.89-6.75s3.23-3.31,3.34-5.18
		c0.13-2.16-0.75-9.14-1.09-11.93c-0.34-2.8-0.89-10.84-0.95-12.55c-0.07-1.7-0.07-5.39-0.48-7.77s-1.57-9.75-0.75-15.2
		c0.82-5.45,2.93-15.41,3.55-18.34c0.61-2.93,2.52-11.11,2.8-13.57c0.27-2.45,0.61-14.25,0.27-17.52c-0.34-3.27-1.02-6.34-1.02-7.57
		s0.68-3.82,1.64-5.59c0.95-1.77,4.84-10.64,5.52-13.02c0.68-2.39,1.64-7.7,1.5-13.57c-0.14-5.86-0.61-20.59-0.55-22.43
		c0.07-1.84,0.14-17.32,1.57-27.61c1.43-10.3,2.73-19.64,3.68-28.64c0.95-9,1.16-10.86,1.16-10.86"
        />
        <path style={styling3} d="M21.26,288.13c0,0,2.5-0.22,3.62-0.99" />
        <path style={styling3} d="M27.03,288.34c0,0,1.94,0.82,2.54,0.9" />
        <path style={styling3} d="M34.61,275.68c0,0,0.69,3.4,1.77,4.74" />
        <path style={styling3} d="M54.08,186.78c0,0,0.37,2.74,1.12,3.87" />
        <path style={styling3} d="M62.56,191.08c0,0-2.62,3.87-4.24,4.61" />
        <path style={styling3} d="M88.88,238.9c0,0,5.57,6.75,13.33,11.42" />
        <path style={styling3} d="M88.35,398.55c0,0,1.9,5.79,5.94,9.25" />
        <path style={styling3} d="M106.99,404.05c0,0-2.63,5.06-6.23,7.64" />
        <path style={styling3} d="M104.11,518.19c0,0-0.5,2.8-0.28,5.11" />
        <path
          style={styling3}
          d="M93.95,528.63c0,0-1.25,1.34-1.9,1.68c-0.65,0.34-2.77,1.52-2.65,3.46s1.34,2.34,1.34,2.34"
        />
        <path style={styling3} d="M87.19,528.6c0,0-3.18,1.68-3.18,4.18c0,0-0.02,1.47,0.13,2.32" />
        <path style={styling3} d="M82.33,528.78c0,0-3.41,2.05-3.33,3.77c0.03,0.72,0.09,1.62,0.22,1.81" />
        <path style={styling3} d="M77.22,528.5c0,0-2.47,1.38-2.37,4.01" />
        <path
          style={styling3}
          d="M127.61,78.6c2.51,0,10.05,0.26,13.86-4.85s7.19-12.13,7.8-17.58c0,0,4.07,0.95,7.1-7.45
		c3.24-8.97-1.56-12.47-4.33-9.7c0,0,1.65-12.78-0.61-19.32c-2.54-7.34-8.6-15.93-24.43-15.94c-15.84,0.01-21.91,8.6-24.45,15.94
		c-2.26,6.54-0.61,19.32-0.61,19.32c-2.77-2.77-7.57,0.73-4.33,9.7c3.03,8.4,7.1,7.45,7.1,7.45c0.61,5.46,3.98,12.47,7.8,17.58
		c3.81,5.11,11.35,4.85,13.86,4.85H127.61z"
        />
        <path
          style={styling3}
          d="M79.82,96.71c0,0,15.83-0.63,19.84-0.59c4.01,0.04,10.36,0.39,12,0.95c1.77,0.6,4.23,2.72,4.58,3.06"
        />
        <path style={styling3} d="M68.05,133.77c0,0,3.02,4.81,6.8,6.54" />
        <path
          style={styling3}
          d="M146.5,66.43c0,0,0.42,9.9,0.53,12.24s0.84,10.46,3.07,11.37c9.22,3.79,20.75,5.58,28.69,8.05
		c3.03,0.95,15.41,5.28,19.41,10.15c4,4.88,4.78,16.69,3.61,27.71c-1.17,11.03-3.02,19.91-3.02,19.91"
        />
        <path
          style={styling3}
          d="M199.95,150.46c0,0,5.34,13.77,4.84,34.05c0,0,7.96,12.39,9.51,22.7c0.92,6.09,1.17,12.52,1.5,15.94
		s5.17,31.88,5.17,31.88s3.54,1.29,5.15,1.83s9.09,2.16,10.34,3.55c1.26,1.39,3.55,4,4.32,5.08s2.16,2.29,3.01,3.01
		c0.85,0.72,1.48,2.29,1.66,2.88c0.18,0.58,1.62,2.34,2.83,3.28c1.21,0.94,1.89,1.75,1.48,2.47s-3.15,1.44-5.44,0
		c-2.29-1.44-6.6-6.08-9.49-6.88c-3.73-1.03-5.26-0.49-6.84-0.4c-1.57,0.09-3.73,0.09-6.61-2.65"
        />
        <path
          style={styling3}
          d="M179.48,140.44c0.96,11.29-1.22,20.13-1.22,20.13c-0.28,5.45,2.21,14.96,2.76,17.37
		c0.55,2.41,3.38,11.44,4.27,13.72c0.9,2.27,0.83,4.34,0.83,6.34c0,2,0.28,13.1,0.76,16.34s4.48,10.55,6.41,14.2
		c1.93,3.65,7.86,15.92,9.38,19.1c1.52,3.17,4.69,10.98,5.23,12.43c0.54,1.45-0.41,3.71-0.77,5.57c-0.36,1.86,0.23,5.07,1.09,7.69
		c0.86,2.62,3.26,8.19,3.98,9.91s1.9,4.3,2.44,6.43c0.54,2.13,0.45,7.1,0.59,9.23s0.09,4.12,1.63,4.93
		c1.54,0.81,2.22-1.04,2.31-2.08s0.45-6.88,0.5-8.33s0.14-3.44,0.5-3.08c0.36,0.36,2.94,11.71,3.25,14.61
		c0.32,2.9,1.23,5.53,3.49,5.53s1.54-4.52,1.27-8.24c-0.27-3.71-2.44-13.03-2.26-12.71c0.18,0.32,4.16,11.18,5.57,16.52
		c0.5,1.89,1.03,5.54,3.26,5.29c3.23-0.36,1.76-3.94,1.04-8.05c-0.72-4.12-3.58-12.43-3.98-13.65c-0.41-1.22-1.04-1.67-0.04-1.03
		c1,0.63,5.07,10.07,6.2,12.96c1.13,2.9,3.17,6.06,4.66,3.94c1.49-2.13-1.99-9.82-3.17-13.3s-2.9-8.91-3.26-10.41
		s-0.45-3.67-0.18-6.2c0.27-2.53,0.36-4.52,0.36-4.52"
        />
        <path
          style={styling3}
          d="M178.26,160.57c0,0-6.29,41.73-6.45,55.45c-0.06,4.99,0.4,14.48,0.73,20.5c0.18,3.36,0.68,6.69,1.48,9.96
		c1.51,6.22,4.04,18.02,4.69,29.78c0.95,17.11-0.27,40.7-0.48,44.11s-1.84,15-2.86,21.55c-1.02,6.55-3.61,18.34-4.5,25.77
		c-0.89,7.43-1.84,11.05-1.77,19.77c0.07,8.73,2.45,21.68,3.07,24.95s3,13.77,2.93,20.18c-0.07,6.41-1.3,13.16-2.93,19.7
		c-1.64,6.55-4.3,18.07-5.45,22.91c-1.16,4.84-2.86,12.14-2.8,16.09c0.07,3.95,0.75,8.39,2.05,11.32c1.3,2.93,2.86,8.39,3.2,9.95
		c0.34,1.57,1.5,3.89,3.27,5.32s3.68,3.27,5.11,5.32c1.43,2.05,2.45,3,3.14,3.75c0.68,0.75,1.74,1.98,1.84,3.61
		c0.11,1.73-1.7,2-3.07,2.17c0,0-0.2,1.41-4.16,1.65c0,0-1.64,1.02-5.11,0.82c0,0-2.59,1.5-5.39,0.61c0,0-3.41,1.43-5.59,1.02
		c-2.18-0.41-5.18-1.84-7.09-4.09c-1.91-2.25-1.91-3.34-1.77-5.59c0.14-2.25,0.61-4.91-0.89-6.75c-1.5-1.84-3.13-3.83-3.34-5.18
		c-0.3-1.98,0.75-9.14,1.09-11.93s0.89-10.84,0.95-12.55c0.07-1.7,0.07-5.39,0.48-7.77s1.57-9.75,0.75-15.2
		c-0.82-5.45-2.93-15.41-3.55-18.34c-0.61-2.93-2.52-11.11-2.8-13.57c-0.27-2.45-0.61-14.25-0.27-17.52s1.02-6.34,1.02-7.57
		s-0.68-3.82-1.64-5.59c-0.95-1.77-4.84-10.64-5.52-13.02c-0.68-2.39-1.64-7.7-1.5-13.57c0.14-5.86,0.61-20.59,0.55-22.43
		c-0.07-1.84-0.14-17.32-1.57-27.61c-1.43-10.3-2.73-19.64-3.68-28.64c-0.95-9-1.19-11.32-1.19-11.32"
        />
        <path style={styling3} d="M233.08,288.25c0,0-2.5-0.22-3.62-0.99" />
        <path style={styling3} d="M227.3,288.47c0,0-1.94,0.82-2.54,0.9" />
        <path style={styling3} d="M219.72,275.8c0,0-0.69,3.4-1.77,4.74" />
        <path style={styling3} d="M200.25,186.91c0,0-0.37,2.74-1.12,3.87" />
        <path style={styling3} d="M191.78,191.21c0,0,2.62,3.87,4.24,4.61" />
        <path style={styling3} d="M165.45,239.03c0,0-5.57,6.75-13.33,11.42" />
        <path style={styling3} d="M165.99,398.67c0,0-1.9,5.79-5.94,9.25" />
        <path style={styling3} d="M147.34,404.17c0,0,2.63,5.06,6.23,7.64" />
        <path style={styling3} d="M150.22,518.32c0,0,0.5,2.8,0.28,5.11" />
        <path
          style={styling3}
          d="M160.38,528.75c0,0,1.25,1.34,1.9,1.68c0.65,0.34,2.77,1.52,2.65,3.46c-0.12,1.94-1.34,2.34-1.34,2.34"
        />
        <path style={styling3} d="M167.14,528.72c0,0,3.18,1.68,3.18,4.18c0,0,0.02,1.47-0.13,2.32" />
        <path style={styling3} d="M172,528.91c0,0,3.41,2.05,3.33,3.77c-0.03,0.72-0.09,1.62-0.22,1.81" />
        <path style={styling3} d="M177.11,528.63c0,0,2.47,1.38,2.37,4.01" />
        <path
          style={styling3}
          d="M174.52,96.83c0,0-15.83-0.63-19.84-0.59c-4.01,0.04-10.36,0.39-12,0.95c-1.77,0.6-4.23,2.72-4.58,3.06"
        />
        <path style={styling3} d="M186.28,133.89c0,0-3.02,4.81-6.8,6.54" />
        <path
          style={styling3}
          d="M118.32,272.49c0,0,0.25,2.49,1.66,6.65c1.41,4.15,3.12,8.02,4.65,9.22c1.54,1.2,3.41,1.37,4.94-0.12
		s3.41-5.9,4.11-7.89s1.66-6.4,1.99-7.77"
        />
        <path style={styling3} d="M110.77,78.99c0,0,3.24,7.75,5.8,11.44" />
        <path style={styling3} d="M143.14,79.44c0,0-2.71,6.77-5.87,11.67" />
        <path style={styling3} d="M124.32,105.86c0,0,0.54,1.74,3.24,1.74c2.71,0,3.29-1.9,3.29-1.9" />
        <path style={styling3} d="M126.32,217.17c0,0,0.73,0.95,2.3,0.67c1.57-0.28,1.96-2.07,1.96-2.07" />

        <path
          style={styling3}
          d="M107.06,166.15c-4.37-0.02-11.84-0.52-14.35-0.83c-0.51-0.06-1.06-0.16-1.62-0.27
		c-2.74-0.56-5.28-1.81-7.42-3.61l-0.37-0.31c-2.45-2.07-4.24-4.8-5.15-7.87l-0.03-0.1c-0.71-2.38-2.02-8.72-2.66-11.93
		c-0.25-1.26-0.38-2.54-0.38-3.83l0,0c0-1.07,0.09-2.14,0.27-3.2c0.2-1.2,0.67-2.93,1.11-4.24"
        />
        <path
          style={styling3}
          d="M147.51,166.15c4.37-0.02,11.84-0.52,14.35-0.83c0.51-0.06,1.06-0.16,1.62-0.27c2.74-0.56,5.28-1.81,7.42-3.61
		l0.37-0.31c2.45-2.07,4.24-4.8,5.15-7.87l0.03-0.1c0.71-2.38,2.02-8.72,2.66-11.93c0.25-1.26,0.38-2.54,0.38-3.83l0,0
		c0-1.07-0.09-2.14-0.27-3.2c-0.2-1.2-0.67-2.93-1.11-4.24"
        />
      </g>
    </svg>
  )
}
