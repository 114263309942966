import React, { FC, useCallback, useMemo, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'
import { Col } from 'antd'
import { useAppStateContext, useServicesContext } from '../../../context'
import { AutocompleteResult } from '../../../models'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../../constants'
import { ActiveFilter } from '../ServicesMenu/ServicesMenu'
import { Filters } from '../ServicesMenu/Filters/Filters'
import { ServicesMenuSearchBar } from '../ServicesMenu/ServicesMenuSearchBar/ServicesMenuSearchBar'
import { SideMenuToggleMobile } from '../../Header/SideMenuToggleMobile/SideMenuToggleMobile'
import { MenuButton, SearchContainer, StyledIcon } from '../ServicesMenu/ServicesMenu.styled'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { SearchResultListMobile } from './SearchResultListMobile/SearchResultListMobile'
import { Container, IconContainer, Menu, SearchRow } from './ServicesMenuMobile.styled'

/**
 * @param props The props object
 * @param props.onClick A method to call on click
 * @returns The OpenMenu component
 */
export const OpenMenu: FC<{
  onClick: () => void
}> = ({ onClick }) => {
  return (
    <IconContainer onClick={onClick}>
      <CustomIcon icon="stroke" />
    </IconContainer>
  )
}

/**
 * @param props The props object
 * @param props.sideMenuToggleClicked A method handling the click action of the side menu toggle
 * @returns The ServicesMenuMobile component
 */
export const ServicesMenuMobile: FC = () => {
  const [activeFilters, setActiveFilters] = useState<ActiveFilter>({})
  const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteResult[]>([])
  const { isSideMenuOpen, setSideMenuOpen } = useAppStateContext()

  const {
    setValue: setSpecializationsSearchText,
    debouncedValue: specializationsSearchText,
    value: specializationsValue,
  } = useDebouncedState('', DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS)

  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [isSpecializationsDetailedViewVisible, setIsSpecializationsDetailedViewVisible] = useState(false)
  const [isLocationsDetailedViewVisible, setIsLocationsDetailedViewVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const {
    filterObject,
    setRequestFilter,
    searchText,
    setSearchText,
    isMatchingFiltersServicesLoading,
    setSelectedService,
  } = useServicesContext()

  const showMatchingFiltersServices = useMemo(
    () => !isFiltersOpen && !isMatchingFiltersServicesLoading,
    [isFiltersOpen, isMatchingFiltersServicesLoading],
  )

  /**
   * The menu button onClick handler
   */
  const menuOnClickHandler = useCallback(() => {
    if (isFiltersOpen) {
      if (isSpecializationsDetailedViewVisible) {
        setIsSpecializationsDetailedViewVisible(false)
        return
      }
      if (isLocationsDetailedViewVisible) {
        setIsLocationsDetailedViewVisible(false)
        return
      }
      setIsFiltersOpen(false)
    } else {
      setIsMenuOpen(true)
      setIsFiltersOpen(true)
    }
  }, [
    isFiltersOpen,
    setIsFiltersOpen,
    setIsSpecializationsDetailedViewVisible,
    isSpecializationsDetailedViewVisible,
    isLocationsDetailedViewVisible,
  ])

  const dynamicSearchValue = useMemo(
    () => (isSpecializationsDetailedViewVisible ? specializationsValue : searchText),
    [isSpecializationsDetailedViewVisible, specializationsValue, searchText],
  )

  return (
    <>
      <SideMenuToggleMobile clicked={() => setSideMenuOpen(!isSideMenuOpen)} />

      <Container isMenuOpen={isMenuOpen}>
        {(!isFiltersOpen || isSpecializationsDetailedViewVisible || isLocationsDetailedViewVisible) && (
          <Menu>
            <OpenMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />

            <SearchRow align="middle">
              <SearchContainer flex="auto">
                <ServicesMenuSearchBar
                  value={dynamicSearchValue}
                  setSearchText={setSearchText}
                  isSpecializationsDetailedViewVisible={isSpecializationsDetailedViewVisible}
                  setSpecializationsSearchText={setSpecializationsSearchText}
                  isAutocomplete={isLocationsDetailedViewVisible}
                  setAutocompleteResults={setAutocompleteResults}
                />
              </SearchContainer>

              <Col flex="33px">
                <MenuButton onClick={menuOnClickHandler}>
                  <StyledIcon icon="horizontalFilter" />
                </MenuButton>
              </Col>
            </SearchRow>
          </Menu>
        )}

        {isFiltersOpen ? (
          <>
            <OpenMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />

            <Filters
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              searchText={specializationsSearchText}
              autocompleteResults={autocompleteResults}
              onCloseFilters={() => {
                setIsFiltersOpen(false)
                setSelectedService(null)
                setRequestFilter(filterObject)
              }}
              isLocationsDetailedViewVisible={isLocationsDetailedViewVisible}
              setIsLocationsDetailedViewVisible={setIsLocationsDetailedViewVisible}
              isSpecializationsDetailedViewVisible={isSpecializationsDetailedViewVisible}
              setIsSpecializationsDetailedViewVisible={setIsSpecializationsDetailedViewVisible}
            />
          </>
        ) : (
          <SearchResultListMobile showMatchingFiltersServices={showMatchingFiltersServices} isMenuOpen={isMenuOpen} />
        )}
      </Container>
    </>
  )
}
