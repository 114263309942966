import React, { CSSProperties, FC, useCallback, useState } from 'react'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { FemaleBodyBackSVG } from './FemaleBody/FemaleBodyBackSVG'
import { FemaleBodyFrontSVG } from './FemaleBody/FemaleBodyFrontSVG'
import { MaleBodyBackSVG } from './MaleBody/MaleBodyBackSVG'
import { MaleBodyFrontSVG } from './MaleBody/MaleBodyFrontSVG'
import { hover } from './styles'

/**
 * @param props The props object
 * @param props.requiredSVG The props object
 * @param props.onClick The click handler method
 * @returns The BodySVG component
 */
export const BodySVG: FC<{
  requiredSVG: 'FEMALE_FRONT' | 'FEMALE_BACK' | 'MALE_FRONT' | 'MALE_BACK'
  onClick: (val: EventTarget) => void
}> = ({ requiredSVG, onClick }) => {
  const [isHovered, setIsHovered] = useState('')
  const [isClicked, setIsClicked] = useState('')

  /**
   * @param event The mouse or touch event
   * @param bodyPartId The body part id to select
   */
  const handleClick = useCallback(
    (eventTarget: EventTarget, bodyPartId: string) => {
      onClick(eventTarget)
      setIsClicked(isClicked === bodyPartId ? '' : bodyPartId)

      if (isHovered) {
        setIsHovered('')
      }
    },
    [isClicked, isHovered, onClick],
  )

  /**
   * @param id The id to use
   * @param styling The styling to use
   * @returns The appropriate styling
   */
  const applyStyling = (id: string, styling: CSSProperties) =>
    isHovered === id || isClicked === id ? hover(CSS_VARS.bodyPartSelectorSVGBodyOutlineAndHoverColor) : styling

  /**
   * @returns The required SVG component
   */
  const determineBodySVGNeeded = () => {
    if (requiredSVG === 'FEMALE_FRONT') {
      return <FemaleBodyFrontSVG handleClick={handleClick} applyStyling={applyStyling} setIsHovered={setIsHovered} />
    } else if (requiredSVG === 'FEMALE_BACK') {
      return <FemaleBodyBackSVG handleClick={handleClick} applyStyling={applyStyling} setIsHovered={setIsHovered} />
    } else if (requiredSVG === 'MALE_FRONT') {
      return <MaleBodyFrontSVG handleClick={handleClick} applyStyling={applyStyling} setIsHovered={setIsHovered} />
    } else {
      return <MaleBodyBackSVG handleClick={handleClick} applyStyling={applyStyling} setIsHovered={setIsHovered} />
    }
  }

  return determineBodySVGNeeded()
}
