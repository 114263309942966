export const DEFAULT_SELECTED_SPECIALIZATION_ALLOWED_LENGTH = 35

export const DEFAULT_SELECTED_SPECIALIZATION_ALLOWED_LENGTH_RESPONSIVE = 25

/**
 * @param string The string to truncate
 * @param allowedLength The maximum allowed length
 * @returns The truncated string with "..." appended to it, or the string
 */
export const truncateString = (string: string, allowedLength = DEFAULT_SELECTED_SPECIALIZATION_ALLOWED_LENGTH) =>
  string.length >= allowedLength ? `${string.slice(0, allowedLength)}...` : string
