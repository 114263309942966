import React, { FC, CSSProperties, useMemo } from 'react'
import { Typography } from 'antd'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

/**
 * The component for displaying texts in modals
 *
 * @param props The props object
 * @param props.title The title to use
 * @param props.style The styling to use
 * @param props.isModalTitle The boolean which decides whether the title property is an actual title
 * @returns The ModalText component
 */
export const ModalText: FC<{ title?: string; style?: CSSProperties; isModalTitle?: boolean }> = ({
  title,
  style,
  isModalTitle,
}) => {
  const modalTitleStyles = useMemo(
    () => ({
      color: CSS_VARS.modalTitleFontColor,
      fontSize: CSS_VARS.modalTitleFontSize,
      fontWeight: CSS_VARS.modalTitleFontWeight,
      fontStyle: CSS_VARS.modalTitleFontStyle,
      fontFamily: CSS_VARS.fontFamily,
      ...style,
    }),
    [style],
  )

  const modalTextStyles = useMemo(
    () => ({
      color: CSS_VARS.modalFontColor,
      fontSize: CSS_VARS.modalFontSize,
      fontWeight: CSS_VARS.modalFontWeight,
      fontStyle: CSS_VARS.modalFontStyle,
      fontFamily: CSS_VARS.fontFamily,
      ...style,
    }),
    [style],
  )

  return <Typography style={isModalTitle ? modalTitleStyles : modalTextStyles}>{title}</Typography>
}
