// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cPrHxNEygPr5SsBcm1uv {\n  max-height: 83vh;\n  overflow: scroll;\n}\n@media screen and (max-width: 820px) and (orientation: portrait), screen and (orientation: portrait) and (max-width: 768px) {\n  .cPrHxNEygPr5SsBcm1uv {\n    max-height: 87vh;\n  }\n}\n@media screen and (orientation: portrait) and (max-height: 1368px) {\n  .cPrHxNEygPr5SsBcm1uv {\n    max-height: 89vh;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Services/ServicesMenu/SearchResultList/SearchResultList.module.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,gBAAA;AADF;AAIA;EAOE;IACE,gBAAA;EARF;AACF;AAWA;EAIE;IACE,gBAAA;EAZF;AACF","sourcesContent":["@import '../../../../variables.less';\n\n.container {\n  max-height: 83vh;\n  overflow: scroll;\n}\n\n@media screen\nand (max-width: @tablet-max-width)\nand (orientation: portrait),\nscreen\nand (orientation: portrait)\nand (max-width: @tablet-standard-max-width)\n {\n  .container {\n    max-height: 87vh;\n  }\n}\n\n@media screen \nand (orientation: portrait)\nand (max-height: @tablet-large-max-width)\n{\n  .container {\n    max-height: 89vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cPrHxNEygPr5SsBcm1uv"
};
export default ___CSS_LOADER_EXPORT___;
