import { ArrowDownOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import React, { useMemo, useState } from 'react'
import { VirtualUrlService as VirtualUrlServiceModel } from '../../../models/virtualServices/VirtualUrlService'
import { VirtualUrlService, VirtualUrlServiceProps } from './VirtualUrlService'

import styles from './VirtualUrlServiceList.module.less'

export type VirtualUrlServiceListProps = {
  virtualUrlServices: VirtualUrlServiceModel[]
  virtualUrlServicesProps?: Partial<VirtualUrlServiceProps>
  virtualUrlServiceContainerProps?: Record<string, string>
  pagination?: {
    loadMoreProps?: ButtonProps
    maxItemsPerPage: number
  }
}

/**
 * @param props - props
 * @param props.virtualUrlServices services to display
 * @param props.virtualUrlServicesProps extra services props
 * @param props.virtualUrlServiceContainerProps extra list container props
 * @param props.pagination object describing pagination (if any)
 * @returns VirtualUrlServiceList component
 */
export const VirtualUrlServiceList: React.FC<VirtualUrlServiceListProps> = ({
  virtualUrlServices,
  virtualUrlServicesProps,
  virtualUrlServiceContainerProps,
  pagination,
}) => {
  const [numberOfServicesToDisplay, setNumberOfServicesToDisplay] = useState(pagination?.maxItemsPerPage ?? 0)

  const paginatedServices = useMemo(
    () => (!pagination ? virtualUrlServices : virtualUrlServices.slice(0, numberOfServicesToDisplay)),
    [pagination, virtualUrlServices, numberOfServicesToDisplay],
  )

  return (
    <>
      <div className={styles.virtualServicesGrid} {...virtualUrlServiceContainerProps}>
        {paginatedServices.map((service) => (
          <VirtualUrlService
            key={`virtual-url-service-${service.id}`}
            virtualUrlService={service}
            {...virtualUrlServicesProps}
          />
        ))}
      </div>
      {pagination && pagination.maxItemsPerPage < virtualUrlServices.length && (
        <Button
          onClick={() => setNumberOfServicesToDisplay(numberOfServicesToDisplay + pagination.maxItemsPerPage)}
          className={styles.loadMoreButton}
          {...pagination.loadMoreProps}
        >
          <ArrowDownOutlined />
        </Button>
      )}
    </>
  )
}
